import DataTable from "../../components/DataTable";
import AddUpdate from "./AddUpdate";
import { useState } from "react";
import { setCustomer } from "src/stores/customer";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdateCustomerProps,
  customerProps,
  customerDataProps,
} from "src/interfaces";
import { columns } from "./constants";
import { checkPermission } from "src/helpers";
import { customerAPIUrls } from "src/common/apiActions";
import { useLocation, useNavigate } from "react-router-dom";

const Component = ({
  customers,
  setCustomer,
}: {
  customers: customerProps;
  setCustomer: (payload: customerDataProps) => void;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = customers.data || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<createUpdateCustomerProps>({
    id: "",
    fullname: "",
    email: "",
    phone: "",
    country_code: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Customers"
        initialSearch={location?.state?.search || ""}
        url={customerAPIUrls?.get}
        reload={isReload}
        onDataLoaded={() => setIsReload(false)}
        addBtnContent="Add New Customer "
        onAddClick={
          checkPermission(customerAPIUrls?.create)
            ? () => {
                setVisible(true);
                setModel({
                  id: "",
                  fullname: "",
                  email: "",
                  phone: "",
                  country_code: "",
                });
              }
            : undefined
        }
        columns={columns({
          onEditClick: checkPermission(customerAPIUrls?.update)
            ? (row: customerDataProps) => {
                setModel({
                  id: row?.id,
                  fullname: row?.fullname,
                  email: row?.email,
                  country_code: row?.country_code,
                  phone: row?.phone,
                });
                setVisible(true);
              }
            : undefined,
          onViewClick: (row: customerDataProps) => {
            setCustomer(row);
            navigate("/customer-detail/" + row?.id);
          },
        })}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ customers }: RootState) => ({ customers });

const mapDispatchToProps = (dispatch: any) => ({
  setCustomer: (payload: any) => dispatch(setCustomer(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
