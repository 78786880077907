import {
  locationAPIUrls
} from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchLocationsService = async () => {
  try {
    const data = await http.Get(locationAPIUrls?.get);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createLocationService = async (payload: any) => {
  try {
    const data = await http.Post(locationAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateLocationService = async (payload: any) => {
  try {
    const data = await http.Post(locationAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
