import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_PASSENGER,
  UPDATE_PASSENGER,
} from "./types";
import {
  createPassengerService,
  updatePassengerService,
} from "../../services";
import { setAlert } from "../basicSlices";
import { handleLoadQuery } from "../query";

function* handleCreatePassenger({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createPassengerService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Passenger!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleUpdatePassenger({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updatePassengerService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Passenger!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

export default function* passengerSaga() {
  yield takeLatest<any>(CREATE_PASSENGER, handleCreatePassenger);
  yield takeLatest<any>(UPDATE_PASSENGER, handleUpdatePassenger);
}
