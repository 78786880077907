import {
  CREATE_PERMISSION,
  FETCH_PERMISSION_LIST,
  UPDATE_PERMISSION,
} from "./types";

export const fetchPermissions = (payload?: any) => {
  return {
    type: FETCH_PERMISSION_LIST,
    payload,
  };
};

export const createPermission = (payload: any) => {
  return {
    type: CREATE_PERMISSION,
    payload,
  };
};

export const updatePermission = (payload: any) => {
  return {
    type: UPDATE_PERMISSION,
    payload,
  };
};
