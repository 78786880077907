import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAk_fQTbq8KmPUdevkwt71Riq42J8JMjxs",
  authDomain: "cyber-ace.firebaseapp.com",
  projectId: "cyber-ace",
  storageBucket: "cyber-ace.appspot.com",
  messagingSenderId: "634924887858",
  appId: "1:634924887858:web:9270c90ce5ff503166312b",
  measurementId: "G-G2DP3C4TGK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging: any = getMessaging(app);
export * from "./pushNotification";
