import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { dashboardProps } from "src/interfaces/dashboard.interfaces";

const initialState: dashboardProps = {
  totalQueries: {
    WalkIn: 0,
    Phone: 0,
    Website: 0,
  },
  queriesByStatus: 0,
  transactions: "$0",
  openfollowup: 0,
  actionablequery: [],
  queries: [],
};

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    setDashboards: (state, action: PayloadAction<dashboardProps>) => {
      return action.payload;
    },
    removeDashboards: () => {
      return initialState;
    },
  },
});

export const { setDashboards, removeDashboards } = dashboardsSlice.actions;

export const selectDashboards = (state: RootState) => {
  return state.dashboards;
};

export default dashboardsSlice.reducer;
