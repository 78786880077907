import { useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable";
import { tableFields } from "./constants";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { fetchQueries } from "src/stores/query";
import {
  authUserProps,
  customerDataProps,
  queryDataProps,
  queryProps,
} from "src/interfaces";
import ViewCustomer from "../../Customers/AddUpdate";
import { useState } from "react";
import { queryAPIUrls } from "src/common/apiActions";

function Component({
  queries,
  authUser,
}: {
  queries: queryProps;
  authUser: authUserProps;
}) {
  const renderData = queries?.data || [];
  const navigate = useNavigate();

  const onQueryClick = (item: queryDataProps) => {
    navigate("/query-detail/" + item?.id);
  };
  const [customer, setCustomer] = useState<customerDataProps | null>(null);
  const onCustomerClick = (item: queryDataProps) => {
    setCustomer(item?.customer);
  };
  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="My Actionable"
        searchable={false}
        columns={tableFields(
          (item: queryDataProps) => {
            onQueryClick(item);
          },
          (item: queryDataProps) => {
            onCustomerClick(item);
          }
        )}
        url={queryAPIUrls?.actionable}
        data={renderData}
        statusOptions={[
          { label: "Open", default: true, val: "0" },
          { label: "Query Done", val: "9" },
        ]}
      />

      <ViewCustomer
        model={customer as any}
        isView={true}
        setVisible={() => setCustomer(null)}
        visible={!!customer}
      />
    </>
  );
}

const mapStateToProps = ({ queries, authUser }: RootState) => ({
  queries,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchQueries(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
