import { connect } from "react-redux";
import Button from "src/base-components/Button";
import {
  FormInput,
  FormLabel,
  InputGroup,
} from "src/base-components/Form";
import SlideoverModal from "src/components/SlideoverModal";
import { useState } from "react";
import Litepicker from "src/base-components/Litepicker";
import Lucide from "src/base-components/Lucide";
import {
  refundPaymentProps,
} from "src/interfaces/payment.interfaces";
import { alertProps, queryDataProps } from "src/interfaces";
import { RootState } from "src/stores";
import moment from "moment";
import LoadingIcon from "src/base-components/LoadingIcon";
import { setAlert } from "src/stores/basicSlices";
import { completeRefund, initiateRefund } from "src/stores/payment";
import { dateParser } from "src/helpers";

export const Component = ({
  setAlert,
  onInitiate,
  onComplete,
  isView,
  loading,
  visible,
  setVisible,
  model,
  setModel,
  initiate = false,
}: {
  setAlert: (payload: alertProps) => void;
  onInitiate: (payload: any) => void;
  onComplete: (payload: any) => void;
  isView: boolean;
  loading: boolean;
  visible: boolean;
  setVisible: Function;
  model: refundPaymentProps;
  setModel: React.Dispatch<React.SetStateAction<refundPaymentProps>>;
  initiate: boolean
}) => {
  const [submitting, setSubmitting] = useState(false)
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setModel((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const callback = (error) => {
    if (!error) {
      setVisible(false)
    }
    setSubmitting(false)
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    setSubmitting(true)
    let payload = { ...model, callback };
    if (initiate) {
      onInitiate(payload)
    } else {
      onComplete(payload)
    }
  }
  const title = `${initiate ? "Initiate " : "Complete "} Refund`
  return (
    <SlideoverModal
      size="md"
      visible={visible}
      onClose={() => {
        setSubmitting(false)
        setVisible(false);
      }}
      headerTitle={title}
    >
      {loading ? (
        <div className="flex h-1/2">
          <LoadingIcon color="#64748b" icon="oval" className="w-8 h-8 m-auto" />
        </div>
      ) : (
        <form className="flex flex-col h-full" onSubmit={onSubmit}>
          <div className="flex flex-col gap-5 items-center sm:flex-row">
            <div className={initiate ? "w-1/2" : 'w-full'}>
              <FormLabel className="">Date</FormLabel>
              <InputGroup className="">
                <InputGroup.Text id="input-group-email">
                  <Lucide icon="CalendarDays" />
                </InputGroup.Text>
                <Litepicker
                  value={dateParser(model?.date as any, "DD MMM, YYYY")}
                  disabled={isView}
                  onChange={(e) => {
                    const date = dateParser(e?.target?.value, "DD MMM, YYYY");
                    setModel((previous) => {
                      return {
                        ...previous,
                        date: date,
                      };
                    });
                  }}
                  options={{
                    autoApply: false,
                    showWeekNumbers: true,
                    format: "DD MMM, YYYY",
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true,
                    },
                  }}
                  className="pl-1 "
                />
              </InputGroup>
            </div>
            {initiate && <div className="w-1/2">
              <FormLabel className="">Amount</FormLabel>
              <InputGroup className="">
                <InputGroup.Text id="input-group-email">
                  <Lucide icon="DollarSign" />
                </InputGroup.Text>
                <FormInput
                  type="text"
                  name="amount"
                  required
                  readOnly={isView}
                  value={model?.amount}
                  onChange={(e) => {
                    if (model?.actual_amount && parseFloat(e?.target?.value) > parseFloat(model?.actual_amount)) {
                      setAlert({ text: 'Amount should be less then or equal to paid amount', alertType: 'Swal', swalIcon: 'info' })
                      return
                    }
                    onChangeInput(e)
                  }}
                  aria-describedby="input-group-email"
                />
              </InputGroup>
            </div>}
          </div>
          <Button
            type="submit"
            variant="primary"
            className="ml-auto mt-auto group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
            loading={submitting}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
            {title}
          </Button>
        </form>
      )}
    </SlideoverModal>
  );
};

const mapStateToProps = ({ }: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setAlert: (payload: any) => dispatch(setAlert(payload)),
  onInitiate: (payload: any) => dispatch(initiateRefund(payload)),
  onComplete: (payload: any) => dispatch(completeRefund(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
