import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_PERMISSION,
  FETCH_PERMISSION_LIST,
  UPDATE_PERMISSION,
} from "./types";
import {
  createPermissionService,
  fetchPermissionsService,
  updatePermissionService,
} from "../../services";
import { setPermissions } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadPermissions(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    let responseData: any = yield call(fetchPermissionsService, payload);
    if (Array.isArray(responseData)) {
      responseData = { data: responseData };
    }
    yield put(setPermissions(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to Load Permissions!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleCreatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(createPermissionService, payload);
    // yield handleLoadPermissions();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Permission!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(updatePermissionService, payload);
    // yield handleLoadPermissions();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update Permission!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* permissionSaga() {
  yield takeLatest<any>(FETCH_PERMISSION_LIST, handleLoadPermissions);
  yield takeLatest<any>(CREATE_PERMISSION, handleCreatePermission);
  yield takeLatest<any>(UPDATE_PERMISSION, handleUpdatePermission);
}
