import React from "react";
import Button from "src/base-components/Button";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormSelect,
} from "src/base-components/Form";
import { useEffect, useState } from "react";
import SlideoverModal from "src/components/SlideoverModal";
import Lucide from "src/base-components/Lucide";
import { connect } from "react-redux";
import { uploadTicket } from "src/stores/quotation";
import { RootState } from "src/stores";
import {
  emailTemplateProps,
  queryDataProps,
  quotationProps,
  uploadTicketProps,
} from "src/interfaces";
import { fetchEmailTemplates } from "src/stores/emailTemplate";
import TinyMCE from "src/base-components/TinyMCE";
import { isQueryOpen } from "../../helpers";
import { fetchQuotationTicket } from "src/services";
import CustomDialog from "src/base-components/CustomDialog";

export const UploadTicket = ({
  query,
  onUpload,
  fetchTemplates,
  emailTemplates,
  visible,
  setVisible,
  model,
  setModel,
  quotations,
}: {
  query?: queryDataProps;
  onUpload: (payload: uploadTicketProps) => void;
  fetchTemplates: () => void;
  emailTemplates: emailTemplateProps;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  model: uploadTicketProps;
  setModel: React.Dispatch<React.SetStateAction<uploadTicketProps>>;
  quotations: any;
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const templates = emailTemplates?.data || [];
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [ticketnames, setTicketNames] = useState([]);
  const [ticketLimit, setTicketLimit] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    // if (ticketnames?.length > 2 && model?.ticket_type == 2) {
    //   setTicketLimit(true);
    //   return;
    // }

    const payload: uploadTicketProps = {
      // email_content: model?.email_content,
      quotation_id: model?.quotation_id,
      ticket: model?.ticket,
      query_id: query?.id,
      // cc_email: model?.cc_email?.replaceAll(" ", ""),
      // ticket_type: model?.ticket_type ?? 2,
      ticket_name: model?.ticket_name,
    };
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    setSubmitting(true);
    onUpload({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  useEffect(() => {
    if (visible) {
      fetchTemplates();
    }
  }, [visible]);

  // useEffect(() => {
  //   setModel((previous) => {
  //     return {
  //       ...previous,
  //       email_content:
  //         templates?.find((ele) => ele?.id === model?.template_id)?.content ||
  //         "",
  //     };
  //   });
  // }, [model?.template_id]);

  const handleTicket = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement; // Explicitly define the type

    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      setModel((previous) => {
        return {
          ...previous,
          ticket: file,
        };
      });
    } else {
      return;
    }
  };

  const fetchTickets = async (quotationId: string) => {
    try {
      const response = await fetchQuotationTicket({
        quotation_id: quotationId,
      });
      if (response) {
        const filePaths = response
          .split(",")
          .map((path) => path.trim().replace(/^ticket\//, ""));

        const tickets = filePaths.map((path, index) => ({
          title: path,
          id: index,
        }));

        if (tickets?.length > 0) {
          setTicketNames(tickets);
        }
      } else {
        console.error("No response received");
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  // useEffect(() => {
  //   if (model?.quotation_id) {
  //     fetchTickets(model?.quotation_id);
  //   }
  // }, [model]);

  // useEffect(() => {
  //   console.log("MODEl", model);
  //   if (model?.ticket_type == 2) {
  //     setModel({ ...model, ticket_type: 2 });
  //     if (ticketnames?.length > 2) {
  //       setTicketLimit(true);
  //       return;
  //     }
  //   } else if (model?.ticket_type == 1) {
  //     setModel({ ...model, ticket_name: ticketnames[0] });
  //   }
  // }, [model?.ticket_type]);

  return (
    <SlideoverModal
      size="sm"
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle={"Upload Ticket"}
    >
      {/* {headerTitle === "Resend Ticket" && (
        <div className="mb-5">
          <FormLabel className="w-full">Select Ticket Type</FormLabel>
          <FormSelect
            className="w-full"
            id="ticket_type"
            name="ticket_type"
            required
            value={model?.ticket_type ?? 1}
            onChange={handleSelectChange as any}
          >
            {ticketOptions.map((type, typeKey) => (
              <option key={typeKey} value={type?.id}>
                {type.title}
              </option>
            ))}
          </FormSelect>
        </div>
      )} */}

      {/* {model?.ticket_type == 1 && (
        <div className="mb-5">
          <FormLabel className="w-full">Select Ticket</FormLabel>
          <FormSelect
            className="w-full"
            id="ticket_name"
            name="ticket_name"
            required
            value={model?.ticket_name ?? ticketnames[0]}
            onChange={handleSelectChange as any}
          >
            {ticketnames.map((name: any, nameKey) => (
              <option key={nameKey} value={name?.title}>
                {name?.title}
              </option>
            ))}
          </FormSelect>
        </div>
      )} */}

      <form onSubmit={handleSubmit} className="w-full flex flex-col h-full">
        <div className="w-full flex flex-col gap-5 ">
          <div>
            <FormLabel className="w-full ">
              Ticket Title <span className="text-gray-400">(optional)</span>{" "}
            </FormLabel>
            <FormInput
              type="text"
              name="cc_email"
              value={model?.ticket_name}
              onChange={(e) =>
                setModel((previous) => {
                  return {
                    ...previous,
                    ticket_name: e?.target?.value,
                  };
                })
              }
              className="w-full"
              placeholder="Enter Ticket Title"
              aria-label="Ticket Title"
            />
          </div>

          {/* {(isQueryOpen(query?.stage) || model?.ticket_type == 2) && ( */}
          <FormInput
            type="file"
            // id="ticket"
            // ref={fileInputRef}
            // value={model?.ticket?.name || ""}
            accept="application/pdf"
            title="choose ticket"
            name="ticket"
            onChange={handleTicket}
            className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600
                    file:bg-gray-50 file:border-0
                    file:me-4
                    file:py-3 file:px-4
                    dark:file:bg-gray-700 dark:file:text-gray-400 mb-3"
          />
          {/* )} */}
        </div>

        {/* <div className="w-full">
          <FormLabel className="w-full">CC Emails</FormLabel>
          <FormInput
            type="text"
            name="cc_email"
            value={model?.cc_email}
            onChange={(e) =>
              setModel((previous) => {
                return {
                  ...previous,
                  cc_email: e?.target?.value,
                };
              })
            }
            className="w-full"
            placeholder="Enter emails. e.g. example1@email.com, example2@email.com"
            aria-label="CC Emails"
          />
        </div> */}
        {/* <div className="w-full mt-3">
          <FormLabel className="w-full">Template</FormLabel>
          <FormSelect
            className="w-full"
            id="template_id"
            name="template_id"
            required
            value={model?.template_id}
            onChange={handleSelectChange as any}
          >
            <option value={""}>--Select Email Template--</option>
            {templates.map((template, templateKey) => (
              <option key={templateKey} value={template?.id}>
                {template.title}
              </option>
            ))}
          </FormSelect>
        </div> */}
        {/* {model?.template_id && (
          <div className="mt-3">
            <FormLabel className="w-full">Content</FormLabel>
            <div className="flex-1 w-full">
              <TinyMCE
                value={model?.email_content || ""}
                onEditorChange={(data) =>
                  setModel((previous) => {
                    return { ...previous, email_content: data };
                  })
                }
              />
              <FormHelp className="text-right">
                Maximum characters:{" "}
                {model?.email_content ? model.email_content.length : 0}
                /2000
              </FormHelp>
            </div>
          </div>
        )} */}

        <Button
          disabled={!model?.ticket}
          variant="primary"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto "
          type="submit"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Upload
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({
  queries: { detail },
  emailTemplates,
}: RootState) => ({
  query: detail,
  emailTemplates,
});

const mapDispatchToProps = (dispatch: any) => ({
  onUpload: (payload: uploadTicketProps) => dispatch(uploadTicket(payload)),
  fetchTemplates: () => dispatch(fetchEmailTemplates({ type: 1 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadTicket);
