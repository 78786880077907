import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { locationProps } from "../../interfaces";

const initialState: locationProps[] = []

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<locationProps[]>) => {
      return action.payload;
    },
    removeLocations: () => {
      return initialState;
    },
  },
});

export const { setLocations, removeLocations } = locationsSlice.actions;

export const selectLocations = (state: RootState) => {
  return state.locations;
};

export default locationsSlice.reducer;
