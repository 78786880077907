import React, { useEffect, useState } from "react";
import SlideoverModal from "../../../components/SlideoverModal";
import {
  FormInput,
  FormLabel,
  FormSelect,
} from "../../../base-components/Form";
import Lucide from "../../../base-components/Lucide";
import Button from "../../../base-components/Button";
import _ from "lodash";
import { createUser, updateUser } from "src/stores/user";
import { fetchRoles } from "src/stores/role";
import { fetchLocations } from "src/stores/location";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  authUserProps,
  createUpdateUserProps,
  locationProps,
  roleProps,
} from "src/interfaces";
import { Eye, EyeOff } from "lucide-react";
import ReactSelect from "src/base-components/ReactSelect";
import LoadingIcon from "src/base-components/LoadingIcon";
import { userTypes } from "./constants";

const Component = ({
  visible,
  setVisible,
  fetchLocations,
  locations,
  fetchRoles,
  roles,
  model = {} as any,
  setModel = () => {},
  onCreate,
  onUpdate,
  onCreateUpdateComplete,
  authUser,
}: {
  visible: boolean;
  setVisible: Function;
  fetchLocations: Function;
  locations: locationProps[];
  fetchRoles: Function;
  roles: roleProps;
  model?: createUpdateUserProps;
  setModel?: Function;
  onCreate: (payload: createUpdateUserProps) => void;
  onUpdate: (payload: createUpdateUserProps) => void;
  onCreateUpdateComplete: Function;
  authUser: authUserProps;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const roleList = roles?.data || [];
  const roleOptions = roleList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const locationOptions = locations.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (visible) {
      setLoadingRoles(true);
      setLoadingLocations(true);
      fetchRoles({
        callback: () => {
          setLoadingRoles(false);
        },
      });
      fetchLocations({
        callback: () => {
          setLoadingLocations(false);
        },
      });
    }
  }, [visible]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    let payload: any = model;
    if (payload?.id) {
      Object?.keys(payload).map((key) => {
        if (!payload[key]) {
          delete payload[key];
        }
      });
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setSubmitting(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setSubmitting(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setModel({
      ...model,
      [name]: value,
    });
  };

  return (
    <SlideoverModal
      size="md"
      headerTitle={model?.id ? "Update User" : "Create New User"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="w-full flex gap-2">
          <div className="w-1/2">
            <FormLabel>First Name</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.name || ""}
              required
              name="name"
              type="text"
              className="rounded-md"
              placeholder={"Enter first name"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Last Name</FormLabel>
            <FormInput
              onChange={onChange}
              value={model?.last_name || ""}
              name="last_name"
              type="text"
              className="rounded-md"
              placeholder={"Enter last name"}
            />
          </div>
        </div>
        <div className="w-full mt-3">
          <FormLabel>Username</FormLabel>
          <FormInput
            required
            onChange={onChange}
            value={model?.username || ""}
            type="text"
            name="username"
            placeholder={"Enter username"}
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel>Email</FormLabel>
          <FormInput
            required
            onChange={onChange}
            value={model?.email || ""}
            type="email"
            name="email"
            placeholder={"Enter email"}
          />
        </div>
        <div className="w-full mt-3 relative">
          <FormLabel>{model.id ? "Password (Optional)" : "Password"}</FormLabel>
          <FormInput
            onChange={onChange}
            value={model?.password || ""}
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="off"
            placeholder={"Enter password"}
            required={model.id ? false : true}
          />
          {showPassword ? (
            <EyeOff
              className="absolute bottom-2 right-2 cursor-pointer w-5 text-gray-500"
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <Eye
              className="absolute bottom-2 right-2 cursor-pointer w-5 text-gray-500"
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        {authUser?.user_type &&
          (authUser.user_type.includes("Admin") ||
            authUser.user_type.includes("Superadmin")) && (
            <div className="mt-2 ">
              <FormLabel>User Type</FormLabel>
              <div className="w-full">
                <ReactSelect
                  required={true}
                  options={userTypes}
                  onChange={(_: any, value) => {
                    setModel((previous) => ({
                      ...previous,
                      user_type: value,
                    }));
                  }}
                  value={model?.user_type}
                  placeholder="Select Type"
                />
              </div>
            </div>
          )}
        <div className="mt-2 ">
          <FormLabel>Role</FormLabel>
          <div className="w-full">
            <ReactSelect
              required={true}
              options={roleOptions}
              loading={loadingRoles}
              onChange={(_: any, value) => {
                setModel((previous) => ({
                  ...previous,
                  role_id: value,
                }));
              }}
              value={model?.role_id}
              placeholder="Select Role"
            />
          </div>
        </div>
        <div className="mt-2">
          <FormLabel>Location</FormLabel>
          <div className="w-full">
            <ReactSelect
              required={true}
              options={locationOptions}
              loading={loadingLocations}
              onChange={(_: any, value) => {
                setModel((previous) => ({
                  ...previous,
                  location_id: value,
                }));
              }}
              value={model?.location_id}
              placeholder="Please select office location."
            />
          </div>
        </div>
        {model.id && (
          <div className={`${model.id ? "w-full" : "w-[10rem]"} mt-3`}>
            <FormLabel>Status</FormLabel>
            <ReactSelect
              options={[
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
              ]}
              value={model?.status}
              onChange={(_, value) => {
                setModel((previous) => ({
                  ...previous,
                  status: value,
                }));
              }}
            />
          </div>
        )}
        <div className="absolute bottom-0 right-0 py-5 px-7">
          <Button
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
            type="submit"
            disabled={submitting}
            loading={submitting}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            Submit
          </Button>
        </div>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ roles, locations, authUser }: RootState) => ({
  roles,
  locations,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchRoles: (payload: any) => dispatch(fetchRoles(payload)),
  fetchLocations: (payload: any) => dispatch(fetchLocations(payload)),
  onCreate: (payload: any) => dispatch(createUser(payload)),
  onUpdate: (payload: any) => dispatch(updateUser(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
