import Tippy from "src/base-components/customTooltip"

interface componentProps extends React.HTMLAttributes<HTMLSpanElement> {
  content?: string
}
const EmergencyIcon = (props: componentProps) => {
  return (
    <div {...props} className={`inline-block min-w-2 min-h-2 w-2 h-2 rounded-full mr-1 ${!props?.className?.includes("bg-") ? "!bg-warning" : ""} ${props?.className || ""}`}>
      <Tippy
        theme="light"
        className=" right-2.5 relative"
        content={props?.content || "Emergency Ticket"}
      />
    </div>
  )
}

export default EmergencyIcon