import moment from "moment";
import { Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import { TableColumnType } from "src/components/DataTable";
import { dateParser } from "src/helpers";
import { userDataProps } from "src/interfaces";

export const columns = ({
  onEditClick,
}: {
  onEditClick?: Function | undefined;
}) => {
  return [
    {
      title: "Name",
      field: "name",
      cell: (row) => `${row?.name || ""} ${row?.last_name || ""}`,
    },
    {
      title: "Role",
      field: "",
      cell: (item) => {
        return item?.role?.name;
      },
    },
    {
      title: "Location",
      field: "",
      cell: (item) => {
        return item?.location?.name;
      },
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Created at",
      field: "created_at",
      cell: (row) => dateParser(row?.created_at, "DD-MM-YYYY"),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "action",
      hide: !onEditClick,
      cell: (row: userDataProps) => {
        if (!onEditClick) return null;
        return (
          <Menu>
            <Menu.Button
              onClick={() => {
                onEditClick && onEditClick(row);
              }}
              className="w-100 h-5 text-slate-500"
            >
              <Lucide icon="Pencil" className="w-4 h-4 mr-2 text-primary" />
            </Menu.Button>
          </Menu>
        );
      },
    },
  ] as TableColumnType<any>[];
};

export const userTypes = [
  { label: "Admin", value: "Admin" },
  { label: "Non-Customer", value: "Non-Customer" },
  { label: "Customer", value: "Customer" },
  { label: "Superadmin", value: "Superadmin" },
];
