import { call, put, takeLatest } from "redux-saga/effects";
import {
    CREATE_NOTIFICATION,
    FETCH_NOTIFICATION_LIST,
    UPDATE_NOTIFICATION,
} from "./types";
import {
    createNotificationService,
    fetchNotificationsService,
    updateNotificationService,
} from "../../services";
import { setNotifications } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadNotifications({ payload }: { payload?: any } = { payload: undefined }): Generator<any> {
    try {
        const responseData: any = yield call(fetchNotificationsService, payload?.loadMore);
        yield put(setNotifications(responseData));
        payload?.callback && payload?.callback(false, responseData);
    } catch (error: any) {
        yield put(
            setAlert({
                text: error?.message,
                type: "danger",
                title: "Failed to fetch Notifications!",
            })
        );
    } finally {
        payload?.callback && payload?.callback()
    }
}

function* handleCreateNotification({ payload }: { payload: any }): Generator<any> {
    try {
        const responseData: any = yield call(createNotificationService, payload);
        // yield handleLoadNotifications()
    } catch (error: any) {
        yield put(
            setAlert({
                text: error?.message,
                type: "danger",
                title: "Failed to add new Notification!",
            })
        );
    } finally {
        payload?.callback && payload?.callback()
    }
}

function* handleUpdateNotification({ payload }: { payload: any }): Generator<any> {
    try {
        const responseData: any = yield call(updateNotificationService, payload);
        yield handleLoadNotifications({ payload: { loadMore: false } })
    } catch (error: any) {
        yield put(
            setAlert({
                text: error?.message,
                type: "danger",
                title: "Failed to update the Notification!",
            })
        );
    } finally {
        payload?.callback && payload?.callback()
    }
}

export default function* notificationSaga() {
    yield takeLatest<any>(FETCH_NOTIFICATION_LIST, handleLoadNotifications);
    yield takeLatest<any>(CREATE_NOTIFICATION, handleCreateNotification);
    yield takeLatest<any>(UPDATE_NOTIFICATION, handleUpdateNotification);
}
