import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_USER,
  FETCH_ALL_USER_LIST,
  FETCH_USER_AGENTS_LIST,
  FETCH_USER_LIST,
  UPDATE_USER,
} from "./types";
import {
  createUserService,
  fetchAllUsersService,
  fetchUsersAgentsService,
  fetchUsersService,
  updateUserService,
} from "../../services";
import { setUserAgents, setUsers } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadUsers(
  { payload }: { payload: any } = { payload: undefined }
): Generator<any> {
  try {
    yield put(setUsers({ data: [] } as any));
    let responseData: any = yield call(fetchUsersService, payload);
    if (Array.isArray(responseData)) {
      responseData = { data: responseData };
    }
    payload?.callback && payload?.callback(responseData?.data || []);
    yield put(setUsers(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Users!",
      })
    );
    payload?.callback && payload?.callback();
  }
}

function* handleLoadAllUsers(
  { payload }: { payload: any } = { payload: undefined }
): Generator<any> {
  try {
    yield put(setUsers({ data: [] } as any));
    let responseData: any = yield call(fetchAllUsersService, payload);
    if (Array.isArray(responseData)) {
      responseData = { data: responseData };
    }
    payload?.callback && payload?.callback(responseData?.data || []);
    yield put(setUsers(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Users!",
      })
    );
    payload?.callback && payload?.callback();
  }
}

function* handleLoadUsersAgents(): Generator<any> {
  try {
    yield put(setUserAgents({ data: [] } as any));

    let responseData: any = yield call(fetchUsersAgentsService);
    if (Array.isArray(responseData)) {
      responseData = { data: responseData };
      yield put(setUserAgents(responseData));
    }
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Users!",
      })
    );
  }
}

function* handleCreateUser({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createUserService, payload);
    // yield handleLoadUsers();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create User!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdateUser({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updateUserService, payload);
    // yield handleLoadUsers();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update User!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* userSaga() {
  yield takeLatest<any>(FETCH_USER_LIST, handleLoadUsers);
  yield takeLatest<any>(FETCH_ALL_USER_LIST, handleLoadAllUsers);
  yield takeLatest<any>(FETCH_USER_AGENTS_LIST, handleLoadUsersAgents);
  yield takeLatest<any>(CREATE_USER, handleCreateUser);
  yield takeLatest<any>(UPDATE_USER, handleUpdateUser);
}
