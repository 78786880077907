import { fetch as http } from "./http";

const amadeusBaseUrl = process.env.REACT_APP_AMADEUS_BASE_URL;
const baseUrl: string = amadeusBaseUrl + "/v1/reference-data/locations";

export const fetchAirportCityDetailService = async (
  code: string,
  token: string
) => {
  try {
    const data = await http.Get(`${baseUrl}/${code}`, token, true);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
