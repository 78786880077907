import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { notificationsProps } from "../../interfaces";

const initialState: notificationsProps = {} as any

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<notificationsProps>) => {
      return { ...state, ...action.payload };
    },
    removeNotifications: () => {
      return initialState;
    },
  },
});

export const { setNotifications, removeNotifications } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => {
  return state.notifications;
};

export default notificationsSlice.reducer;
