import React, { useEffect, useState } from "react";
import SlideoverModal from "../../components/SlideoverModal";
import { FormInput, FormLabel, FormSelect } from "../../base-components/Form";
import Lucide from "../../base-components/Lucide";
import Button from "../../base-components/Button";
import _ from "lodash";
import { createCustomer, updateCustomer } from "src/stores/customer";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import { createUpdateCustomerProps, locationProps } from "src/interfaces";
import PhoneInput from "src/components/PhoneInput";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel = () => {},
  onCreate,
  onUpdate,
  onCreateUpdateComplete,
  isView = false,
}: {
  visible: boolean;
  setVisible: Function;
  editing?: boolean;
  model?: createUpdateCustomerProps;
  setModel?: Function;
  onCreate: (payload: createUpdateCustomerProps) => void;
  onUpdate: (payload: createUpdateCustomerProps) => void;
  onCreateUpdateComplete?: Function;
  isView?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isView) {
      return;
    }
    setLoading(true);
    let payload: any = model;
    if (payload?.id) {
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setLoading(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });

    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setLoading(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isView) {
      return;
    }
    const name = event?.target?.name;
    const value = event?.target?.value;
    setModel({
      ...model,
      [name]: value,
    });
  };
  return (
    <SlideoverModal
      size="md"
      headerTitle={
        isView
          ? "Customer"
          : model?.id
          ? "Update Customer"
          : "Create New Customer"
      }
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="w-full">
          <FormLabel>Full Name</FormLabel>
          <FormInput
            w-full
            onChange={onChange}
            value={model?.fullname || ""}
            required
            readOnly={isView}
            name="fullname"
            type="text"
            className="rounded-md"
            placeholder={"Enter full name"}
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel>Email</FormLabel>
          <FormInput
            readOnly={isView}
            onChange={onChange}
            value={model?.email || ""}
            type="email"
            name="email"
            placeholder={"Enter email"}
          />
        </div>
        <div className="w-full mt-3 relative">
          <FormLabel className="">Phone</FormLabel>{" "}
          {isView ? (
            <FormInput
              required
              readOnly={isView}
              value={`${model?.country_code}${model?.phone}` || ""}
              placeholder={"Phone"}
            />
          ) : (
            <PhoneInput
              {...({} as any)}
              value={`${model?.country_code}${model?.phone}` || ""}
              name="phone"
              placeholder="#Phone"
              className="rounded-r-md z-10"
              onChange={(payload) => {
                if (isView) {
                  return null;
                }
                setModel({
                  ...model,
                  phone: payload.phone,
                  country_code: payload.dialCode,
                });
              }}
            />
          )}
        </div>
        {!isView && (
          <div className="absolute bottom-0 right-0 py-5 px-7">
            <Button
              className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              <Lucide
                icon="Check"
                className="stroke-[1.3] w-4 h-4 mr-2 -ml-2"
              />
              Submit
            </Button>
          </div>
        )}
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ roles, locations }: RootState) => ({
  roles,
  locations,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (payload: any) => dispatch(createCustomer(payload)),
  onUpdate: (payload: any) => dispatch(updateCustomer(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
