import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_ROLE, FETCH_ROLE_LIST, UPDATE_ROLE } from "./types";
import {
  createRoleService,
  fetchRolesService,
  updateRoleService,
} from "../../services";
import { setRoles } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadRoles(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    const responseData: any = yield call(fetchRolesService);
    yield put(setRoles(responseData));
    payload?.callback && payload?.callback();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Roles!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleCreateRole({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createRoleService, payload);
    // yield handleLoadRoles()
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Role!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdateRole({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updateRoleService, payload);
    // yield handleLoadRoles()
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Role!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* roleSaga() {
  yield takeLatest<any>(FETCH_ROLE_LIST, handleLoadRoles);
  yield takeLatest<any>(CREATE_ROLE, handleCreateRole);
  yield takeLatest<any>(UPDATE_ROLE, handleUpdateRole);
}
