import { useEffect, useState } from "react";
import _ from "lodash";
import FileIcon from "src/base-components/FileIcon";
import Card from "src/components/Card";
import LoadingIcon from "src/base-components/LoadingIcon";
import SlideoverModal from "src/components/SlideoverModal";
import { baseUrl } from "src/services/http";

function FileManager({ data, loading }: any) {
  const [billingDocTitle, setBillingDocTitle] = useState<string>("");
  const [
    superlargeSlideoverSizePreviewFiles,
    setSuperlargeSlideoverSizePreviewFiles,
  ] = useState(false);
  const [selectedBillingDoc, setSelectedBillingDoc] = useState<any>(null);

  const getFileType = (filename: string) => {
    return filename?.split(".").pop();
  };

  const getFirstPartBeforeSlash = (path: string) => {
    return path?.split("/")[0];
  };

  const handleBillingDoc = (item: any) => {
    setSelectedBillingDoc(item);
    setBillingDocTitle(
      item?.name && item?.name != "null"
        ? getFirstPartBeforeSlash(item?.name)
        : item?.file
    );
    setSuperlargeSlideoverSizePreviewFiles(true);
  };

  return (
    <div className="grid grid-cols-12 gap-y-10 gap-x-6">
      <div className="col-span-12">
        <div className="gap-5 flex flex-col sm:flex-row">
          <Card className="flex flex-col w-full box box--stacked">
            {loading ? (
              <div className="flex w-[100%] justify-center py-16">
                <LoadingIcon
                  color="#64748b"
                  icon="oval"
                  className="w-8 h-8 mx-auto"
                />
              </div>
            ) : data?.length == 0 ? (
              <div className="flex w-[100%] justify-center py-28">
                No Documents Available
              </div>
            ) : (
              <div className="flex flex-col gap-6 px-5">
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3.5 gap-x-4">
                    {data &&
                      data.map((item: any, indexKey) => (
                        <div
                          key={indexKey}
                          onClick={() => {
                            handleBillingDoc(item);
                          }}
                        >
                          <div className="flex items-center relative px-3 py-2.5 rounded-[0.6rem] border border-slate-200/80 hover:bg-slate-50 cursor-pointer transition sm:px-5 shadow-sm">
                            <div className="ml-5">
                              {(() => {
                                return (
                                  <FileIcon
                                    className="w-11 h-11"
                                    variant="file"
                                    type={getFileType(item?.file)}
                                  />
                                );
                              })()}
                            </div>
                            <div className="flex flex-col w-full ml-3 lg:items-center lg:flex-row gap-y-1">
                              <a
                                href=""
                                className="block font-medium capitalize truncate lg:text-center"
                              >
                                {item?.name
                                  ? getFirstPartBeforeSlash(item?.name)
                                  : item?.file}
                              </a>
                              <div className="mr-4 text-xs lg:text-center lg:ml-auto text-slate-500/80">
                                {item?.date}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
      <SlideoverModal
        size="xl"
        visible={superlargeSlideoverSizePreviewFiles}
        onClose={() => {
          setSuperlargeSlideoverSizePreviewFiles(false);
        }}
        headerTitle={billingDocTitle ?? "Attachment"}
      >
        <div className="h-[100%]">
          {selectedBillingDoc && (
            <iframe
              src={`${baseUrl}/billing_doc/${selectedBillingDoc?.file}`}
              width="100%"
              height="100%"
            />
          )}
        </div>
      </SlideoverModal>
    </div>
  );
}

export default FileManager;
