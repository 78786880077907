import ReactDOM from "react-dom/client";
import "./assets/css/app.css";
import AppContent from "./AppContent";
import AppProviders from "./AppProviders";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV == "Production") {
  try {
    Sentry.init({
      dsn: "https://3d99311b298aa2f326577035ecd2f524@o4506772476264448.ingest.us.sentry.io/4506987933270016",
      integrations: [Sentry.browserTracingIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "https://crm.millwoodstravels.ca",
        /^https:\/\/yourserver\.io\/api/,
      ],
    });
  } catch (error) {
    console.log("Sentry: ", error);
  }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <AppProviders>
    <AppContent />
  </AppProviders>
);
