import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { customerProps } from "../../interfaces";

const initialState: customerProps = {} as any;

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<customerProps>) => {
      return action.payload
    },
    removeCustomers: () => {
      return initialState;
    },
  },
});

export const { setCustomers, removeCustomers } = customersSlice.actions;

export const selectCustomers = (state: RootState) => {
  return state.customers;
};

export default customersSlice.reducer;

