import {
  CREATE_USER,
  FETCH_USER_LIST,
  FETCH_USER_AGENTS_LIST,
  UPDATE_USER,
  FETCH_ALL_USER_LIST,
} from "./types";

export const fetchUsers = (payload?: any) => {
  return {
    type: FETCH_USER_LIST,
    payload,
  };
};

export const fetchAllUsers = (payload?: any) => {
  return {
    type: FETCH_ALL_USER_LIST,
    payload,
  };
};

export const fetchUserAgents = () => {
  return {
    type: FETCH_USER_AGENTS_LIST,
  };
};

export const createUser = (payload: any) => {
  return {
    type: CREATE_USER,
    payload,
  };
};

export const updateUser = (payload: any) => {
  return {
    type: UPDATE_USER,
    payload,
  };
};
