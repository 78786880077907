import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootSaga from "./sagas";
import allReducers from './reducers'
import { HANDLE_LOGOUT } from "./auth/types";

const rootReducer = combineReducers(allReducers);
const sagaMiddleware = createSagaMiddleware();


// Configure Redux Persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authUser", "customer"],
  // blacklist: ["alert", "globalSearch", "notifications"]
};

const resettableReducer = (state: any, action: any) => {
  // If the action is a reset action, return the initial state of all reducers
  if (action.type === HANDLE_LOGOUT) {
    state = undefined;
  }

  return rootReducer(state, action);
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer({ ...persistConfig }, resettableReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer, // Pass persisted reducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
      immutableCheck: false, // Disable immutable check
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

// Run the root saga
sagaMiddleware.run(rootSaga);


// Export necessary types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export * from './hooks'
