import React, { useEffect, useState } from "react";
import SlideoverModal from "src/components/SlideoverModal";
import icon from "src/assets/images/icon/pdf.svg";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { uploadTicketProps } from "src/interfaces";
import { deleteTicket, uploadTicket } from "src/stores/quotation";
import { baseUrl } from "src/services/http";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import CustomDialog from "src/base-components/CustomDialog";
import _ from "lodash";
import { checkPermission } from "src/helpers";
import { quotationAPIUrls } from "src/common/apiActions";
import { isQueryCancelled } from "../../helpers";

interface TicketItem {
  ticket?: string;
  name?: string;
  title?: string;
}

function TicketPdf({
  ticket,
  query,
  onDelete,
  quotation,
  acceptedQuotation,
  onButtonClick,
}) {
  const [superlargeSlideoverSizePreview, setSuperlargeSlideoverSizePreview] =
    useState(false);
  const [ticketDeletionConfirm, setTicketDeletionConfirm] =
    useState<boolean>(false);
  const [ticketsData, setTicketsData] = useState<any>(null);
  const [selectedTicket, setSelectedTicket] = useState<any>("");
  const [selectedName, setSelectedName] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);

  const handleDelete = async (filePath: string, name: string) => {
    try {
      const payload = {
        query_id: query?.id,
        quotation_id: query?.quotation_id,
        ticket: filePath,
        ticketName: name,
      };
      await onDelete(payload);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const removePrefix = (filename, prefix) => {
    let regex = new RegExp(`^${prefix}`);
    return filename.replace(regex, "");
  };

  useEffect(() => {
    const currentQuotation = acceptedQuotation?.find(
      (item: any) => item?.id === quotation?.id
    );
    if (currentQuotation?.formattedTickets?.length > 0) {
      setTicketsData({
        type: "new",
        data: currentQuotation?.formattedTickets,
      });
    } else {
      if (ticket) {
        const filePaths = ticket.split(",");

        if (filePaths?.length > 0) {
          setTicketsData({ type: "old", data: filePaths });
        }
      }
    }
  }, [ticket, acceptedQuotation]);

  const onDeleteTicket = () => {
    setSubmit(true);
    handleDelete(selectedTicket, selectedName);
  };

  const extractTicketInfo = (prefix, filename) => {
    const regex = new RegExp(`${prefix}_(.*)\\.pdf$`);
    const match = filename.match(regex);
    return match ? match[1] : null;
  };

  const handleButtonClick = (item: TicketItem | string) => {
    setSelectedTicket(_.isObject(item) ? item.ticket : item);
    setSuperlargeSlideoverSizePreview(true);
  };

  const handleTrashClick = (item: TicketItem | string) => {
    setSelectedTicket(_.isObject(item) ? item.ticket : item);
    setSelectedName(
      _.isObject(item)
        ? item.name && item.name !== "null"
          ? item.name
          : item.ticket || "Attachment"
        : _.isString(item)
        ? item
        : "Attachment"
    );

    setTicketDeletionConfirm(true);
  };

  return (
    <div className=" flex text-left items-center gap-2 flex-wrap">
      <CustomDialog
        buttonModalPreview={ticketDeletionConfirm}
        setButtonModalPreview={setTicketDeletionConfirm}
        description="Are you sure you want to delete?"
        onSave={onDeleteTicket}
        onCancel={() => setTicketDeletionConfirm(false)}
        saveTitle="Proceed anyway"
        submit={submit}
      />
      {ticketsData &&
        ticketsData?.data?.map((item: TicketItem) => {
          const displayName = (item: TicketItem) => {
            if (_.isObject(item)) {
              if (item.name && item.name !== "null") {
                return item.name.length > 10
                  ? `${item.name.slice(0, 10)}..`
                  : item.name;
              } else {
                return extractTicketInfo(
                  quotation?.quotation_code,
                  (item?.ticket as string)?.trim().replace(/^ticket\//, "")
                );
              }
            } else {
              const ticketInfo = extractTicketInfo(
                quotation?.quotation_code,
                (item as string)?.trim().replace(/^ticket\//, "")
              );
              return ticketInfo ?? "Attachment";
            }
          };

          return (
            <div>
              <div className="flex items-center border border-gray-300 p-[3px] rounded-md">
                <Button
                  variant="outline-primary"
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    handleButtonClick(item);
                  }}
                  className="flex items-center border-0 text-xs"
                >
                  <img className="h-5 w-5" src={icon} alt="pdf" />
                  <div className="font-semibold ml-1">{displayName(item)}</div>
                </Button>

                <Lucide
                  icon="Trash"
                  className="w-4 h-4 text-red-500 cursor-pointer"
                  onClick={() => handleTrashClick(item)}
                />
              </div>
            </div>
          );
        })}

      <SlideoverModal
        size="xl"
        visible={superlargeSlideoverSizePreview}
        onClose={() => {
          setSuperlargeSlideoverSizePreview(false);
        }}
        headerTitle={`Ticket-(${query?.query_no})`}
      >
        <div className="h-[100%]">
          {ticket && (
            <iframe
              src={`${baseUrl}/${selectedTicket}`}
              width="100%"
              height="100%"
            />
          )}
        </div>
      </SlideoverModal>

      {onButtonClick && query?.status !== 9 && (
        <div className="block">
          {checkPermission(quotationAPIUrls?.uploadTicket) &&
            !isQueryCancelled(query?.status) &&
            query?.payment?.some(
              (item: any) =>
                item?.quotation_id === quotation?.id &&
                item?.status_text === "Completed"
            ) &&
            query?.accepted_quotation
              ?.filter(
                (item: any) =>
                  item?.status_text === "Accepted" ||
                  item?.status_text === "Ticket Sent"
              )
              ?.map((value) => {
                if (value?.id?.includes(quotation?.id)) {
                  return (
                    <Button
                      variant="outline-primary"
                      onClick={(event: React.MouseEvent) => {
                        onButtonClick();
                      }}
                      className="mr-1 flex justify-center items-center border-0 text-primary bg-gray-300 text-sm"
                    >
                      <Lucide
                        icon={"Plus"}
                        className=" w-5 h-5 text-primary font-bold "
                      />
                    </Button>
                  );
                }
              })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ queries: { detail } }: RootState) => ({
  query: detail,
});
const mapDispatchToProps = (dispatch: any) => ({
  onUpload: (payload: uploadTicketProps) => dispatch(uploadTicket(payload)),
  onDelete: (payload: uploadTicketProps) => dispatch(deleteTicket(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPdf);
