import DataTable from "../../../components/DataTable";
import AddUpdate from "./AddUpdate";
import { useEffect, useState } from "react";
import { fetchUsers } from "src/stores/user";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdateUserProps,
  userProps,
  userDataProps,
} from "src/interfaces";
import { columns } from "./constants";
import { checkPermission } from "src/helpers";
import { userAPIUrls } from "src/common/apiActions";

const Component = ({
  fetchData,
  users,
}: {
  fetchData: Function;
  users: userProps;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = users.data || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<createUpdateUserProps>({
    name: "",
    email: "",
    password: "",
    role_id: "",
    user_type: "",
    username: "",
    location_id: "",
    status: "",
  });

  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Users"
        url={userAPIUrls?.get}
        reload={isReload}
        onDataLoaded={() => setIsReload(false)}
        addBtnContent="Add New User "
        onAddClick={
          checkPermission(userAPIUrls?.create)
            ? () => {
                setVisible(true);
                setModel({
                  name: "",
                  email: "",
                  password: "",
                  role_id: "",
                  user_type: "",
                  username: "",
                  location_id: "",
                  status: "",
                });
              }
            : undefined
        }
        columns={columns({
          onEditClick: checkPermission(userAPIUrls?.update)
            ? (row: userDataProps) => {
                setModel({
                  id: row?.id,
                  name: row?.name,
                  last_name: row?.last_name,
                  email: row?.email,
                  username: row?.username,
                  user_type: row?.user_type,
                  role_id: row?.role_id,
                  location_id: row?.location_id,
                  status: row?.status,
                });
                setVisible(true);
              }
            : undefined,
        })}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ users }: RootState) => ({ users });

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchUsers(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
