import React, { useEffect, useMemo, useState } from "react";
import SlideoverModal from "../../../components/SlideoverModal";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormSwitch,
  FormTextarea,
  InputGroup,
} from "../../../base-components/Form";
import { queryUpdateProps } from "src/interfaces";
import { connect } from "react-redux";
import { updateQuery } from "src/stores/query";
import { RootState } from "src/stores";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
// import { PlusIcon, XCircleIcon } from "lucide-react";
import { countries, queryTypes, sources, tripTypes } from "../constants";
import Litepicker from "src/base-components/Litepicker";
import moment from "moment";
import ReactSelect from "src/base-components/ReactSelect";
import { dateParser, isAdmin } from "src/helpers";
import { fetchLocations } from "src/stores/location";

const DateInput = ({ date, index, handleChange }) => {
  return (
    <div className="w-1/3">
      <FormLabel className="">Date</FormLabel>
      <div className="flex flex-row w-full align-middle justify-center items-center">
        <Litepicker
          value={date}
          placeholder="Date"
          name={`date-${index}`}
          onChange={(e) => handleChange(e.target.value, index)}
          getRef={() => {}}
          className={"w-full mx-auto"}
          options={{
            autoApply: false,
            format: "DD-MM-YYYY",
            showWeekNumbers: true,
            dropdowns: {
              minYear: moment().subtract(5, "years").year(),
              maxYear: moment().add(5, "years").year(),
              months: true,
              years: true,
            },
          }}
        />
      </div>
    </div>
  );
};

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel,
  onUpdate,
  isAssigned,
  fetchLocations,
  locations,
}: {
  visible: boolean;
  setVisible: Function;
  model: queryUpdateProps;
  setModel: Function;
  onUpdate: Function;
  isAssigned: boolean;
  fetchLocations: (payload: { callback?: Function }) => void;
  locations: any;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    if (isAdmin()) {
      fetchLocations({
        callback: () => {},
      });
    }
  }, []);

  useEffect(() => {
    if (locations) {
      const options: any = locations?.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      }));
      if (options) {
        setLocationOptions(options);
      }
    }
  }, [locations]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e?.target || {};
    if (setModel) {
      setModel((previous) => {
        return {
          ...previous,
          [name]: value,
        };
      });
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    onUpdate({
      ...model,
      query_type: parseInt(model?.query_type as any),
      trip_type: parseInt(model?.trip_type as any),
      callback: (error) => {
        setSubmitting(false);
        if (!error) {
          setVisible(false);
        }
      },
    });
  };

  const handleDateChange = (newDate, index) => {
    setModel((prevModel) => {
      const updatedArray = [...prevModel.query_itinerary_detail];
      updatedArray[index].date = newDate;
      return { ...prevModel, query_itinerary_detail: updatedArray };
    });
  };

  const handleChangeItineraryItem = (
    name: string,
    value: any,
    index: number
  ) => {
    let itineraries = [...model?.query_itinerary_detail];
    let obj = itineraries[index];
    obj = { ...obj, [name]: value };
    itineraries[index] = obj;
    setModel((previous) => {
      return { ...previous, query_itinerary_detail: itineraries };
    });
  };

  useEffect(() => {
    if (model?.query_itinerary_detail) {
      try {
        if (model.trip_type === 1 && model.query_itinerary_detail.length <= 1) {
          const updatedArray = [
            ...model.query_itinerary_detail,
            {
              source: "",
              destination: "",
              date: new Date().toISOString(),
            },
          ];

          setModel((prevModel) => ({
            ...prevModel,
            query_itinerary_detail: updatedArray,
          }));
        } else if (model.trip_type !== 1) {
          const firstObj =
            model.query_itinerary_detail.length > 0
              ? model.query_itinerary_detail[0]
              : null;
          const updatedModel = {
            ...model,
            query_itinerary_detail: firstObj ? [firstObj] : [],
          };
          setModel(updatedModel);
        }
      } catch (error) {
        console.error("Error processing model in useEffect:", error);
      }
    } else {
      console.warn("Model or query_itinerary_detail is null or undefined.");
    }
  }, [model?.trip_type]);

  const getCorrectDateFormat = (dateString) => {
    const expectedFormat = "DD-MM-YYYY";
    const isValidFormat = moment(dateString, expectedFormat, true).isValid();
    if (isValidFormat) {
      return dateString;
    } else {
      return dateParser(dateString?.split("T")[0], "DD-MM-YYYY");
    }
  };

  return (
    <SlideoverModal
      size="md"
      headerTitle={"Update Query"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="h-full w-full flex flex-col">
        <div>
          <FormLabel className="">Source</FormLabel>
          <ReactSelect
            onChange={(_: any, value) => {
              setModel((previous) => ({
                ...previous,
                source: value,
              }));
            }}
            name="query_type"
            placeholder="--Select--"
            value={model?.source?.toString()}
            options={sources}
            className="w-full z-30"
          />
        </div>
        {isAdmin() && !isAssigned && (
          <div className="mt-2">
            <FormLabel className="">Location</FormLabel>
            <ReactSelect
              onChange={(_: any, value) => {
                setModel((previous) => ({
                  ...previous,
                  location_id: value,
                }));
              }}
              name="query_type"
              placeholder="--Select--"
              value={model?.location_id?.toString()}
              options={locationOptions}
              className="w-full z-30"
            />
          </div>
        )}
        <div className="mt-2 w-full flex justify-between gap-2 items-center">
          <div className="w-60 z-20">
            <FormLabel className="">Type</FormLabel>
            <ReactSelect
              onChange={(_: any, value) => {
                setModel((previous) => ({
                  ...previous,
                  type: value,
                }));
              }}
              name="query_type"
              placeholder="--Select--"
              value={model?.query_type?.toString()}
              options={queryTypes}
              className="w-full"
            />
          </div>
          <div className="w-40 ml-4 mt-6">
            <FormSwitch className="">
              <FormSwitch.Input
                name="is_emergency"
                id="is_emergency"
                checked={model?.is_emergency == 1}
                onChange={(e) => {
                  onChangeInput({
                    target: {
                      name: "is_emergency",
                      value: model?.is_emergency == 1 ? 0 : 1,
                    },
                  });
                }}
                type="checkbox"
              />
              <FormSwitch.Label htmlFor="is_emergency" className="text-xs">
                Emergency Ticket
              </FormSwitch.Label>
            </FormSwitch>
          </div>
        </div>
        <div className="w-full mt-2">
          <FormLabel className="">Trip Type</FormLabel>
          <ReactSelect
            onChange={(_: any, value) => {
              setModel((previous) => ({
                ...previous,
                trip_type: value,
              }));
            }}
            name="query_type"
            placeholder="--Select--"
            value={model?.trip_type?.toString()}
            options={tripTypes}
            className="w-full"
          />
        </div>
        <div className="w-full mt-2">
          <FormLabel className="">No. of PAX</FormLabel>
          <div className="flex w-full gap-2">
            <InputGroup>
              <InputGroup.Text>Adult</InputGroup.Text>
              <FormInput
                name="no_of_adults"
                value={model?.no_of_adults}
                onChange={onChangeInput}
                min="1"
                type="number"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Child</InputGroup.Text>
              <FormInput
                defaultValue="0"
                name="no_of_children"
                value={model?.no_of_children}
                onChange={onChangeInput}
                min="0"
                type="number"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Infant</InputGroup.Text>
              <FormInput
                defaultValue="0"
                name="no_of_infants"
                value={model?.no_of_infants}
                onChange={onChangeInput}
                min="0"
                type="number"
              />
            </InputGroup>
          </div>
        </div>
        <div className="mt-2 w-full">
          <FormLabel className="">Itinerary details</FormLabel>
          {model?.query_itinerary_detail?.map((field, index) => (
            <div className="w-full flex gap-2 mb-2" key={index}>
              <div className="w-1/3">
                <FormLabel className="">Source</FormLabel>
                <FormInput
                  onChange={(e) => {
                    handleChangeItineraryItem(
                      "source",
                      e?.target?.value,
                      index
                    );
                  }}
                  name="source"
                  defaultValue={field?.source}
                  value={field?.source}
                  required={true}
                  className="w-full"
                />
              </div>
              <div className="w-1/3">
                <FormLabel className="">Destination</FormLabel>
                <FormInput
                  defaultValue={field?.destination}
                  value={field?.destination}
                  onChange={(e) => {
                    handleChangeItineraryItem(
                      "destination",
                      e?.target?.value,
                      index
                    );
                  }}
                  name="destination"
                  required={true}
                  className="w-full"
                />
              </div>

              <DateInput
                date={getCorrectDateFormat(field?.date)}
                index={index}
                handleChange={(newDate) => handleDateChange(newDate, index)}
              />
            </div>
          ))}
        </div>
        <div>
          <FormLabel className="">Remarks</FormLabel>
          <FormTextarea
            className="mb-3"
            rows={7}
            defaultValue={model?.remarks}
            value={model?.remarks}
            name="remarks"
            onChange={onChangeInput}
          />
        </div>
        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="submit"
          disabled={submitting}
          loading={submitting}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ authUser, locations }: RootState) => ({
  authUser,
  locations,
});

const mapDispatchToProps = (dispatch: any) => ({
  onUpdate: (payload: any) => dispatch(updateQuery(payload)),
  fetchLocations: (payload: any) => dispatch(fetchLocations(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
