import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  activityLogProps,
  queryDataProps,
  queryProps,
  quotationDataProps,
  paymentProps,
} from "../../interfaces";

const initialState: queryProps = {} as any;

export const queriesSlice = createSlice({
  name: "queries",
  initialState,
  reducers: {
    setQueries: (state, action: PayloadAction<queryProps>) => {
      return { ...state, ...action.payload };
    },
    setQuery: (state, action: PayloadAction<queryDataProps>) => {
      return { ...state, detail: action.payload };
    },
    setActivityLogs: (state, action: PayloadAction<activityLogProps>) => {
      return {
        ...state,
        detail: {
          ...state?.detail,
          activity_log: { ...state?.detail?.activity_log, ...action.payload },
        } as any,
      };
    },
    setQuotations: (state, action: PayloadAction<quotationDataProps>) => {
      return { ...state, quotations_detail: action.payload };
    },
    setQueryQueues: (state, action: any) => {
      return { ...state, queryQueues: action.payload };
    },
    removeQueryQueues: (state, action: any) => {
      return { ...state, queryQueues: action.payload };
    },
    setUserQueries: (state, action: PayloadAction<queryDataProps>) => {
      return { ...state, userQueries: action.payload };
    },
    removeQuery: (state, action: PayloadAction<queryDataProps>) => {
      return { ...state, detail: undefined };
    },
    removeUserQuery: (state, action: PayloadAction<queryDataProps>) => {
      return { ...state, userQueries: undefined };
    },
    removeQueries: () => {
      return initialState;
    },
  },
});

export const {
  setQueries,
  removeQueries,
  setQuery,
  setActivityLogs,
  setUserQueries,
  removeQuery,
  setQueryQueues,
  removeUserQuery,
  setQuotations,
} = queriesSlice.actions;

export const selectQueries = (state: RootState) => {
  return state.queries;
};

export default queriesSlice.reducer;
