import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { userProps } from "../../interfaces";

const initialState: userProps = {} as any;

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<userProps>) => {
      return { ...state, ...action.payload };
    },
    setUserAgents: (state, action: PayloadAction<userProps>) => {
      state.agents = action.payload?.data;
    },
    removeUsers: () => {
      return initialState;
    },
  },
});

export const { setUsers, removeUsers, setUserAgents } = usersSlice.actions;

export const selectUsers = (state: RootState) => {
  return state.users;
};

export default usersSlice.reducer;
