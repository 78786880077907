import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "src/components/DataTable";
import {
  sortOptions,
  sourceOptions,
  stageOptions,
  tableFields,
} from "./constants";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { viewTypes, setQueryViewType } from "src/stores/basicSlices";
import { checkPermission, isManagerOrAdmin } from "src/helpers";
import { queryAPIUrls } from "src/common/apiActions";
import {
  authUserProps,
  customerDataProps,
  queryDataProps,
  queryProps,
} from "src/interfaces";
import GridViewItem from "./GridViewItem";
import ViewCustomer from "../Customers/AddUpdate";
import { fetchUserAgents } from "src/stores/user";

function Component({
  currentView,
  setCurrentView,
  queries,
  authUser,
  fetchAgentsList,
  users,
}: {
  currentView: viewTypes;
  setCurrentView: (type: viewTypes) => void;
  queries: queryProps;
  authUser: authUserProps;
  fetchAgentsList: () => void;
  users: any;
}) {
  const location = useLocation();

  const renderData = queries?.data || [];
  const navigate = useNavigate();

  const onQueryClick = (item: queryDataProps) => {
    navigate("/query-detail/" + item?.id);
  };
  const [customer, setCustomer] = useState<customerDataProps | null>(null);
  const [agents, setAgents] = useState<any>(null);

  const onCustomerClick = (item: queryDataProps) => {
    setCustomer(item?.customer);
  };
  const defaultStageOption =
    stageOptions.find((ele) => ele?.val == location?.state?.stage)?.val || "";
  const defaultSourceOption =
    sourceOptions.find((ele) => ele?.val == location?.state?.source)?.val || "";
  const defaultSortOption =
    sortOptions.find((ele) => ele?.val == location?.state?.sortBy)?.val || "";

  useEffect(() => {
    fetchAgentsList();
  }, []);

  useEffect(() => {
    if (users?.agents) {
      const userOptions: any = [...users.agents]?.map((item) => ({
        label: `${item.name || ""} ${item.last_name || ""}`,
        val: item.id,
      }));

      if (userOptions.length > 0) {
        userOptions.unshift({ label: "All Agents", val: "", default: true });
        setAgents(userOptions);
      }
    }
  }, [users]);

  const defaultAgentOption =
    agents?.find((ele) => ele?.val == location?.state?.agents)?.val || "";

  return (
    <>
      <DataTable
        showColors={true}
        viewType={currentView}
        onViewChange={(view) => setCurrentView(view)}
        columns={tableFields(
          (item: queryDataProps) => {
            onQueryClick(item);
          },
          (item: queryDataProps) => {
            onCustomerClick(item);
          },
          authUser
        )}
        initialSearch={
          location?.search != "?search-queries"
            ? ""
            : location?.state?.search || ""
        }
        url={
          location?.search === "?search-queries"
            ? queryAPIUrls?.search
            : location?.search === "?all"
            ? queryAPIUrls?.all
            : queryAPIUrls?.get
        }
        rowsPerPage={9}
        data={renderData}
        tableHeading="Queries"
        addBtnContent="Create New Query"
        // onAddClick={
        //   checkPermission(queryAPIUrls?.create)
        //     ? () => navigate("/create-query")
        //     : undefined
        // }
        gridViewRenderItem={(row, index) => (
          <GridViewItem
            key={index}
            item={row}
            index={index}
            onItemClick={(item) => {
              onQueryClick(item);
            }}
          />
        )}
        showSortOrder={true}
        showDateFilter={true}
        showClearFilter={true}
        statusOptions={stageOptions?.map((item) => {
          return { ...item, default: item?.val == defaultStageOption };
        })}
        sourceOptions={sourceOptions?.map((item) => {
          return { ...item, default: item?.val == defaultSourceOption };
        })}
        sortOptions={sortOptions?.map((item) => {
          return { ...item, default: item?.val == defaultSortOption };
        })}
        agentOptions={
          isManagerOrAdmin()
            ? agents?.map((item) => {
                return { ...item, default: item?.val == defaultAgentOption };
              })
            : []
        }
        sourceFilterKey="source"
        statusFilterKey="stage"
        agentsFilterKey="agentId"
        directionFilterKey="sortDirection"
        sortFilterKey="sortBy"
        toggleFilterKey="emergency"
        toggleLabel="Emergency"
      />
      <ViewCustomer
        model={customer as any}
        isView={true}
        setVisible={() => setCustomer(null)}
        visible={!!customer}
      />
    </>
  );
}

const mapStateToProps = ({
  queryViewType,
  queries,
  authUser,
  users,
}: RootState) => ({
  currentView: queryViewType,
  queries,
  authUser,
  users,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentView: (payload: any) => dispatch(setQueryViewType(payload)),
  fetchAgentsList: () => dispatch(fetchUserAgents()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
