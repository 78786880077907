import Lucide from "../../../base-components/Lucide";
import { FormInput } from "../../../base-components/Form";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchDashboardGlobalSearch } from "src/stores/dashboard";
import { RootState } from "src/stores";
import { customerDataProps, globalSearchDataProps } from "src/interfaces";
import {
  detailLinkEnum,
  displayKeys,
  iconEnum,
  linkEnum,
  titleEnum,
} from "./constants";
import LoadingIcon from "src/base-components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { setCustomer } from "src/stores/customer";
import { customColorGenerator, dateParser } from "src/helpers";

interface MainProps {
  quickSearch: boolean;
  setQuickSearch: (val: boolean) => void;
  globalSearch: globalSearchDataProps;
  fetchData: (payload: { keywords: string; callback: Function }) => void;
  setCustomer: (payload: customerDataProps) => void;
}

function Component(props: MainProps) {
  const navigate = useNavigate();
  const { fetchData, globalSearch } = props;
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState("");
  const [searching, setSearching] = useState(false);

  const handleSearch = (text: string) => {
    if (text) {
      setSearching(true);
      setDisplay("");
      fetchData({ keywords: text, callback: () => setSearching(false) });
    }
  };

  const handleInput = (event: any) => {
    const { value } = event?.target;
    setSearch(value);
  };

  useEffect(() => {
    document.onkeydown = function (evt) {
      if (evt.key === "Escape" || evt.key === "Esc") {
        props.setQuickSearch(false);
      } else if ((evt.ctrlKey || evt.metaKey) && evt.key === "k") {
        props.setQuickSearch(true);
      }
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  const dataKeys = Object.keys(globalSearch);
  useEffect(() => {
    if (search?.length > 2) {
      setSearching(true);
      setDisplay("");
      fetchData({ keywords: search, callback: () => setSearching(false) });
    }
  }, [search]);
  return (
    <>
      <Transition appear show={props.quickSearch} as={Fragment}>
        <HeadlessDialog
          as="div"
          className="relative z-[60]"
          onClose={props.setQuickSearch}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-50"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gradient-to-b from-primary/50 via-secondary/50 to-black/50 backdrop-blur-sm" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex justify-center my-2 sm:mt-40">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-50"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <HeadlessDialog.Panel className="sm:w-[600px] lg:w-[700px] w-[95%] relative mx-auto transition-transform">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center justify-center w-12">
                      <Lucide
                        icon="Search"
                        className="w-5 h-5 -mr-1.5 text-slate-500 stroke-[1]"
                      />
                    </div>
                    <FormInput
                      className="pl-12 pr-14 py-3.5 text-base rounded-lg focus:ring-0 border-0 shadow-lg"
                      type="text"
                      placeholder="Quick search..."
                      value={search}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        handleInput(e);
                      }}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center w-14">
                      <div className="px-2 py-1 mr-auto text-xs border rounded-[0.4rem] bg-slate-100 text-slate-500/80">
                        ESC
                      </div>
                    </div>
                  </div>
                  <div className="relative z-10 pb-1 mt-1 bg-white rounded-lg shadow-lg max-h-[468px] sm:max-h-[615px] overflow-y-auto">
                    {searching ? (
                      <div className="flex w-[100%] justify-center py-12">
                        <LoadingIcon
                          color="#64748b"
                          icon="oval"
                          className="w-8 h-8 mx-auto"
                        />
                      </div>
                    ) : !search ||
                      !dataKeys?.some((key) => globalSearch[key].length > 0) ? (
                      <div className="flex flex-col items-center justify-center pt-20 pb-28">
                        <Lucide
                          icon="SearchX"
                          className="w-20 h-20 text-primary/20 fill-primary/5 stroke-[0.5]"
                        />
                        <div className="mt-5 text-xl font-medium">
                          No result {search && "found"}
                        </div>
                        <div className="w-2/3 mt-3 leading-relaxed text-center text-slate-500">
                          No results{" "}
                          {search && (
                            <span>
                              found for{" "}
                              <span className="italic font-medium">
                                "{search}
                              </span>
                              ". Please try a different search term or check
                              your spelling.
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="px-5 py-4">
                          <div className="flex items-center">
                            <div className="text-xs uppercase text-slate-500">
                              Start your search here...
                            </div>
                          </div>
                          <div className="flex flex-wrap gap-2 mt-3.5">
                            <div
                              onClick={() => {
                                setDisplay("");
                              }}
                              className={`cursor-pointer ${
                                display == "" ? "!bg-gray-200" : ""
                              } flex items-center gap-x-1.5 border rounded-full px-3 py-0.5 border-slate-300/70 hover:bg-slate-50`}
                            >
                              <Lucide
                                icon={"View"}
                                className="w-4 h-4 stroke-[1.3]"
                              />
                              All
                            </div>
                            {dataKeys?.map((key, keyIndex) => {
                              if (globalSearch[key]?.length == 0) {
                                return null;
                              }
                              return (
                                <div
                                  key={keyIndex}
                                  onClick={() => {
                                    setDisplay(key);
                                  }}
                                  className={`cursor-pointer ${
                                    display == key ? "!bg-gray-200" : ""
                                  } flex items-center gap-x-1.5 border rounded-full px-3 py-0.5 border-slate-300/70 hover:bg-slate-50`}
                                >
                                  <Lucide
                                    icon={iconEnum[key]}
                                    className="w-4 h-4 stroke-[1.3]"
                                  />
                                  {titleEnum[key]}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {dataKeys
                          ?.filter((ele) =>
                            display ? ele == display : ele != null
                          )
                          ?.map((key, keyIndex) => {
                            if (globalSearch[key]?.length == 0) {
                              return null;
                            }
                            return (
                              <div
                                key={keyIndex}
                                className="px-5 py-4 border-t border-dashed"
                              >
                                <div className="flex items-center">
                                  <div className="text-xs uppercase text-slate-500">
                                    {titleEnum[key]}
                                  </div>
                                  <div
                                    className="ml-auto text-xs text-slate-500 cursor-pointer"
                                    onClick={() => {
                                      if (!linkEnum[key]) {
                                        return;
                                      }
                                      props?.setQuickSearch(false);
                                      setSearch("");
                                      navigate(linkEnum[key], {
                                        state: { search },
                                      });
                                    }}
                                  >
                                    See All
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1 mt-3.5">
                                  {globalSearch[key]?.map(
                                    (item: any, itemIndex) => {
                                      const randomColor = customColorGenerator(
                                        item?.sourced_by_user?.location
                                          ?.short_name ??
                                          item?.location?.short_name
                                      );
                                      return (
                                        <div className="flex flex-col">
                                          <div
                                            onClick={() => {
                                              if (!detailLinkEnum[key]) {
                                                return;
                                              }
                                              if (key == "customers") {
                                                props?.setCustomer(item);
                                              }
                                              props?.setQuickSearch(false);
                                              setSearch("");
                                              if (
                                                location?.pathname !=
                                                `${detailLinkEnum[key]}/${
                                                  item?.id || ""
                                                }`
                                              ) {
                                                navigate(
                                                  `${detailLinkEnum[key]}/${
                                                    item?.id || ""
                                                  }`
                                                );
                                              }
                                            }}
                                            key={itemIndex}
                                            className="cursor-pointer flex items-center gap-2.5 hover:bg-slate-50/80 border border-transparent hover:border-slate-100 p-1 rounded-md"
                                          >
                                            {titleEnum[key] !== "Queries" ? (
                                              <div className="w-6 h-6 overflow-hidden border-2 rounded-full image-fit zoom-in border-slate-200/70 box">
                                                <Lucide
                                                  className="mr-0.5 p-0.5"
                                                  icon={iconEnum[key]}
                                                />
                                              </div>
                                            ) : (
                                              <div
                                                className={`w-6 h-6 p-2 border-2 rounded-full  flex justify-center items-center`}
                                                style={{
                                                  backgroundColor:
                                                    randomColor?.backgroundColor,
                                                }}
                                              >
                                                <p
                                                  className="p-2 text-[8px] font-semibold"
                                                  style={{
                                                    color: randomColor?.color,
                                                  }}
                                                >
                                                  {item?.sourced_by_user
                                                    ?.location?.short_name ??
                                                    item?.location?.short_name}
                                                </p>
                                              </div>
                                            )}
                                            {displayKeys[key]?.map(
                                              (disKey, disKeyIndex) => {
                                                let value = item[disKey?.key];
                                                if (disKey?.type == "date") {
                                                  value = dateParser(
                                                    value,
                                                    "DD-MM-YYYY"
                                                  );
                                                }
                                                if (disKey?.type == "status") {
                                                  value = (
                                                    <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
                                                      {value}
                                                    </span>
                                                  );
                                                }
                                                if (disKey?.type == "custom") {
                                                  value =
                                                    disKey?.callback &&
                                                    disKey?.callback(item);
                                                }
                                                if (
                                                  disKey?.type == "customer"
                                                ) {
                                                  value =
                                                    disKey?.callback &&
                                                    disKey?.callback(item);
                                                }
                                                return (
                                                  <div
                                                    key={disKeyIndex}
                                                    className="font-medium truncate"
                                                  >
                                                    {value}
                                                    {disKeyIndex <
                                                      displayKeys[key]?.length -
                                                        1 &&
                                                      value &&
                                                      " | "}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </HeadlessDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </HeadlessDialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ globalSearch }: RootState) => ({
  globalSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchDashboardGlobalSearch(payload)),
  setCustomer: (payload: any) => dispatch(setCustomer(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
