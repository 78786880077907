import { call, put, takeLatest } from "redux-saga/effects";
import {} from "../../interfaces";
import { FETCH_CITY_AIRPORT_DICTIONARIES, PARSE_PNR_DETAIL } from "./types";
import { fetchAirportCityDetailService, parsePNRService } from "../../services";
import { handleFetchAmadeusToken } from "src/stores/auth";
import { store } from "..";
import { setCityAirports } from "./slice";

let cityAirportDictionaries = {};
function* handleCityAirportDictionary(code: string): Generator<any> {
  const token = yield call(handleFetchAmadeusToken);
  const responseData: any = yield call(
    fetchAirportCityDetailService as any,
    code,
    token
  );
  const finalData = {
    [code]: responseData,
  };
  const previous = store?.getState()?.cityAirports || {};
  cityAirportDictionaries = {
    ...previous,
    ...cityAirportDictionaries,
    ...finalData,
  };

  yield put(setCityAirports(cityAirportDictionaries));
}

function* handleCityAirportDictionaries({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const previous = store?.getState()?.cityAirports || {};
    for (let i = 0; i < payload?.codes?.length; i++) {
      const key = `${payload?.type || ""}${payload?.codes[i]}`;
      if (!Object?.keys(previous || {})?.includes(key)) {
        yield call(handleCityAirportDictionary, key);
      }
    }
  } catch (error) {
  } finally {
    const updated = store?.getState()?.cityAirports || {};
    payload?.callback && payload?.callback(updated);
  }
}

function* handleParsePNRDetail({ payload }: { payload: any }): Generator<any> {
  let responseData: any = null;
  try {
    responseData = yield call(parsePNRService, payload);
  } catch (error) {
  } finally {
    payload?.callback && payload?.callback(false, responseData);
  }
}

export function* cityAirportsSaga() {
  yield takeLatest<any>(
    FETCH_CITY_AIRPORT_DICTIONARIES,
    handleCityAirportDictionaries
  );
  yield takeLatest<any>(PARSE_PNR_DETAIL, handleParsePNRDetail);
}
