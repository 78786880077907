import React, { ComponentType, ElementType } from "react";
import Tippy from "../Tippy";

interface IndexProps extends React.HTMLAttributes<HTMLSpanElement> {
  mode?: ElementType<any> | ComponentType<any>;
  variant?: "primary" | "secondary";
  theme?: "light" | "dark";
  trigger?: "click" | "";
  content: string;
}

const CustomTippy: React.FC<IndexProps> = (props: IndexProps) => {
  const { mode, trigger, variant, theme, content, children } = props;

  return (
    <div>
      <Tippy
        as={mode}
        variant={variant === "primary" ? "primary" : undefined}
        options={{
          theme: theme,
          trigger: trigger,
        }}
        className={`grid justify-items-center w-8 h-8 items-center transform-none rounded-full ring-offset-0 ring-0 bg-transparent border-none ${
          props?.className || ""
        }`}
        content={content}
      >
        {children}
      </Tippy>
    </div>
  );
};

export default CustomTippy;
