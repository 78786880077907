import { FETCH_DASHBOARD_GLOBAL_SEARCH, FETCH_DASHBOARD_DATA } from "./types";

export const fetchDashboards = (payload: any) => {
  return {
    type: FETCH_DASHBOARD_DATA,
    payload,
  };
};

export const fetchDashboardGlobalSearch = (payload: any) => {
  return {
    type: FETCH_DASHBOARD_GLOBAL_SEARCH,
    payload,
  };
};
