import { twMerge } from "tailwind-merge";
import Button from "../Button";
import Lucide from "../../base-components/Lucide";
import { showColorsTips } from "src/helpers";
import Tippy from "../Tippy";

type DefaultPaginationProps = React.PropsWithChildren &
  React.ComponentPropsWithoutRef<"nav">;

type PaginationProps = DefaultPaginationProps & {
  totalItems?: number;
  rowsPerPage?: number;
  currentPage?: number;
  onPageClick?: (page: number) => void;
  onNextClick?: (page: number) => void;
  onPrevClick?: (page: number) => void;
  onFirstClick?: (page: number) => void;
  onLastClick?: (page: number) => void;
  showColors?: boolean;
};

function Pagination({
  className,
  totalItems = 0,
  rowsPerPage = 0,
  currentPage = 1,
  onPageClick,
  onFirstClick,
  onLastClick,
  onNextClick,
  onPrevClick,
  showColors = false,
}: PaginationProps) {
  let pages: number[] = [];

  for (let i = 1; i <= Math.ceil(totalItems / rowsPerPage); i++) {
    pages.push(i);
  }

  return (
    <nav className={className}>
      <ul className="flex items-center justify-between">
        <div className="flex w-full mr-0 sm:w-auto sm:mr-auto ">
          <Pagination.Link
            onClick={
              currentPage === 1
                ? undefined
                : () => {
                    onFirstClick && onFirstClick(1);
                  }
            }
          >
            <Lucide icon="ChevronsLeft" className="w-4 h-4" />
          </Pagination.Link>
          <Pagination.Link
            onClick={
              currentPage === 1
                ? undefined
                : () => {
                    onPrevClick && onPrevClick(currentPage - 1);
                  }
            }
          >
            <Lucide icon="ChevronLeft" className="w-4 h-4" />
          </Pagination.Link>
          {pages.length > 3 && currentPage > 2 && (
            <Pagination.Link>...</Pagination.Link>
          )}
          {pages
            .filter(
              (page) =>
                page >= Math.max(1, currentPage - 2) &&
                page <= Math.min(pages.length, currentPage + 2)
            )
            .map((page) => (
              <Pagination.Link
                key={page}
                disabled={currentPage === page}
                active={currentPage === page}
                onClick={() => onPageClick && onPageClick(page)}
              >
                {page}
              </Pagination.Link>
            ))}
          {pages.length > 3 && currentPage < pages.length - 1 && (
            <Pagination.Link>...</Pagination.Link>
          )}
          <Pagination.Link
            onClick={
              currentPage === pages.length
                ? undefined
                : () => {
                    onNextClick && onNextClick(currentPage + 1);
                  }
            }
          >
            <Lucide icon="ChevronRight" className="w-4 h-4" />
          </Pagination.Link>
          <Pagination.Link
            onClick={
              currentPage === pages.length
                ? undefined
                : () => {
                    onLastClick && onLastClick(pages.length);
                  }
            }
          >
            <Lucide icon="ChevronsRight" className="w-4 h-4" />
          </Pagination.Link>
        </div>
        {showColors && (
          <div className="flex flex-row gap-x-2">
            <p>Legend</p>
            {showColorsTips?.map((item: any, index: number) => (
              <Tippy
                as="div"
                options={{ theme: "light" }}
                content={item?.name}
                key={index}
                className="flex items-center"
              >
                <div className={`w-4 h-4 rounded-full ${item?.code}`} />
              </Tippy>
            ))}
          </div>
        )}
      </ul>
    </nav>
  );
}

interface LinkProps
  extends React.PropsWithChildren,
    React.ComponentPropsWithoutRef<"li"> {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

Pagination.Link = ({
  className,
  active,
  disabled,
  children,
  onClick,
}: LinkProps) => {
  return (
    <>
      <li className="flex-1 sm:flex-initial">
        <Button
          as="a"
          className={twMerge([
            "min-w-0 sm:min-w-[40px] font-normal flex items-center justify-center text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3",
            active &&
              "rounded-[0.5rem] bg-white font-medium dark:bg-darkmode-400",
            !active && "shadow-none border-transparent",
            className,
          ])}
          onClick={(e: any) => {
            e.preventDefault();
            onClick && !disabled && onClick();
          }}
        >
          {children}
        </Button>
      </li>
    </>
  );
};

export default Pagination;
