import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { permissionProps } from "../../interfaces";

const initialState: permissionProps = {} as any

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<permissionProps>) => {
      return { ...state, ...action.payload };
    },
    removePermissions: () => {
      return initialState;
    },
  },
});

export const { setPermissions, removePermissions } = permissionsSlice.actions;

export const selectPermissions = (state: RootState) => {
  return state.permissions;
};

export default permissionsSlice.reducer;
