import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export type viewTypes = "GRID" | "LIST";

const initialState: viewTypes = "GRID";

export const queryViewTypeSlice = createSlice({
  name: "queryViewType",
  initialState,
  reducers: {
    setQueryViewType: (state, action: PayloadAction<any>) => {
      const value = action.payload || state;
      return value;
    },
  },
});

export const { setQueryViewType } = queryViewTypeSlice.actions;

export const selectQueryViewType = (state: RootState) => {
  return state.queryViewType;
};

export default queryViewTypeSlice.reducer;
