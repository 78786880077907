import Lucide from "src/base-components/Lucide";
import { Menu } from "src/base-components/Headless";
import Button from "src/base-components/Button";
import { FormInput, FormSwitch, InputGroup } from "src/base-components/Form";
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { ChevronDownIcon, PlusIcon, XCircleIcon } from "lucide-react";
import Litepicker from "src/base-components/Litepicker";
import ReactSelect from "src/base-components/ReactSelect";
import FormInputParent from "src/base-components/FormInputParent/FormInputParent";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import ErrorMessage from "src/base-components/FormErrorValidation/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { schemaWithCustomerId, schemaWithCustomerDetail } from "./yup.schema";
import {
  authUserProps,
  createUpdateQueryProps,
  customerDataProps,
  customerProps,
  locationProps,
} from "src/interfaces";
import { createQuery } from "src/stores/query";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { fetchCustomers } from "src/stores/customer";
import moment from "moment";
import { countries, queryTypes, sources, tripTypes } from "../constants";
import LoadingIcon from "src/base-components/LoadingIcon";
import PhoneInput from "src/components/PhoneInput";
import Card from "src/components/Card";
import { isAdmin } from "src/helpers";
import { fetchLocations } from "src/stores/location";

const Component = ({
  onCreate,
  customers,
  fetchCustomers,
  fetchLocations,
  locations,
  authUser,
}: {
  onCreate: (payload: createUpdateQueryProps) => void;
  fetchCustomers: (payload: {
    keywords: string;
    type: number;
    callback?: Function;
  }) => void;
  customers: customerProps;
  locations: any;
  fetchLocations: (payload: { callback?: Function }) => void;
  authUser: authUserProps;
}) => {
  const customerData = customers?.data || [];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const [isCreateCustomer, setIsCreateCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);

  const yupResolverCallback = useCallback(() => {
    return yupResolver(
      isCreateCustomer ? schemaWithCustomerDetail : schemaWithCustomerId
    );
  }, [isCreateCustomer]);

  useEffect(() => {
    if (isAdmin()) {
      fetchLocations({
        callback: () => {
          setLoading(false);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (locations) {
      const options: any = locations?.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      }));
      if (options) {
        setLocationOptions(options);
      }
    }
  }, [locations]);

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
    control,
  } = useForm<createUpdateQueryProps>({
    defaultValues: {
      query_itinerary_detail: [
        { source: "", destination: "", date: new Date() },
      ],
      customer_countrycode: "CA",
      no_of_children: 0,
      no_of_infants: 0,
      is_emergency: 0,
    },
    mode: "onChange",
    resolver: yupResolverCallback(),
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!(await formValidation(event))) {
      return;
    }
    setLoading(true);
    let payload: any = getValues();
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });

    payload = {
      ...payload,
      location_id: payload?.location_id
        ? payload?.location_id
        : authUser?.location_id,
      no_of_adults: Number(payload?.no_of_adults || 0),
      no_of_children: Number(payload?.no_of_children || 0),
      no_of_infants: Number(payload?.no_of_infants || 0),
      query_type: Number(payload?.query_type),
      trip_type: Number(payload?.trip_type),
      is_emergency: Number(payload?.is_emergency || 0),
      query_itinerary_detail: JSON.stringify(payload?.query_itinerary_detail),
    };

    onCreate({
      ...payload,
      callback: (isError: boolean, id: string, no: string) => {
        if (!isError) {
          navigate("/query-detail/" + id);
        }
        setLoading(false);
      },
    } as any);
  };

  const handleFilterCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    if (value?.length >= 3) {
      setValue("customer_id", "");
      setLoadingCustomer(true);
      fetchCustomers({
        keywords: value,
        type: 1,
        callback: () => {
          setLoadingCustomer(false);
        },
      });
    }
  };

  const handleSelectCustomer = (data: customerDataProps | null) => {
    setValue("customer_id", data?.id ?? "");
    clearErrors("customer_id");
    setSearchTerm("");
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "query_itinerary_detail",
  });

  useEffect(() => {
    if (getValues()?.trip_type == 1 && fields.length == 1) {
      append({ source: "", destination: "", date: new Date() });
    } else if (getValues()?.trip_type == 2 && fields?.length > 1) {
      remove(fields?.length - 1);
    }
  }, [getValues()?.trip_type]);

  const formValidation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await trigger();
    if (!result) {
      return false;
    } else {
      return true;
    }
  };

  const handleDateChange = useCallback(
    (value: string, index: number) => {
      const selectedDate = moment(value, "DD-MM-YYYY");
      if (selectedDate.isValid()) {
        setValue(`query_itinerary_detail.${index}.date`, selectedDate.toDate());
        clearErrors(`query_itinerary_detail.${index}.date`);
      } else {
        console.error("Invalid date selected");
        return "";
      }
    },
    [setValue, clearErrors]
  );

  const handleGetDate = useCallback(
    (index: number) => {
      const date = getValues()?.query_itinerary_detail?.[index]?.date;
      if (date instanceof Date && !isNaN(date.getTime())) {
        return date.toLocaleDateString("en-GB").replace(/\//g, "-");
      } else {
        console.error("Invalid date");
        return "";
      }
    },
    [getValues()]
  );

  const handlePhoneChange = (payload: { phone: string; dialCode: string }) => {
    setValue("customer_phone", payload.phone);
    setValue("customer_countrycode", payload.dialCode);
  };

  const selectedCustomer = customerData?.find(
    (ele) => ele?.id == getValues()?.customer_id
  );

  return (
    <Card className="p-0 w-[85%] m-auto">
      <form className="" onSubmit={onSubmit}>
        <div className="p-7">
          <div>
            <FormInputParent
              label="Source"
              required={true}
              description="Website, Phone Call, or Walk-in"
              childrens={
                <div
                  className={
                    isAdmin()
                      ? "flex flex-col items-start w-full md:flex-row sm:flex-row md:justify-start gap-10"
                      : ""
                  }
                >
                  <ReactSelect
                    {...register("source")}
                    onChange={(_, value) => {
                      setValue("source", value as any);
                      clearErrors("source");
                    }}
                    name="source"
                    value={getValues()?.source}
                    errors={errors?.source ? errors?.source.message : ""}
                    required={true}
                    placeholder="Choose Source"
                    options={sources}
                    containerClassName={isAdmin() ? "w-2/3 sm:w-2/5" : "w-full"}
                  />
                  {isAdmin() && (
                    <div className="w-2/3 sm:w-2/5">
                      <ReactSelect
                        {...register("location")}
                        onChange={(_, value) => {
                          setValue("location_id", value as any);
                          clearErrors("location");
                        }}
                        name="Locations"
                        value={getValues()?.location_id}
                        errors={
                          errors?.location ? errors?.location.message : ""
                        }
                        required={true}
                        placeholder="Choose Location"
                        options={locationOptions}
                        className={clsx("w-full", {
                          "border-danger": errors?.location?.message,
                        })}
                      />
                    </div>
                  )}
                </div>
              }
            />
          </div>

          <FormInputParent
            label="Type"
            required={true}
            description="Tavel Package, Emergency Ticket"
            childrens={
              <div className="flex flex-col items-start w-full md:flex-row sm:flex-row md:justify-start">
                <div className="flex flex-row w-full items-center justify-items-center">
                  <ReactSelect
                    {...register("query_type")}
                    onChange={(_, value) => {
                      setValue("query_type", value as any);
                      clearErrors("query_type");
                    }}
                    errors={
                      errors?.query_type ? errors?.query_type.message : ""
                    }
                    value={getValues()?.query_type as any}
                    required={true}
                    placeholder="Select Type"
                    options={queryTypes}
                    name="query_type"
                    containerClassName="w-2/3 sm:w-2/5"
                  />
                  <div className="w-1/3 sm:ml-20 pl-1.5">
                    <FormSwitch className="">
                      <FormSwitch.Input
                        onChange={() => {
                          setValue(
                            "is_emergency",
                            getValues()?.is_emergency == 0 ? 1 : 0
                          );
                        }}
                        type="checkbox"
                      />
                      <FormSwitch.Label className=" text-xs">
                        Emergency Ticket
                      </FormSwitch.Label>
                    </FormSwitch>
                  </div>
                </div>
              </div>
            }
          />
          <FormInputParent
            label="Customer"
            required={true}
            errors={
              errors?.customer_id && !isCreateCustomer ? (
                <ErrorMessage message={errors?.customer_id?.message} />
              ) : null
            }
            description={
              <>
                Who is making the Query? <br />
                Search with name, phone, or email
              </>
            }
            childrens={
              <>
                {/* <ReactSelect
                  {...register("customer_id")}
                  onChange={(_, value) => {
                    setValue("customer_id", value as any);
                    clearErrors("customer_id");
                  }}
                  loadingOptions={loadingCustomer}
                  onFocus={() => setIsCreateCustomer(false)}
                  onInputChange={(value) => { handleFilterCustomer({ target: { value: value as any || "" } } as any) }}
                  errors={errors?.customer_id ? errors?.customer_id.message : ""}
                  value={getValues()?.customer_id as any}
                  required={true}
                  placeholder="Search or Create New"
                  name="customer_id"
                  className="w-full"
                  containerClassName="w-full"
                  options={customerData?.map(item => { return { label: `${item?.fullname || ""} | ${item?.country_code || ""}${item?.phone || ""} | ${item?.email || ""}`, value: item?.id } })}
                />
                {!isCreateCustomer && <div
                  className="text-cyan-800 pt-2 cursor-pointer"
                  onClick={() => {
                    setIsCreateCustomer(true);
                    handleSelectCustomer(null);
                    const csearchValue: any = document.getElementById(
                      "search-customer-input"
                    );
                    const value = csearchValue?.value
                    if (value?.includes("@")) {
                      setValue("customer_email", value || "");
                    } else if (parseInt(value)?.toString() != "NaN" && typeof parseInt(value) == "number") {
                      setValue("customer_phone", value?.toString());
                    } else {
                      setValue("customer_name", value || "");
                    }
                  }}
                >
                  + Create new customer
                </div>} */}
                <Menu className="">
                  <Menu.Button
                    // onClickCapture={() => customerSearchInputFocus()}
                    // onAuxClickCapture={() => customerSearchInputFocus()}
                    as={Button}
                    onClick={(e) => {
                      setIsCreateCustomer(false);
                      setValue("customer_countrycode", "");
                      setValue("customer_email", "");
                      setValue("customer_name", "");
                      setValue("customer_phone", "");
                    }}
                    className={clsx(
                      "w-full h-10 border-slate-300/100",
                      { "border-danger": errors?.customer_id },
                      "object-contain justify-start truncate"
                    )}
                  >
                    {getValues()?.customer_id ? (
                      <div
                        className={`flex flex-wrap sm:flex-row md:flex-row space-x-2 p-1 md:truncate`}
                      >
                        <div className="text-gray-500 sm:text-sm md:text-sm">
                          {selectedCustomer?.fullname}
                        </div>
                        <span className="mx-1">|</span>
                        <div className="text-gray-500 sm:text-sm md:text-sm">
                          {selectedCustomer?.phone}
                        </div>
                        <span className="mx-1">|</span>
                        <div className="text-gray-500 sm:text-sm md:text-sm">
                          {selectedCustomer?.email}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row w-full justify-between text-sm font-light">
                        <>Search or Create New</>
                        <ChevronDownIcon className="size-5" />
                      </div>
                    )}
                  </Menu.Button>
                  {!isCreateCustomer && (
                    <Menu.Items
                      className="flex flex-col flex-wrap w-full lg:w-full md:w-full sm:w-full  "
                      placement="bottom-custom"
                    >
                      <Menu.Header className="flex flex-col text-start font-light">
                        <FormInput
                          id="search-customer-input"
                          type="text"
                          autoComplete="off"
                          onChange={handleFilterCustomer}
                        />
                        <div className="flex-grow  flex-row items-center justify-start pt-2 space-x-2">
                          <span
                            className="text-cyan-800 cursor-pointer"
                            onClick={() => {
                              setIsCreateCustomer(true);
                              handleSelectCustomer(null);
                              const csearchValue: any = document.getElementById(
                                "search-customer-input"
                              );
                              const value = csearchValue?.value;
                              if (value?.includes("@")) {
                                setValue("customer_email", value || "");
                              } else if (
                                parseInt(value)?.toString() != "NaN" &&
                                typeof parseInt(value) == "number"
                              ) {
                                setValue("customer_phone", value?.toString());
                              } else {
                                setValue("customer_name", value || "");
                              }
                            }}
                          >
                            + Create new customer
                          </span>
                          <span className="text-gray-500 cursor-default">
                            or select from below
                          </span>
                        </div>
                      </Menu.Header>
                      <Menu.Divider />
                      {loadingCustomer ? (
                        <div className="flex w-[100%] justify-center p-2">
                          <LoadingIcon
                            color="#64748b"
                            icon="oval"
                            className="w-8 h-8 mx-auto"
                          />
                        </div>
                      ) : customerData?.length == 0 ? (
                        <div className="mx-auto py-2">No customers</div>
                      ) : (
                        customerData?.map((customer, customerKey) => (
                          <Menu.Item
                            className="md:w-full sm:w-full sm:text-sm md:text-sm"
                            onClick={() => {
                              handleSelectCustomer(customer);
                              setIsCreateCustomer(false);
                            }}
                            key={customerKey}
                          >
                            {({ active }) => (
                              <div
                                className={`flex flex-wrap sm:flex-row md:flex-row items-center space-x-2 p-1
                          md:truncate ${active && "bg-gray-100"}`}
                              >
                                <div className="font-bold sm:text-sm md:text-sm">
                                  {customer.fullname}
                                </div>
                                <span className="mx-1">|</span>
                                <div className="text-gray-500 sm:text-sm md:text-sm">
                                  {customer.country_code} {customer.phone}
                                </div>
                                <span className="mx-1">|</span>
                                <div className="text-gray-500 sm:text-sm md:text-sm">
                                  {customer.email}
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        ))
                      )}
                    </Menu.Items>
                  )}
                </Menu>
                {isCreateCustomer && (
                  <div className="flex flex-col w-full md:flex-col mt-5">
                    <div
                      className={clsx(
                        "w-full flex flex-col items-center md:flex-row",
                        {
                          "border-danger":
                            errors?.customer_phone ||
                            // errors?.customer_email ||
                            errors?.customer_phone,
                        }
                      )}
                    >
                      <FormInput
                        {...register("customer_name")}
                        name="customer_name"
                        autoFocus={isCreateCustomer}
                        placeholder="Name"
                        type="text"
                        className={clsx(
                          "first:rounded-b-none first:md:rounded-bl-md ",
                          "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
                          "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
                          "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
                          "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px",
                          {
                            "border-danger": errors?.customer_name,
                          }
                        )}
                      />
                      <FormInput
                        {...register("customer_email")}
                        name="customer_email"
                        placeholder="Email (Optional)"
                        type="email"
                        className={clsx(
                          "first:rounded-b-none first:md:rounded-bl-md ",
                          "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
                          "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
                          "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
                          "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-20",
                          {
                            "border-danger": errors?.customer_email,
                          }
                        )}
                      />
                      <PhoneInput
                        {...({} as any)}
                        {...register("customer_phone")}
                        {...register("customer_countrycode")}
                        value={`${getValues()?.customer_countrycode || ""}${
                          getValues()?.customer_phone || ""
                        }`}
                        className="rounded-r-md border-slate-300/100"
                        onChange={handlePhoneChange}
                      />
                    </div>
                    <div className="flex flex-col ">
                      {errors?.customer_name || errors?.customer_phone ? (
                        <ErrorMessage
                          message={"Please fill the customer details" as any}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </>
            }
          />
          <FormInputParent
            label="PAX"
            required={true}
            description="Number of passengers"
            childrens={
              <div className="flex flex-col w-full sm:mr-2 md:flex-row gap-5 sm:gap-5">
                <div className="flex flex-col">
                  <InputGroup>
                    <InputGroup.Text>Adult</InputGroup.Text>
                    <FormInput
                      {...register("no_of_adults")}
                      name="no_of_adults"
                      min="1"
                      type="number"
                      className={clsx({
                        "border-danger": errors?.no_of_adults,
                      })}
                    />
                  </InputGroup>
                  {errors?.no_of_adults && (
                    <ErrorMessage
                      message={errors?.no_of_adults?.message as any}
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <InputGroup>
                    <InputGroup.Text>Child</InputGroup.Text>
                    <FormInput
                      {...register("no_of_children")}
                      defaultValue="0"
                      name="no_of_children"
                      min="0"
                      type="number"
                      className={clsx({
                        "border-danger": errors?.no_of_children,
                      })}
                    />
                  </InputGroup>
                  {errors?.no_of_children && (
                    <ErrorMessage
                      message={errors?.no_of_children?.message as any}
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <InputGroup>
                    <InputGroup.Text>Infant</InputGroup.Text>
                    <FormInput
                      {...register("no_of_infants")}
                      defaultValue="0"
                      name="no_of_infants"
                      min="0"
                      type="number"
                      className={clsx({
                        "border-danger": errors?.no_of_infants,
                      })}
                    />
                  </InputGroup>
                  {errors?.no_of_infants && (
                    <ErrorMessage
                      message={errors?.no_of_infants?.message as any}
                    />
                  )}
                </div>
              </div>
            }
          />
          <FormInputParent
            label="Trip Type"
            required={true}
            description={
              <>
                Choose the flight or trip type: <br />
                Single or Return
              </>
            }
            childrens={
              <ReactSelect
                {...register("trip_type")}
                onChange={(_, value) => {
                  setValue("trip_type", value as any);
                  clearErrors("trip_type");
                }}
                errors={errors?.trip_type ? errors?.trip_type.message : ""}
                value={getValues()?.trip_type as any}
                required={true}
                placeholder="Choose Trip Type"
                options={tripTypes}
                name="trip_type"
                className="w-full"
                containerClassName="w-full"
              />
            }
          />
          <FormInputParent
            label="Itinerary Details"
            required={true}
            description="Source, destination & date of travel"
            childrens={
              <>
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    className={`flex flex-col ${
                      index === 0 ? "" : "mt-5"
                    } flex-nowrap sm:flex-row md:flex-row gap-5 md:gap-10`}
                  >
                    <div className="flex flex-col w-full items-start">
                      <FormInput
                        {...register(`query_itinerary_detail.${index}.source`)}
                        name="source"
                        placeholder="#Source"
                        onChange={(e) => {
                          setValue(
                            `query_itinerary_detail.${index}.source`,
                            e?.target?.value as any
                          );
                          clearErrors(`query_itinerary_detail.${index}.source`);
                        }}
                        type="text"
                        className={clsx("w-full", {
                          "border-danger":
                            errors?.query_itinerary_detail?.[index]?.source,
                        })}
                      />
                      {errors?.query_itinerary_detail?.[index]?.source && (
                        <ErrorMessage
                          message={
                            errors?.query_itinerary_detail?.[index]?.source
                              ?.message
                          }
                        />
                      )}
                    </div>
                    <div className="flex flex-col w-full items-start">
                      <FormInput
                        {...register(
                          `query_itinerary_detail.${index}.destination`
                        )}
                        placeholder="#Destination"
                        onChange={(e) => {
                          setValue(
                            `query_itinerary_detail.${index}.destination`,
                            e?.target?.value as any
                          );
                          clearErrors(
                            `query_itinerary_detail.${index}.destination`
                          );
                        }}
                        type="text"
                        name="destination"
                        className={clsx("w-full", {
                          "border-danger":
                            errors?.query_itinerary_detail?.[index]
                              ?.destination,
                        })}
                      />
                      {errors?.query_itinerary_detail?.[index]?.destination && (
                        <ErrorMessage
                          message={
                            errors?.query_itinerary_detail?.[index]?.destination
                              ?.message
                          }
                        />
                      )}
                    </div>
                    <div className="flex flex-col w-full items-start">
                      <div className="flex flex-row w-full align-middle justify-center items-center">
                        <Litepicker
                          {...register(`query_itinerary_detail.${index}.date`)}
                          value={handleGetDate(index)}
                          placeholder="Date"
                          name="date"
                          onChange={(e) =>
                            handleDateChange(e.target.value, index)
                          }
                          className={clsx("w-full mx-auto", {
                            disabled: true,
                            "border-danger":
                              errors?.query_itinerary_detail?.[index]?.date,
                          })}
                          options={{
                            autoApply: false,
                            format: "DD-MM-YYYY",
                            showWeekNumbers: true,

                            dropdowns: {
                              minYear: moment().subtract(5, "years").year(),
                              maxYear: moment().add(5, "years").year(),
                              months: true,
                              years: true,
                            },
                          }}
                        />
                        {((getValues()?.trip_type == 1 && index > 1) ||
                          (getValues()?.trip_type == 2 && index > 0)) &&
                          _.isEmpty(
                            errors?.query_itinerary_detail
                              ? errors?.query_itinerary_detail[index]?.message
                              : {}
                          ) && (
                            <XCircleIcon
                              color="white"
                              className="flex items-center ml-5 bg-red-900  w-8 h-7 md:h-6 border-none rounded-full cursor-pointer"
                              onClick={() => remove(index)}
                            />
                          )}
                      </div>
                      {errors?.query_itinerary_detail?.[index]?.date && (
                        <ErrorMessage
                          message={
                            errors?.query_itinerary_detail?.[index]?.date
                              ?.message
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex flex-row justify-end">
                  <span
                    className="flex flex-row items-center justify-items-end text-primary font-bold mt-4 cursor-pointer"
                    onClick={() =>
                      append({ source: "", destination: "", date: new Date() })
                    }
                  >
                    <PlusIcon className="mr-0.5" size={18} /> Add itinerary
                    Detail
                  </span>
                </div>
              </>
            }
          />

          <FormInputParent
            label="Remarks / Notes"
            required={false}
            description="Additional notes for records."
            errors={
              <ErrorMessage
                message={errors?.remark && (errors?.remark.message as any)}
              />
            }
            childrens={
              <FormInput
                id="regular-form-1"
                {...register("remark")}
                type="text"
                name="remark"
                className={clsx({
                  "border-danger": errors?.remark,
                })}
              />
            }
          />
        </div>
        <div className="flex p-7  border-t md:justify-end xl:justify-end border-slate-200/80">
          <Button
            className="flex w-full items-center justify-center px-10 md:w-auto text-white bg-primary"
            type="submit"
            loading={loading}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            {"Create Query"}
          </Button>
        </div>
      </form>
    </Card>
  );
};

const mapStateToProps = ({
  queries,
  customers,
  locations,
  authUser,
}: RootState) => ({
  queries,
  customers,
  locations,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (payload: any) => dispatch(createQuery(payload)),
  fetchCustomers: (payload: any) => dispatch(fetchCustomers(payload)),
  fetchLocations: (payload: any) => dispatch(fetchLocations(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
