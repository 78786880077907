import React, { useRef, useState } from "react";
import { Editor, IAllProps } from "@tinymce/tinymce-react";
import LoadingIcon from "../LoadingIcon";
import "./style.css";
const TinyMCE = (props: IAllProps) => {
  const editorRef = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const onLoad = () => {
    setLoading(false);
  };
  const height = props?.init?.height || 300;

  return (
    <div className="mt-1 relative">
      {loading && (
        <div
          className=" z-10 flex w-[100%] justify-center items-center absolute top-0 bottom-0 left-0 right-0"
          style={{ height: height }}
        >
          <LoadingIcon
            color="#64748b"
            icon="oval"
            className="w-8 h-8 mx-auto"
          />
        </div>
      )}
      <Editor
        {...props}
        onLoadContent={onLoad}
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey="gtncf6r1o3100fanv2tcmhohxsa1dcjde5prwm4hnpvcqj3j"
        init={{
          ...props?.init,
          height: height,
          menubar: props?.init?.menubar || false,
          plugins: [
            ...(props?.plugins || []),
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            props?.toolbar ||
            "undo redo | formatselect | " +
              "bold italic backcolor forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist removeformat",
          content_style: `body { position:relative; font-family:Helvetica,Arial,sans-serif; font-size:14px; height:100% } html { height:100%; position:relative } 
                    html,
                    body {
                      scroll-behavior: smooth !important;
              
                      font-family: "Public Sans", sans-serif;
                      font-optical-sizing: auto;
                    }
                    /* width */
                    *::-webkit-scrollbar {
                      width: 5px;
                    }
              
                    /* Track */
                    *::-webkit-scrollbar-track {
                      background: #f1f1f1;
                    }
              
                    /* Handle */
                    *::-webkit-scrollbar-thumb {
                      background: #888;
                    }
              
                    /* Handle on hover */
                    *::-webkit-scrollbar-thumb:hover {
                      background: #555;
                    }`,
        }}
      />
    </div>
  );
};
export default TinyMCE;
