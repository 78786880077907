import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import { FormLabel, FormTextarea } from "src/base-components/Form";
import React, { useEffect, useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import {
  queryDataProps,
  userDataProps,
  callBackProps,
  queryAssignToProps,
} from "src/interfaces";
import { RootState, useAppSelector } from "src/stores";
import { callBackQuery } from "src/stores/query";
import { fetchAllUsers } from "src/stores/user";
import ReactSelect from "src/base-components/ReactSelect";
import { selectAuthUser } from "src/stores/auth";

export type callBackToUsersType = 1 | 2 | 3 | 4 | 5;

const Component = ({
  query,
  onCallBack,
  visible,
  setVisible,
  model,
  setModel,
  users,
  addSelfUser = false,
  callBackToUsersType = 1,
  fetchUsers,
  currentSource,
}: {
  query: queryDataProps | undefined;
  users: userDataProps[];
  fetchUsers: (payload: { type: 1 | 2 | 3 }) => void;
  onCallBack: (payload: callBackProps) => void;
  visible: boolean;
  setVisible: Function;
  model?: queryAssignToProps;
  setModel: Function;
  addSelfUser?: boolean;
  callBackToUsersType?: callBackToUsersType;
  currentSource: any;
}) => {
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [sourcedBy, setSourcedBy] = useState<any>(null);

  const authUser = useAppSelector(selectAuthUser);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    let payload: callBackProps = {
      id: query_id || model?.id,
      remark: model?.remark,
      userId: sourcedBy,
      currentSource: currentSource,
    };
    if ((model as any)?.status) {
      payload = { ...payload, status: (model as any)?.status } as any;
    }
    setSubmitting(true);
    onCallBack({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  useEffect(() => {
    if (visible) {
      fetchUsers({
        type: callBackToUsersType,
        callback: (userList) => {
          if (
            !!model?.userid &&
            !addSelfUser &&
            !userList?.some((ele) => ele?.id == model?.userid)
          ) {
            setModel({
              ...model,
              userid: "",
            });
          }
        },
      } as any);
    }
  }, [visible]);

  const selfUser = addSelfUser
    ? [
        {
          name: authUser?.name,
          last_name: authUser?.last_name,
          id: authUser?.id,
        },
      ]
    : [];
  const userOptions = [...selfUser, ...users]?.map((item) => {
    return {
      label: `${item?.name || ""} ${item?.last_name || ""}`,
      value: item?.id,
    };
  });

  return (
    <SlideoverModal
      size="md"
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle="Customer needs a callback"
    >
      <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
          Query: <span className="text-primary"># {query?.query_no}</span>
        </div>
        {
          <div className="flex-1 w-full mt-3 mb-10 xl:mt-0">
            <FormLabel>Assign to</FormLabel>
            <ReactSelect
              className="flex-1 w-full mt-3 xl:mt-0"
              name="userid"
              placeholder="--Select--"
              value={sourcedBy}
              onChange={(_, value) => {
                setSourcedBy(value);
              }}
              options={userOptions}
            />
            <FormLabel className="mt-5">Reason</FormLabel>
            <FormTextarea
              rows={5}
              value={model?.remark || ""}
              name="remark"
              required
              onChange={handleSelectChange as any}
            />
          </div>
        }
        <Button
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
  users: users?.data || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (payload: any) => dispatch(fetchAllUsers(payload)),
  onCallBack: (payload: any) => dispatch(callBackQuery(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
