import moment from "moment";
import { Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import {
  queryAPIUrls
} from "src/common/apiActions";
import { TableColumnType } from "src/components/DataTable";
import { dateParser } from "src/helpers";
import { roleDataProps } from "src/interfaces";

export const columns = ({
  onEditClick,
}: {
  onEditClick?: Function | undefined;
}) => {
  return [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Type",
      field: "user_type",
    },
    {
      title: "Created at",
      field: "created_at",
      cell: (row) => dateParser(row?.created_at, "DD-MM-YYYY"),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Action",
      field: "action",
      hide: !onEditClick,
      cell: (row: roleDataProps) => {
        if (!onEditClick) return null;
        return (
          <Menu>
            <Menu.Button
              onClick={() => {
                onEditClick && onEditClick(row);
              }}
              className="w-100 h-5 text-slate-500"
            >
              <Lucide icon="Pencil" className="w-4 h-4 mr-2 text-primary" />
            </Menu.Button>
          </Menu>
        );
      },
    },
  ] as TableColumnType<any>[];
};

interface additionalPermissionProps {
  [key: string]: string[];
}

export const additionalPermissionAdd: additionalPermissionProps = {
  [queryAPIUrls?.create]: [queryAPIUrls?.get],
};

export const additionalPermissionRemove: additionalPermissionProps = {
  [queryAPIUrls?.get]: [queryAPIUrls?.create],
};


export const userTypes = [
  { label: "Admin", value: 'Admin' },
  { label: "Manager", value: 'Manager' },
  { label: "Agent", value: 'Non-Customer' },
]