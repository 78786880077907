import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from "./types";
import {
  createEmailTemplateService,
  fetchEmailTemplatesService,
  updateEmailTemplateService,
  deleteEmailTemplateService,
} from "../../services";
import { setEmailTemplates } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadEmailTemplates(
  { payload }: { payload: any } = { payload: undefined }
): Generator<any> {
  try {
    const responseData: any = yield call(fetchEmailTemplatesService, payload);
    if (Array.isArray(responseData)) {
      yield put(setEmailTemplates({ data: responseData } as any));
    } else {
      yield put(setEmailTemplates(responseData));
    }
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Email Templates!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleCreateEmailTemplate({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(createEmailTemplateService, payload);
    // yield handleLoadEmailTemplates();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Email Template",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdateEmailTemplate({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(updateEmailTemplateService, payload);
    // yield handleLoadEmailTemplates();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Email Template!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleDeleteEmailTemplate({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(deleteEmailTemplateService, payload);
    // yield handleLoadEmailTemplates();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to delete the Email Template!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* emailTemplateSaga() {
  yield takeLatest<any>(FETCH_EMAIL_TEMPLATE, handleLoadEmailTemplates);
  yield takeLatest<any>(CREATE_EMAIL_TEMPLATE, handleCreateEmailTemplate);
  yield takeLatest<any>(UPDATE_EMAIL_TEMPLATE, handleUpdateEmailTemplate);
  yield takeLatest<any>(DELETE_EMAIL_TEMPLATE, handleDeleteEmailTemplate);
}
