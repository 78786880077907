import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface CompactMenuState {
  value: boolean;
}

const initialState: CompactMenuState = {
  value: true,
};

export const compactMenuSlice = createSlice({
  name: "compactMenu",
  initialState,
  reducers: {
    setCompactMenu: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setCompactMenu } = compactMenuSlice.actions;

export const selectCompactMenu = (state: RootState) => {
  return state.compactMenu.value;
};

export default compactMenuSlice.reducer;
