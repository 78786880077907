import {
  passengerAPIUrls
} from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchPassengersService = async (
  { type = 0 }: { type: 0 | 1 } = { type: 0 }
) => {
  try {
    const data = await http.Get(`${passengerAPIUrls?.get}?type=${type}`);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createPassengerService = async (payload: any) => {
  try {
    const data = await http.Post(passengerAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updatePassengerService = async (payload: any) => {
  try {
    const data = await http.Post(passengerAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
