import Snackbar from "src/base-components/Snackbar";
import Router from "./router";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "./stores";
import { selectAuthUser } from "./stores/auth";
import { appInitializer } from "./helpers";
import { usePushNotification } from "./firebase";

const AppContent = () => {
  const pushNotification = usePushNotification();
  const authUser = useAppSelector(selectAuthUser);
  useEffect(() => {
    pushNotification;
    appInitializer(authUser);
  }, []);
  const RouterCallback = useCallback(
    () => <Router />,
    [authUser?.role?.permissions?.length]
  );

  // if('caches' in window){
  //   caches.keys().then((names) => {
  //  // Delete all the cache files
  //  names.forEach(name => {
  //      caches.delete(name);
  //  })

  // useEffect(() => {
  //   if ("caches" in window) {
  //     caches.keys()?.then((names: any) => {
  //       console.info("Cache", names);
  //     });
  //   }
  // }, []);

  return (
    <>
      <Snackbar />
      <RouterCallback />
    </>
  );
};
export default AppContent;
