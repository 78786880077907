import { fetch as http } from "./http";
import {
  paymentAPIUrls
} from "../common/apiActions";

export const fetchPaymentService = async (id: string = "") => {
  try {
    const data = await http.Get(`${paymentAPIUrls?.getById}?id=${id}`);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createPaymentService = async (payload: any) => {
  try {
    const data = await http.Post(paymentAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const acceptPaymentService = async (payload: any) => {
  try {
    const data = await http.Post(paymentAPIUrls?.accept, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const initiateRefundService = async (payload: any) => {
  try {
    const data = await http.Post(paymentAPIUrls?.initiateRefund, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const completeRefundService = async (payload: any) => {
  try {
    const data = await http.Post(paymentAPIUrls?.completeRefund, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const fetchSignTemplatesService = async (payload: any) => {
  try {
    const data = await http.Get(paymentAPIUrls?.getSignTemplates);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
