import Lucide, { icons } from "src/base-components/Lucide";
import { TableColumnType } from "../../../components/DataTable/interfaces";
import { queryDataProps } from "src/interfaces";
import moment from "moment";
import EmergencyIcon from "../../Queries/EmergencyIcon";
import { isQueryClosedAndCancelled } from "src/pages/Queries/helpers";
import { dateParser } from "src/helpers";
import RecieptIcon from "../../../assets/images/icon/receipt.svg";

export const tableFields = (onQueryClick: Function): TableColumnType<any>[] => [
  {
    title: "Query #",
    field: "id",
    cell: (row: queryDataProps) => {
      return (
        <div
          className="flex items-center text-[#075985] cursor-pointer"
          onClick={() => onQueryClick(row)}
        >
          {row?.is_emergency == 1 && <EmergencyIcon className="" />}{" "}
          {row?.query_no}
        </div>
      );
    },
  },
  {
    title: "No. of PAX",
    field: "pax",
    cell: (row: queryDataProps) => {
      return row?.no_of_adults + row?.no_of_children + row?.no_of_infants;
    },
  },
  {
    title: "Created By",
    field: "createdBy",
    cell: (row: queryDataProps) => {
      return `${row?.sourced_by_user?.name} ${row?.sourced_by_user?.last_name ?? ""
        }`;
    },
  },
  {
    title: "Assigned To",
    field: "assignedTo",
    cell: (row: queryDataProps) => {
      if (!row?.assigned_to_user?.name && !row?.assigned_to_user?.last_name) {
        return "N/A";
      }
      return (
        (row?.assigned_to_user?.name || "") +
        " " +
        (row?.assigned_to_user?.last_name
          ? row?.assigned_to_user?.last_name
          : "")
      );
    },
  },
  {
    title: "Time",
    field: "time",
    cell: (row: queryDataProps) => {
      return dateParser(row?.created_at, "DD-MM-YYYY");
    },
  },
  {
    title: "Status",
    field: "status",
    cell: (row: queryDataProps) => {
      return (
        <>
          {isQueryClosedAndCancelled(row.status) ? (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#F5CECC] rounded-md text-[#CE3B3B]">
              {row?.status_text}
            </span>
          ) : (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
              {row?.status_text}
            </span>
          )}
        </>
      );
    },
  },
];

export type menuIdProps = "info" | "queries" | "documents";
interface menuProps {
  title: string;
  icon: keyof typeof icons | any;
  id: menuIdProps;
}
export const menus: menuProps[] = [
  {
    id: "info",
    title: "Profile",
    icon: "User",
  },
  {
    id: "queries",
    title: "Queries",
    icon: "Activity",
  },
  {
    id: "documents",
    title: "Documents",
    icon: "Book",
  },
];
