import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { authUserProps } from "../../interfaces";

const initialState: authUserProps = {
  token: "",
  name: "",
  email: "",
} as any;

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<authUserProps>) => {
      return { ...state, ...action.payload };
    },
    setAmadeusToken: (state, action: PayloadAction<any>) => {
      return { ...state, amadeus: action.payload };
    },
    setUserPermission: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action?.payload,
      };
    },
  },
});

export const { setAuthUser, setAmadeusToken, setUserPermission } =
  authUserSlice.actions;

export const selectAuthUser = (state: RootState) => {
  return state.authUser;
};

export default authUserSlice.reducer;
