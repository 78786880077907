import {
  emailTemplateAPIUrls
} from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchEmailTemplatesService = async (
  { type = 0 }: { type: 0 | 1 } = { type: 0 }
) => {
  try {
    const data = await http.Get(`${emailTemplateAPIUrls?.get}?type=${type}`);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createEmailTemplateService = async (payload: any) => {
  try {
    const data = await http.Post(emailTemplateAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateEmailTemplateService = async (payload: any) => {
  try {
    const data = await http.Post(emailTemplateAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const deleteEmailTemplateService = async (payload: any) => {
  try {
    const data = await http.Delete(emailTemplateAPIUrls?.delete, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
