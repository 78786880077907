import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { cityAirportDictionaryProps } from "../../interfaces";

const initialState: cityAirportDictionaryProps = {} as any;

export const cityAirportsSlice = createSlice({
  name: "cityAirports",
  initialState,
  reducers: {
    setCityAirports: (state, action: PayloadAction<cityAirportDictionaryProps>) => {
      return { ...state, ...action.payload };
    },
    setAmadeusToken: (state, action: PayloadAction<any>) => {
      return { ...state, amadeus: action.payload };
    },
    removeCityAirports: () => {
      return initialState;
    },
  },
});

export const { setCityAirports, setAmadeusToken, removeCityAirports } = cityAirportsSlice.actions;

export const selectCityAirports = (state: RootState) => {
  return state.cityAirports;
};

export default cityAirportsSlice.reducer;
