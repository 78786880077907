import Table from "../../base-components/Table";
import LoadingIcon from "../../base-components/LoadingIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dataTableProps, datatableResponseProps } from "./interfaces";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import Pagination from "src/base-components/Pagination";
import { FormInput, FormSwitch, InputGroup } from "src/base-components/Form";
import { store, useAppDispatch } from "src/stores";
import { apiBaseUrl } from "src/services/http";
import { setAlert } from "src/stores/basicSlices";
export * from "./interfaces";
import { uuidv4 } from "src/helpers";
import Card from "../Card";
import ReactSelect from "src/base-components/ReactSelect";
import {
  ArrowDownAZ,
  ArrowUpZA,
  Grid3X3,
  ListOrdered,
  RefreshCcwDot,
} from "lucide-react";
import DatePicker from "src/base-components/DatePicker";
import { DateValueType } from "react-tailwindcss-datepicker";
import CustomTippy from "src/base-components/customTooltip";
import moment from "moment";
import _ from "lodash";

const DataTable = (props: dataTableProps) => {
  const {
    data = [],
    gridViewRenderItem,
    viewType = "LIST",
    onViewChange,
    fetchData,
    reload = false,
    onDataLoaded,
    baseUrl = apiBaseUrl,
    url = "",
    token = store?.getState()?.authUser?.token || "",
    columns = [],
    tableHeading,
    addBtnContent,
    onAddClick,
    onRowClick,
    initialSearch = "",
    onSearchChange,
    rowsPerPage = 10,
    loading,
    showHeader = true,
    showFooter = true,
    saveState = true,
    searchable = true,
    statusOptions = [],
    sourceOptions = [],
    sortOptions = [],
    agentOptions = [],
    sourceFilterKey = "source",
    statusFilterKey = "status",
    directionFilterKey = "sortDirection",
    agentsFilterKey = "agentId",
    sortFilterKey = "sortBy",
    showDateFilter,
    toggleLabel,
    toggleFilterKey,
    showClearFilter,
    showSortOrder,
    passengersName = [],
    setPassengersName,
    showColors,
    priceObject,
  } = props;
  const dispatch = useAppDispatch();
  const [fetchUrl, setFetchUrl] = useState(url);

  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(rowsPerPage);
  const [toggle, setToggle] = useState<number | "">("");

  const [status, setStatus] = useState(
    statusOptions?.find((ele) => ele?.default)?.val
  );
  const [sourceStatus, setSourceStatus] = useState(
    sourceOptions?.find((ele) => ele?.default)?.val
  );
  const [selectedAgent, setSelectedAgent] = useState(
    agentOptions?.find((ele) => ele?.default)?.val
  );
  const [sortedBy, setSortedBy] = useState(
    sortOptions?.find((ele) => ele?.default)?.val
  );
  const [sortDirection, setSortDirection] = useState("DESC");

  const [filterDate, setFilterDate] = useState<DateValueType>({
    startDate: "",
    endDate: "",
  });

  const [dataLoading, setDataLoading] = useState(false);
  const [responseData, setResponseData] = useState<datatableResponseProps>(
    {} as any
  );
  useEffect(() => {
    fetchData && !url && fetchData();
  }, []);
  useEffect(() => {
    if (reload) {
      if (url) {
        let previousState = saveState ? fetchUrl : "";
        setFetchUrl("");
        setTimeout(() => {
          setDataLoading(true);
          setFetchUrl(previousState || url);
        }, 10);
      } else {
        fetchData && fetchData();
      }
    }
  }, [reload]);
  useEffect(() => {
    if (initialSearch) {
      setSearch(initialSearch);
      handleParams(url, "search", initialSearch, false).then((newUrl) => {
        handleParams(newUrl, statusFilterKey, status, false).then(
          (statusUrl) => {
            handleParams(statusUrl, sourceFilterKey, sourceStatus, true);
          }
        );
      });
    }
  }, [url, initialSearch, window?.location?.pathname]);
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * perPage;
  const indexOfFirstRow = indexOfLastRow - perPage;
  const renderData = url ? responseData?.data || [] : data;
  const totalItems = responseData?.total || data?.length;
  const currentRows = url
    ? renderData
    : renderData?.slice(indexOfFirstRow, totalItems);

  const modifyUrlParameter = (url, paramName, paramValue) => {
    var urlParts = url.split("?");
    if (urlParts.length >= 2) {
      var prefix = encodeURIComponent(paramName) + "=";
      var queryParams = urlParts[1].split("&");

      var found = false;
      for (var i = 0; i < queryParams.length; i++) {
        if (queryParams[i].indexOf(prefix) === 0) {
          queryParams[i] = prefix + encodeURIComponent(paramValue);
          found = true;
          break;
        }
      }
      if (!found) {
        queryParams.push(prefix + encodeURIComponent(paramValue));
      }

      urlParts[1] = queryParams.join("&");
      return urlParts.join("?");
    } else {
      return (
        url +
        "?" +
        encodeURIComponent(paramName) +
        "=" +
        encodeURIComponent(paramValue)
      );
    }
  };

  const handleParams = async (
    currentUrl,
    name,
    value,
    isSetState = true,
    resetPage = false
  ) => {
    let newUrl: string = currentUrl;
    let paramStartIndex = newUrl?.indexOf("?") || 0;
    let paramsPart = newUrl?.substring(paramStartIndex);
    if (paramsPart?.includes(name)) {
      const startIndexOfParam = paramsPart?.indexOf(name) + paramStartIndex;
      const endIndexOfParam = newUrl
        ?.substring(startIndexOfParam)
        ?.indexOf("&");
      const removeParam = newUrl?.substring(
        startIndexOfParam - 1,
        endIndexOfParam > 0
          ? startIndexOfParam + endIndexOfParam
          : newUrl?.length
      );
      newUrl = newUrl?.replace(removeParam, "");
      if (!newUrl?.includes("?")) {
        newUrl = newUrl?.replace("&", "?");
      }
    }
    let isFirstParam = !newUrl?.includes("?");
    if (value) {
      newUrl = newUrl + `${isFirstParam ? "?" : "&"}${name}=${value}`;
    }

    if (resetPage) {
      newUrl = modifyUrlParameter(newUrl, "page", 1);
    }

    if (isSetState) {
      setFetchUrl(newUrl);
    }
    return newUrl;
  };
  const onPageChange = (value: number) => {
    setCurrentPage(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, "page", value);
    }
  };
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event?.target?.value;
    setSearch(value);
    if (onSearchChange && !url) {
      onSearchChange(event);
    } else if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, "search", value, true, true);
    }
  };
  const onStatusChange = (value) => {
    setStatus(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, statusFilterKey, value, true, true);
    }
  };
  const onSourceStatusChange = (value) => {
    setSourceStatus(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, sourceFilterKey, value, true, true);
    }
  };
  const onAgentChange = (value) => {
    setSelectedAgent(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, agentsFilterKey, value, true, true);
    }
  };

  const onSortByChange = (value: string) => {
    setSortedBy(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, sortFilterKey, value, true, true);
    }
  };

  const onToggleChange = (value) => {
    setToggle(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, toggleFilterKey, value, true, true);
    }
  };
  const onDateChange = (value: DateValueType) => {
    setFilterDate(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      const fromDate = value?.startDate || "";
      const toDate = value?.endDate || "";
      handleParams(currentUrl, "fromDate", fromDate, false).then((newUrl) => {
        handleParams(newUrl, "toDate", toDate, true);
      });
    }
  };
  const onClearFilter = () => {
    setSearch("");
    setStatus(statusOptions?.find((ele) => ele?.default)?.val || "");
    setSourceStatus(sourceOptions?.find((ele) => ele?.default)?.val || "");
    setSelectedAgent("");
    setFilterDate({ startDate: "", endDate: "" });
    setToggle("");
    setSortedBy("");
    setSortDirection("DESC");
    setTimeout(() => {
      setFetchUrl(url);
    }, 10);
  };

  useEffect(() => {
    if (location?.pathname && location.search === "?all") {
      setStatus("0");
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (fetchUrl) {
      setDataLoading(true);
      fetch(baseUrl + fetchUrl, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        ?.then(async (res) => {
          if (res.status == 200) {
            const apiResponse: datatableResponseProps = await res.json();
            if (Array.isArray(apiResponse)) {
              setResponseData({
                data: apiResponse,
                current_page: 1,
                total: apiResponse?.length,
              } as any);
            } else {
              setPerPage(apiResponse?.per_page);
              setResponseData(apiResponse);
              setCurrentPage(apiResponse?.current_page);
            }
          } else if (res?.status == 403) {
            dispatch(
              setAlert({
                id: uuidv4(),
                title: `Failed to load ${tableHeading}`,
                text: "You don't have permission, contact administrator",
                type: "danger",
              })
            );
          }
        })
        .finally(() => {
          setTimeout(() => {
            setDataLoading(false);
            onDataLoaded && onDataLoaded();
          }, 1000);
        });
    }
  }, [fetchUrl]);
  const currentDate: any = moment(new Date());
  const isSingle = !searchable && !showDateFilter && !toggleFilterKey;
  const isFilterApplied =
    (status && !statusOptions?.find((ele) => ele?.val == status)?.default) ||
    (sortedBy && !sortOptions?.find((ele) => ele?.val == sortedBy)?.default) ||
    (sourceStatus &&
      !sourceOptions?.find((ele) => ele?.val == sourceStatus)?.default) ||
    (selectedAgent &&
      !agentOptions?.find((ele) => ele?.val == selectedAgent)?.default) ||
    filterDate?.startDate ||
    filterDate?.endDate ||
    toggle ||
    search;
  const DatePickerCallback = useCallback(
    () => (
      <DatePicker
        value={
          filterDate?.startDate || filterDate?.endDate
            ? filterDate
            : (undefined as any)
        }
        onChange={(value) => {
          onDateChange(value);
        }}
        placeholder="Select date range"
        maxDate={currentDate}
        separator="-"
        inputId="date-picker"
        showFooter={true}
      />
    ),
    [filterDate, fetchUrl, url]
  );

  const toggleSortDirection = () => {
    const value = sortDirection === "ASC" ? "DESC" : "ASC";
    setSortDirection(value);
    if (url) {
      const currentUrl = fetchUrl || url;
      handleParams(currentUrl, directionFilterKey, value);
    }
  };

  useEffect(() => {
    const passengersObject = data?.find((item) => item.passengers);
    if (passengersObject && passengersObject.passengers) {
      try {
        const parsedPassengers = passengersObject.passengers;
        setPassengersName(parsedPassengers);
      } catch (error) {
        console.error("Error parsing passengers JSON:", error);
      }
    }
  }, []);

  return (
    <>
      {showHeader && (
        <>
          {(onAddClick || tableHeading) && (
            <div className="flex flex-col md:h-10 gap-y-3 md:items-center md:flex-row justify-between mb-3">
              <div className="text-base font-medium">{tableHeading}</div>
              <div className="flex gap-5">
                {onAddClick && (
                  <Button
                    variant="primary"
                    onClick={onAddClick}
                    className="group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                  >
                    <Lucide icon="Plus" className="stroke-[1.3] w-4 h-4 mr-2" />
                    {addBtnContent}
                  </Button>
                )}
                <div className=" flex items-center gap-3">
                  {toggleFilterKey && (
                    <div className="ml-auto">
                      <FormSwitch className="ml-3 right-0">
                        <FormSwitch.Input
                          checked={toggle == 1}
                          onChange={() => {
                            onToggleChange(toggle == 1 ? 0 : 1);
                          }}
                          type="checkbox"
                          id="toggle-btn"
                        />
                        <FormSwitch.Label
                          htmlFor="toggle-btn"
                          className=" text-sm"
                        >
                          {toggleLabel || (toggle == 1 ? "On" : "Off")}
                        </FormSwitch.Label>
                      </FormSwitch>
                    </div>
                  )}
                </div>
                {onViewChange && (
                  <div className="flex flex-row items-center sm:flex-row gap-x-3 gap-y-2 sm:ml-auto ml-5">
                    <CustomTippy
                      mode="div"
                      content={viewType === "GRID" ? "List View" : "Grid View"}
                      theme="light"
                      children={
                        <>
                          {viewType === "GRID" ? (
                            <Grid3X3
                              onClick={() => onViewChange("LIST")}
                              className={"text-primary cursor-pointer"}
                              size={30}
                            />
                          ) : (
                            <ListOrdered
                              onClick={() => onViewChange("GRID")}
                              className={"text-primary cursor-pointer"}
                              size={30}
                            />
                          )}
                        </>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <Card className="flex flex-row justify-between justify-items-center sm:items-center sm:flex-row gap-y-2 mb-8 p-5">
            <div className="flex flex-row">
              {searchable && (
                <div className="relative">
                  <Lucide
                    icon="Search"
                    className="absolute inset-y-0 left-0 z-10 w-4 h-4 my-auto ml-3 stroke-[1.3] text-slate-500"
                  />
                  <FormInput
                    type="text"
                    value={search}
                    placeholder="Search..."
                    className="pl-9 sm:w-64 rounded-[0.5rem]"
                    onChange={onSearch}
                  />
                </div>
              )}
              {showDateFilter && (
                <div className="relative ml-3">
                  <DatePickerCallback />
                </div>
              )}
              {statusOptions?.length > 0 && (
                <ReactSelect
                  options={statusOptions?.map((item) => {
                    return { ...item, value: item?.val };
                  })}
                  placeholder={statusFilterKey || "Status"}
                  value={status}
                  containerClassName={
                    isSingle ? "min-w-[7rem] ml-auto" : "min-w-[7rem] ml-3"
                  }
                  onChange={(_, value) => {
                    onStatusChange(value as any);
                  }}
                />
              )}
              {sourceOptions?.length > 0 && (
                <ReactSelect
                  options={sourceOptions?.map((item) => {
                    return { ...item, value: item?.val };
                  })}
                  placeholder={sourceFilterKey || "All Sources"}
                  value={sourceStatus}
                  containerClassName={
                    isSingle ? "min-w-[7rem] ml-auto" : "min-w-[7rem] ml-3"
                  }
                  onChange={(_, value) => {
                    onSourceStatusChange(value as any);
                  }}
                  className="min-w-36"
                />
              )}
              {agentOptions?.length > 0 && (
                <ReactSelect
                  options={agentOptions?.map((item) => {
                    return { ...item, value: item?.val };
                  })}
                  placeholder={"All Agents"}
                  value={selectedAgent}
                  containerClassName={
                    isSingle ? "min-w-[7rem] ml-auto" : "min-w-[7rem] ml-3"
                  }
                  onChange={(_, value) => {
                    onAgentChange(value as any);
                  }}
                  className="min-w-36"
                />
              )}

              {sortOptions?.length > 0 && (
                <>
                  <ReactSelect
                    options={sortOptions?.map((item) => {
                      return { ...item, value: item?.val };
                    })}
                    placeholder={sortFilterKey || "All Sources"}
                    value={sortedBy}
                    containerClassName={
                      isSingle ? "min-w-[7rem] ml-auto" : "min-w-[7rem] ml-3"
                    }
                    onChange={(_, value) => {
                      onSortByChange(value as any);
                    }}
                    className="min-w-36"
                  />
                </>
              )}

              {showSortOrder && (
                <CustomTippy
                  mode="div"
                  content={
                    sortDirection === "DESC"
                      ? "View Ascending"
                      : "View Descending"
                  }
                  theme="light"
                  className="shadow-none"
                  children={
                    <>
                      {sortDirection === "DESC" ? (
                        <ArrowUpZA
                          className="text-primary cursor-pointer w-6 h-6 ml-3"
                          onClick={toggleSortDirection}
                        />
                      ) : (
                        <ArrowDownAZ
                          className="text-primary cursor-pointer w-6 h-6 ml-3"
                          onClick={toggleSortDirection}
                        />
                      )}
                    </>
                  }
                />
              )}
            </div>

            <div className="flex flex-row justify-center items-center">
              {showClearFilter && isFilterApplied && (
                <Button
                  onClick={onClearFilter}
                  size="sm"
                  className="ml-2 border-slate-400"
                >
                  <Lucide
                    icon="FilterX"
                    className=" text-slate-600 mr-2 w-4 h-4"
                  />{" "}
                  Clear
                </Button>
              )}
            </div>
          </Card>
        </>
      )}

      {viewType == "GRID" ? (
        loading || dataLoading ? (
          <div className="flex w-[100%] justify-center py-8">
            <LoadingIcon color="#64748b" icon="oval" className="w-8 h-8" />
          </div>
        ) : currentRows?.length === 0 ? (
          <div className="w-full py-10 text-center">
            <div className="font-medium text-slate-500">
              No matching records found.
            </div>
          </div>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 justify-center">
            {currentRows?.map((item, index) => {
              return gridViewRenderItem && gridViewRenderItem(item, index);
            })}
          </div>
        )
      ) : (
        <Card className={`col-span-12 relative`}>
          <div className="overflow-auto lg:overflow-visible">
            {loading || dataLoading ? (
              <div className="flex w-[100%] justify-center py-8">
                <LoadingIcon
                  color="#64748b"
                  icon="oval"
                  className="w-8 h-8 mx-auto"
                />
              </div>
            ) : (
              <div className="p-3">
                {passengersName?.length > 0 && (
                  <>
                    <p className="ml-5 py-1 font-medium bg-inherit text-slate-500 text-start">
                      Passengers:
                    </p>
                    <div className="flex ml-5 gap-5 flex-wrap">
                      {passengersName?.map((item: any, index: number) => {
                        if (item) {
                          return (
                            <p
                              className="border border-gray-300 bg-gray-500/10 rounded-md p-1 text-primary text-xs"
                              key={index}
                            >
                              {item}
                            </p>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
                {!_.isEmpty(priceObject) && (
                  <div className="px-5 py-3 flex flex-col gap-3">
                    <div>
                      <p className="py-1 font-medium bg-inherit text-slate-500 text-start">
                        Actual Price ($):
                      </p>
                      <div className="flex justify-between">
                        {priceObject?.actualPrice &&
                          priceObject?.actualPrice?.map(
                            (item: any, index: number) => {
                              if (item?.price != 0) {
                                return (
                                  <div
                                    key={index}
                                    className="w-full md:w-1/2 lg:w-1/4"
                                  >
                                    <InputGroup className="w-full">
                                      <InputGroup.Text>
                                        {item?.title}
                                      </InputGroup.Text>
                                      <FormInput
                                        disabled
                                        name="actualPrice"
                                        type="number"
                                        value={item?.price}
                                      />
                                    </InputGroup>
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>

                    <div>
                      <p className="py-1 font-medium bg-inherit text-slate-500 text-start">
                        Quoted Price ($):
                      </p>
                      <div className="flex justify-between">
                        {priceObject?.quotedPrice &&
                          priceObject?.quotedPrice?.map(
                            (item: any, index: number) => {
                              if (item?.price != 0) {
                                return (
                                  <div
                                    key={index}
                                    className="w-full md:w-1/2 lg:w-1/4"
                                  >
                                    <InputGroup className="w-full">
                                      <InputGroup.Text>
                                        {item?.title}
                                      </InputGroup.Text>
                                      <FormInput
                                        disabled
                                        name="quotedPrice"
                                        type="number"
                                        value={item?.price}
                                      />
                                    </InputGroup>
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>
                  </div>
                )}
                <Table className="w-full relative bg-white">
                  <Table.Thead>
                    <Table.Tr>
                      {columns?.map((column, index) =>
                        (column?.hide && typeof column?.hide != "function") ||
                        !column?.title ? null : (
                          <Table.Th
                            key={index}
                            className="py-4 font-medium bg-inherit text-slate-500 text-start"
                          >
                            {column?.title}
                          </Table.Th>
                        )
                      )}
                    </Table.Tr>
                  </Table.Thead>
                  {currentRows?.length === 0 ? (
                    <div className="w-full py-10 text-center bg-inherit">
                      <div className="absolute left-0 right-0 font-medium text-slate-500">
                        No matching records found.
                      </div>
                    </div>
                  ) : (
                    <Table.Tbody className=" bg-inherit">
                      {currentRows?.map((item: any, rowIndex) => (
                        <Table.Tr
                          onClick={() => onRowClick && onRowClick(item)}
                          className={
                            onRowClick ? `cursor-pointer relative` : "relative "
                          }
                          key={rowIndex}
                        >
                          {columns?.map((column, columnIndex) => {
                            const hide =
                              typeof column?.hide == "function"
                                ? column?.hide(item)
                                : column?.hide;
                            return hide ? null : (
                              <Table.Td
                                key={columnIndex}
                                className="py-4 border-dashed dark:bg-darkmode-600"
                              >
                                {column.cell
                                  ? column.cell(item)
                                  : item[column.field]}
                              </Table.Td>
                            );
                          })}
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  )}
                </Table>
              </div>
            )}
          </div>
        </Card>
      )}
      {/* Pagination Component */}
      {showFooter && (
        <Card className="flex flex-col-reverse flex-wrap items-center flex-reverse mt-8 sm:flex-row p-5">
          <Pagination
            className="flex-1 w-full mr-auto sm:w-auto"
            totalItems={totalItems}
            rowsPerPage={perPage}
            currentPage={currentPage}
            onPageClick={onPageChange}
            onNextClick={onPageChange}
            onPrevClick={onPageChange}
            onLastClick={onPageChange}
            onFirstClick={onPageChange}
            showColors={showColors}
          />
        </Card>
      )}
    </>
  );
};

export default DataTable;
