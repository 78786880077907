import moment from "moment";
import { Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import { TableColumnType } from "src/components/DataTable";
import { dateParser } from "src/helpers";
import { emailTemplateDataProps, userDataProps } from "src/interfaces";

export const columns = ({
  onEditClick,
}: {
  onEditClick?: Function | undefined;
}) => {
  return [
    {
      title: "Name",
      field: "title",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "Locations",
      field: "locations",
      cell: (row: emailTemplateDataProps) => <div className="max-w-[15rem]">
        {row?.locations?.map(item => <span className="p-1 bg-slate-200 mr-1 rounded-md">{item?.name}</span>)}
      </div>,
    },
    {
      title: "Created at",
      field: "created_at",
      cell: (row) => dateParser(row?.created_at, "DD-MM-YYYY"),
    },
    {
      title: "Status",
      field: "status",
      cell: (row) => row?.status || "Inactive"
    },
    {
      title: "Action",
      field: "action",
      hide: !onEditClick,
      cell: (row: userDataProps) => {
        if (!onEditClick) return null;
        return (
          <Menu>
            <Menu.Button
              onClick={() => {
                onEditClick && onEditClick(row);
              }}
              className="w-100 h-5 text-slate-500"
            >
              <Lucide icon="Pencil" className="w-4 h-4 mr-2 text-primary" />
            </Menu.Button>
          </Menu>
        );
      },
    },
  ] as TableColumnType<any>[];
};

export const templateTypes = [
  { label: "Ticket", value: 1 },
  { label: "Quotation", value: 2 },
];

export const templateTypesEnum = {
  Ticket: 1,
  Quotation: 2,
};

export const templateStatusEnum = {
  Active: 1,
  Inactive: 0,
};
