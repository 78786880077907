import Lucide from "../../../base-components/Lucide";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import Card from "src/components/Card";
import { menuIdProps, menus } from "./constants";
import Queries from "./Queries";
import Button from "src/base-components/Button";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { customerDataProps } from "src/interfaces";
import { removeCustomer } from "src/stores/customer";
import { fetchCustomersDetails } from "src/services";
import { Car } from "lucide-react";
import LoadingIcon from "src/base-components/LoadingIcon";
import SlideoverModal from "src/components/SlideoverModal";
import { baseUrl } from "src/services/http";
import Pagination from "src/base-components/Pagination";
import FileManager from "./fileManager";
interface TicketItem {
  ticket?: string;
  name?: string;
  title?: string;
  quotation_code?: string | any;
}

function Component({
  customer,
  removeCustomer,
}: {
  customer: customerDataProps | undefined;
  removeCustomer: Function;
}) {
  const params = useParams();
  const id = params?.id || "";
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState<menuIdProps>("info");
  const [loading, setLoading] = useState(false);
  const [documentsData, setDocumentsData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [perPage, setPerPage] = useState<any>(0);
  const [totalItems, setTotalItems] = useState<any>(0);

  const handleCutomerDocs = async (cussId: any, pageNo: any) => {
    try {
      const result = await fetchCustomersDetails({
        customer_id: cussId,
        page: pageNo,
      });
      if (!_.isEmpty(result)) {
        setCurrentPage(result?.current_page);
        setPerPage(result?.per_page);
        setTotalItems(result?.total);
        setDocumentsData(result.data);
      }
    } catch (error) {
      console.log("Error - handleCutomerDocs : ", error);
    }
  };

  useEffect(() => {
    if (activeMenu === "documents") {
      setLoading(true);
      handleCutomerDocs(id, 1);
    }
  }, [activeMenu, id]);

  useEffect(() => {
    if (documentsData && documentsData?.length > 0) {
      setLoading(false);
    }
  }, [documentsData]);

  const onPageChange = (value: number) => {
    setCurrentPage(value);
    handleCutomerDocs(id, value);
  };

  useEffect(() => {
    const cancelLoader = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(cancelLoader);
  }, []);

  return (
    <div className="grid grid-cols-12 gap-y-10 gap-x-6">
      <div className="col-span-12">
        <div className="flex flex-col md:h-10 gap-y-3 md:items-center md:flex-row">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="primary"
            className="mr-2 p-1 px-2 shadow-none w-[80px] lg:mb-0 sm:mb-1"
          >
            <Lucide icon="ArrowLeft" className="stroke-[1.3] w-4 h-4 mr-2" />{" "}
            Back
          </Button>
          <div className="text-base font-medium group-[.mode--light]:text-white">
            Customer Profile
          </div>
        </div>
        <div className="mt-3.5 grid grid-cols-12 gap-y-10 gap-x-6">
          <div className="relative col-span-12 xl:col-span-3 ">
            <div className="sticky top-[104px] ">
              <Card className="flex flex-col px-5 pt-5 pb-5">
                {menus?.map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    onClick={() => setActiveMenu(item?.id)}
                    className={clsx([
                      "flex items-center py-3 first:-mt-3 last:-mb-3 [&.active]:text-primary [&.active]:font-medium hover:text-primary",
                      { active: activeMenu === item?.id },
                    ])}
                  >
                    <Lucide
                      icon={item?.icon}
                      className="stroke-[1.3] w-4 h-4 mr-3"
                    />

                    {item?.title}
                  </Link>
                ))}
              </Card>
            </div>
          </div>
          <div className="flex flex-col col-span-12 xl:col-span-9">
            {activeMenu == "info" && (
              <Card className="mt-1">
                <div className="border relative border-dashed px-5 py-3 rounded-lg">
                  <div className="flex gap-x-5 flex-col justify-between items-start flex-1 mt-0 gap-y-1 sm:mt-5 sm:mb-3 lg:mt-2 xl:mb-0 md:flex-row">
                    <div>
                      <div className="text-slate-500">Name:</div>
                      <div className="mt-1.5 font-medium text-slate-600">
                        {customer?.fullname}
                      </div>
                    </div>
                    <div className="max-w-[25%]">
                      <div className="text-slate-500">Email:</div>
                      <div className="mt-1.5 font-medium text-slate-600 w-max">
                        {customer?.email}
                      </div>
                    </div>
                    <div className="max-w-[20%] min-w-[15%]">
                      <div className="text-slate-500">Phone No:</div>
                      <div className="mt-1.5 font-medium text-slate-600">
                        {customer?.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            {activeMenu == "queries" && <Queries id={id} />}
            {activeMenu == "documents" && (
              <>
                <FileManager data={documentsData} loading={loading} />

                <Card className="flex flex-col-reverse flex-wrap items-center flex-reverse mt-8 sm:flex-row p-5">
                  <Pagination
                    className="flex-1 w-full mr-auto sm:w-auto"
                    totalItems={totalItems}
                    rowsPerPage={perPage}
                    currentPage={currentPage}
                    onPageClick={onPageChange}
                    onNextClick={onPageChange}
                    onPrevClick={onPageChange}
                    onLastClick={onPageChange}
                    onFirstClick={onPageChange}
                    showColors={false}
                  />
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ customer }: RootState) => ({
  customer,
});

const mapDispatchToProps = (dispatch: any) => ({
  removeCustomer: () => dispatch(removeCustomer()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
