import { call, put, takeLatest } from "redux-saga/effects";
import { loginProps, permissionListProps } from "../../interfaces";
import {
  HANDLE_FETCH_AMADEUS_TOKEN,
  FETCH_USER_PERMISSIONS,
  HANDLE_LOGIN,
} from "./types";
import {
  fetchUserPermissionsService,
  getAmadeusTokenService,
  loginService,
} from "../../services";
import { setAmadeusToken, setAuthUser, setUserPermission } from "./slice";
import { store } from "..";
import moment from "moment";
import { setAlert } from "../basicSlices";

function* handleLogin({ payload }: { payload: loginProps }): Generator<any> {
  try {
    const responseData: any = yield call(loginService, payload);
    sessionStorage.setItem("IsSameSession", "Yes");
    yield put(setAuthUser({ ...responseData, isPersist: payload?.isPersist }));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to Login",
      })
    );
  }
}

export function* handleFetchAmadeusToken(): Generator<any> {
  var token_time = store?.getState()?.authUser?.amadeus?.token_time;
  var expires_in = store?.getState()?.authUser?.amadeus?.expires_in;
  var token = store?.getState()?.authUser?.amadeus?.access_token;
  const end = moment();
  var duration = moment.duration(end.diff(moment(token_time)));
  var seconds = duration.asSeconds();
  if (seconds < parseInt(expires_in)) {
    return token;
  }
  try {
    const responseData: any = yield call(getAmadeusTokenService);
    yield put(setAmadeusToken({ ...responseData, token_time: new Date() }));
    token = responseData?.access_token;
  } catch (error) {}
  return token;
}

function* handleFetchUserPermissionList({
  payload,
}: {
  payload: permissionListProps;
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchUserPermissionsService, payload);
    yield put(setUserPermission(responseData));
  } catch (error: any) {}
}

export default function* authSaga() {
  yield takeLatest<any>(HANDLE_LOGIN, handleLogin);
  yield takeLatest<any>(HANDLE_FETCH_AMADEUS_TOKEN, handleFetchAmadeusToken);
  yield takeLatest<any>(FETCH_USER_PERMISSIONS, handleFetchUserPermissionList);
}
