import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export const colorSchemes = ["default"] as const;

export type ColorSchemes = (typeof colorSchemes)[number];

interface ColorSchemeState {
  value: ColorSchemes;
}

const initialState: ColorSchemeState = {
  value: colorSchemes[0],
};

export const colorSchemeSlice = createSlice({
  name: "colorScheme",
  initialState,
  reducers: {
    setColorScheme: (state, action: PayloadAction<ColorSchemes>) => {
      return {
        ...state,
        value: action.payload,
      };
    },
  },
});

export const { setColorScheme } = colorSchemeSlice.actions;

export const selectColorScheme = (state: RootState) => {
  return state.colorScheme.value;
};

export default colorSchemeSlice.reducer;
