import { FETCH_QUEUE_LIST, CREATE_QUEUE, UPDATE_QUEUE } from "./types";

export const fetchQueues = () => {
  return {
    type: FETCH_QUEUE_LIST,
  };
};

export const createQueue = (payload: any) => {
  return {
    type: CREATE_QUEUE,
    payload,
  };
};

export const updateQueue = (payload: any) => {
  return {
    type: UPDATE_QUEUE,
    payload,
  };
};
