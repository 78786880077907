import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { globalSearchDataProps } from "src/interfaces/dashboard.interfaces";

const initialState: globalSearchDataProps = {
  queries: [],
  customers: []
};

export const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    setGlobalSearchData: (state, action: PayloadAction<globalSearchDataProps>) => {
      return action.payload;
    },
    removeGlobalSearchData: () => {
      return initialState;
    },
  },
});

export const { setGlobalSearchData, removeGlobalSearchData } = globalSearchSlice.actions;

export const selectGlobalSearchData = (state: RootState) => {
  return state.dashboards;
};

export default globalSearchSlice.reducer;
