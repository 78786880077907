import { dashboardAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchDashboardService = async ({
  fromDate,
  toDate,
  stage,
}: {
  fromDate: string;
  toDate: string;
  stage: number;
}) => {
  try {
    const data = await http.Get(
      `${dashboardAPIUrls?.get}?fromDate=${fromDate}&toDate=${toDate}&stage=${stage}`
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchDashboardGlobalSearchService = async (
  keywords: string = ""
) => {
  try {
    const data = await http.Get(
      `${dashboardAPIUrls?.globalSearch}?search=${keywords}`
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
