import DefaultPhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.css";

type DefaultPhoneInputProps = typeof DefaultPhoneInput;
type PhoneInputProps = DefaultPhoneInputProps & {
  name?: string;
  value?: string;
  className?: string;
  onChange?: (payload: { dialCode: string; phone: string }) => void;
  placeholder?: string;
  required?: boolean;
};

const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
  return (
    <DefaultPhoneInput
      {...props}
      defaultCountry={"CA"}
      countryOptionsOrder={["CA", "IN", "US", "PK"]}
      value={props?.value}
      className={`w-[100%] border-slate-300/100 text-sm text-slate-500 ${props?.className}`}
      limitMaxLength={true}
      placeholder={props?.placeholder}
      focusInputOnCountrySelection
      required={props?.required}
      onChange={(val) => {
        let code = formatPhoneNumberIntl(val || "");
        code = code?.substring(0, code?.indexOf(" "));
        props?.onChange &&
          props?.onChange({
            phone: val?.replace(code, "") || "",
            dialCode: code,
          });
      }}
      aria-label="#Phone"
    />
  );
};

export default PhoneInput;
