import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_CUSTOMER, FETCH_CUSTOMER_LIST, UPDATE_CUSTOMER } from "./types";
import { createCustomerService, fetchCustomersService, updateCustomerService } from "../../services";
import { setCustomers } from "./slice";
import { handleLoadQuery } from "../query/saga";
import { setAlert } from "../basicSlices";

function* handleLoadCustomers({
  payload,
}: {
  payload?: { keywords: string; type?: number; callback?: Function };
} = {}): Generator<any> {
  try {
    let responseData: any = yield call(
      fetchCustomersService,
      payload?.keywords,
      payload?.type
    );
    if (Array.isArray(responseData)) {
      responseData = { data: responseData };
    }
    yield put(setCustomers(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Customers!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleCreateCustomer({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createCustomerService, payload);
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Quotation!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleUpdateCustomers({
  payload,
}: {
  payload?: { callback?: Function; query_id: string };
} = {}): Generator<any> {
  try {
    const responseData: any = yield call(updateCustomerService, payload);
    if (payload?.query_id) {
      yield handleLoadQuery({ payload: { id: payload?.query_id } })
    }
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Customer Information!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* customerSaga() {
  yield takeLatest<any>(FETCH_CUSTOMER_LIST, handleLoadCustomers);
  yield takeLatest<any>(CREATE_CUSTOMER, handleCreateCustomer);
  yield takeLatest<any>(UPDATE_CUSTOMER, handleUpdateCustomers);
}
