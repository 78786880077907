import React from 'react';
interface ErrorMessageProps {
  message: string | undefined;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div className="mt-1 text-danger font-light ml-2 whitespace-break-spaces">
      {typeof message === "string" && message}
    </div>
  );
};
export default ErrorMessage;
