import {
  CREATE_QUOTATION,
  ACCEPT_QUOTATION,
  SEND_QUOTATION,
  UPLOAD_TICKET,
  DELETE_TICKET,
  SEND_TICKET,
} from "./types";

export const createQuotation = (payload: any) => {
  return {
    type: CREATE_QUOTATION,
    payload,
  };
};

export const acceptQuotation = (payload: any) => {
  return {
    type: ACCEPT_QUOTATION,
    payload,
  };
};

export const sendQuotation = (payload: any) => {
  return {
    type: SEND_QUOTATION,
    payload,
  };
};

export const uploadTicket = (payload: any) => {
  return {
    type: UPLOAD_TICKET,
    payload,
  };
};

export const sendTicket = (payload: any) => {
  return {
    type: SEND_TICKET,
    payload,
  };
};

export const deleteTicket = (payload: any) => {
  return {
    type: DELETE_TICKET,
    payload,
  };
};
