import { useEffect } from "react";
import Notification from "src/base-components/Notification";
import Lucide from "src/base-components/Lucide";
import { RootState } from "src/stores";
import { removeAlert } from "src/stores/basicSlices";
import { connect } from "react-redux";
import { alertProps } from "src/interfaces";
import Toastify from "toastify-js";
import Swal from "sweetalert2";

let alertId = `toast-${new Date().getTime()}`;
const alertDuration = 6000;

const Component = ({
  alert,
  onRemove,
}: {
  alert: alertProps;
  onRemove: Function;
}) => {
  const handleAlert = async () => {
    if (alert?.visible) {
      if (alert?.alertType == "Swal") {
        Swal.fire(alert?.title, alert?.text, alert?.swalIcon);
        return;
      }
      const isAlertActive = document.querySelector(`#${alertId}`);
      if (isAlertActive && typeof isAlertActive != "string") {
        const alertClone = isAlertActive.cloneNode(true);
        (alertClone as HTMLElement).classList.remove("hidden");
        Toastify({
          node: alertClone,
          duration: alertDuration,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          callback: () => {},
        }).showToast();
      }
    }
  };
  useEffect(() => {
    handleAlert()?.then(() => {
      alertId = `toast-${new Date().getTime()}`;
      onRemove();
    });
  }, [alert?.visible]);

  if (!alert?.visible) {
    return null;
  }
  return (
    <>
      <Notification id={alertId} className="flex bg-gray-150 items-center">
        <Lucide icon={alert?.icon as any} className={"text-" + alert?.type} />
        <div className="px-4">
          {alert?.title && (
            <div className="font-medium mb-1">{alert?.title}</div>
          )}
          <div className="text-slate-500">{alert?.text}</div>
        </div>
      </Notification>
    </>
  );
};

const mapStateToProps = ({ alert }: RootState) => ({ alert });

const mapDispatchToProps = (dispatch: any) => ({
  onRemove: () => dispatch(removeAlert()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
