import { fetch as http } from "./http";
const url: string = "https://api.pnrconverter.com/api";

const envEnums: any = {
  Developement: "dev",
  Staging: "staging",
  Production: "prod",
};
const currentEnv = envEnums[process.env.REACT_APP_ENV || "prod"];
export const parsePNRService = async (payload: {
  pnr: string;
  callback?: Function;
}) => {
  try {
    // if (
    //   origin === "http://localhost:5173" ||
    //   origin === "http://192.168.18.127:5173"
    // ) {
    const data = await http
      ?.Post("/quotations/pnr", { pnr: payload.pnr })
      .then((response) => {
        const parsedData = JSON.parse(response);
        return parsedData?.data;
      })
      .catch((error) => {
        console.log("ERR", error);
      });
    // const data = await http?.Postx_www_form(url, `pnr=${payload?.pnr}`, "", {
    //   PUBLIC_APP_KEY: process.env.REACT_APP_PNR_CONVERTER_CLIENT_ID,
    //   PRIVATE_APP_KEY: process.env.REACT_APP_PNR_CONVERTER_CLIENT_SECRET,
    // });
    return data;
    // } else {
    //   const data = await http.Post(
    //     `api/pnrParser.${currentEnv}.php`,
    //     { pnr: payload?.pnr },
    //     "multipart/form-data",
    //     undefined,
    //     "/"
    //   );
    //   return data;
    // }
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
