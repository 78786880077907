import { isAdmin } from "src/helpers";
import * as yup from "yup";

export const itineraryDetailsSchema = yup.object().shape({
  source: yup.string().required("Please enter a source."),
  location_id: isAdmin()
    ? yup.string().required("Please enter a location.")
    : yup.string(),
  destination: yup.string().required("Please enter a destination."),
  date: yup
    .date()
    .typeError("Please, provide valid date.")
    .required("Date is required"),
});

export const schemaWithCustomerId = yup
  .object({
    no_of_adults: yup
      .number()
      .typeError("Please provide the adult count")
      .required("Please provide the adult count")
      .test("startsWithZero", "Adult count cannot start with zero", (value) => {
        return !value?.toString().startsWith("0");
      }),
    no_of_children: yup
      .number()
      .typeError("Please provide the child count")
      .required("Please provide the child count"),
    no_of_infants: yup
      .number()
      .typeError("Please provide the infant count")
      .required("Please provide the infant count"),
    query_type: yup.string().required("Please select the type."),
    trip_type: yup.string().required("Please select the trip type."),
    customer_id: yup.string().required("Please select customer details."),
    query_itinerary_detail: yup
      .array()
      .of(itineraryDetailsSchema)
      .nullable()
      .required("At least one itinerary detail is required"),
    source: yup.string().required("Please select the source."),
    location_id: isAdmin()
      ? yup.string().required("Please enter a location.")
      : yup.string(),
  })
  .required();

export const schemaWithCustomerDetail = yup
  .object({
    no_of_adults: yup
      .number()
      .typeError("Please provide the adult count")
      .required("Please provide the adult count")
      .test("startsWithZero", "Adult count cannot start with zero", (value) => {
        return !value?.toString().startsWith("0");
      }),
    no_of_children: yup
      .number()
      .typeError("Please provide the child count")
      .required("Please provide the child count"),
    no_of_infants: yup
      .number()
      .typeError("Please provide the infant count")
      .required("Please provide the infant count"),
    query_type: yup.string().required("Please select the type."),
    trip_type: yup.string().required("Please select the trip type."),
    customer_name: yup.string().required("Please enter customer name."),
    // customer_email: yup.string().email('Please enter a valid email.').required('Please enter customer email.'),
    // customer_countrycode: yup.string().required('Please select country code.'),
    customer_phone: yup
      .string()
      .required("Please enter customer phone number."),
    query_itinerary_detail: yup
      .array()
      .of(itineraryDetailsSchema)
      .nullable()
      .required("At least one itinerary detail is required"),
    source: yup.string().required("Please select the source."),
    location_id: isAdmin()
      ? yup.string().required("Please enter a location.")
      : yup.string(),
  })
  .required();
