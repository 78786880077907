import { connect } from "react-redux";
import Button from "src/base-components/Button";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
  InputGroup,
} from "src/base-components/Form";
import { FormSwitch } from "src/base-components/Form";
import SlideoverModal from "src/components/SlideoverModal";
import { useEffect, useMemo, useState } from "react";
import Litepicker from "src/base-components/Litepicker";
import Lucide from "src/base-components/Lucide";
import {
  acceptPaymentProps,
  paymentProps,
  signTemplatesDataProps,
} from "src/interfaces/payment.interfaces";
import {
  acceptPayment,
  createPayment,
  fetchSignTemplates,
} from "src/stores/payment";
import { alertProps, queryDataProps, userDataProps } from "src/interfaces";
import { RootState } from "src/stores";
import moment from "moment";
import {
  checkPermission,
  encrypt,
  formatCVV,
  formatCardNumber,
  formatExpiryDate,
} from "src/helpers";
import LoadingIcon from "src/base-components/LoadingIcon";
import { paymentAPIUrls } from "src/common/apiActions";
import { number } from "card-validator";
import { setAlert } from "src/stores/basicSlices";
import PhoneInput from "src/components/PhoneInput";
import ReactSelect from "src/base-components/ReactSelect";
import icon from "src/assets/images/icon/pdf.svg";
import ViewContract from "./ViewContract";
import { fetchUsers } from "src/stores/user";
import {
  isQueryClosedAndCancelled,
  isQueryOpen,
  isQueryOpenOrReopened,
} from "../../helpers";
import { baseUrl } from "src/services/http";
import _ from "lodash";

interface cardType {
  niceType: string;
  type: string;
  patterns: Array<number>;
  gaps: Array<number>;
  lengths: Array<number>;
  code: {
    name: string;
    size: number;
  };
  matchStrength: number;
}
export const Component = ({
  headerTitle,
  setAlert,
  query,
  fetchTemplates,
  templates,
  onCreate,
  onMarkAccepted,
  handleAssignToClick,
  isPartial,
  isView,
  loading,
  visible,
  setVisible,
  model,
  setModel,
  acceptModel,
  setAcceptModel,
  fetchUsers,
  users,
}: {
  headerTitle?: string;
  setAlert: (payload: alertProps) => void;
  query: queryDataProps | undefined;
  onCreate: (payload: any) => void;
  fetchTemplates: (payload?: any) => void;
  templates: signTemplatesDataProps[];
  onMarkAccepted: (payload: any) => void;
  handleAssignToClick: Function;
  isPartial: boolean;
  isView: boolean;
  loading: boolean;
  visible: boolean;
  setVisible: Function;
  model: paymentProps;
  setModel: React.Dispatch<React.SetStateAction<paymentProps>> | any;
  acceptModel: acceptPaymentProps;
  setAcceptModel: React.Dispatch<
    React.SetStateAction<acceptPaymentProps | any>
  >;
  fetchUsers: (payload: { type: 1 | 2 | 3 }) => void;
  users: userDataProps[];
}) => {
  const canMarkAccepted = checkPermission(paymentAPIUrls?.accept);
  const [cardType, setCardType] = useState<cardType | null>();
  const [submitting, setSubmitting] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [contractVisible, setContractVisible] = useState(false);
  const [superlargeSlideoverSizePreview, setSuperlargeSlideoverSizePreview] =
    useState(false);
  const [selectedBillingDoc, setSelectedBillingDoc] = useState<any>(null);
  const [remainingAmount, setRemainingAmount] = useState<boolean>(false);
  const [partial, setPartial] = useState<boolean>(false);
  const [priceObject, setPriceObject] = useState<any>([]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setModel((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (model?.payment_mode == "2") {
      const expiryDate = model?.expiration_date?.split("/");
      if (
        ((expiryDate?.[0] || 0) < moment()?.format("MM") &&
          (expiryDate?.[1] || 0) <= moment()?.format("YYYY")) ||
        (expiryDate?.[1] || 0) < moment()?.format("YYYY")
      ) {
        setAlert({
          text: "Card expired, please enter valid card",
          alertType: "Swal",
          swalIcon: "error",
        });
        return null;
      }
    }
    if (model?.payment_mode == "1" && model?.is_split == 1) {
      if (
        parseFloat((model?.charge1_amount as any) || 0) +
          parseFloat((model?.charge2_amount as any) || 0) !=
        parseFloat(model?.amount)
      ) {
        setAlert({
          alertType: "Swal",
          swalIcon: "warning",
          text: "Charge 1 and Charge 2 amount must be equal to total amount",
        });
        return;
      }
    }
    let payload: paymentProps = {
      ...model,
      card_number: model?.card_number ? encrypt(model?.card_number) : "",
      card_cvv: model?.card_number ? encrypt(model?.card_cvv) : "",
    };

    Object.keys(payload).forEach((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });

    if (canMarkAccepted && isView) {
      const acceptPayload = acceptModel;
      Object.keys(acceptPayload).forEach((key) => {
        if (!acceptPayload[key]) {
          delete acceptPayload[key];
        }
      });
      if (!model?.contract_status && model?.payment_mode == "1") {
        setAlert({
          text: "Contract not signed yet",
          alertType: "Swal",
          swalIcon: "warning",
        });
        return;
      }
      setSubmitting(true);
      onMarkAccepted({
        ...acceptPayload,
        location_id: query?.location_id,
        quotation_id: model?.quotation_id,
        callback: (error: any) => {
          setSubmitting(false);
          if (!error) {
            setVisible(false);
            setAcceptModel({
              location_id: query?.location_id || "",
              query_id: query?.id || "",
              payment_id: "",
              total_margin: 0,
              status: "",
            });
            setModel({
              location_id: query?.location_id || "",
              query_id: query?.id || "",
              id: "",
              payment_date: moment(new Date()).format("YYYY-MM-DD"),
              amount: "",
              payment_mode: "",
              is_split: 0,
            });
          }
        },
      });
      return;
    }

    if (!isView) {
      setSubmitting(true);
      onCreate({
        ...payload,
        location_id: query?.location_id,
        is_partial: partial,
        callback: (error: any) => {
          setSubmitting(false);
          if (!error) {
            setVisible(false);
            setModel({
              query_id: query?.id || "",
              location_id: query?.location_id || "",
              id: "",
              payment_date: moment(new Date()).format("YYYY-MM-DD"),
              amount: "",
              payment_mode: "",
              is_split: 0,
            });
          }
        },
      });
    }
  };
  useEffect(() => {
    if (model?.payment_mode == "1") {
      setLoadingTemplates(true);
      fetchTemplates({ callback: () => setLoadingTemplates(false) });
      if (!model?.template_id) {
        setModel((previous) => {
          return {
            ...previous,
            signer_name: query?.customer?.fullname,
            signer_email: query?.customer?.email,
            signer_country_code: query?.customer?.country_code,
            signer_phone: query?.customer?.phone,
          };
        });
      }
    } else {
      setModel((previous) => {
        return {
          ...previous,
          signer_name: "",
          signer_email: "",
          signer_country_code: "",
          signer_phone: "",
          is_split: 0,
        };
      });
    }
  }, [model?.payment_mode]);

  useEffect(() => {
    if (visible && model?.type == 2) {
      fetchUsers({
        type: 2,
      } as any);
    }
  }, [visible]);

  const hideCardDetail =
    (isView && !canMarkAccepted) || isQueryClosedAndCancelled(query?.status);

  const generateOptions = useMemo(() => {
    const currentAccepted: any = query?.accepted_quotation;
    if (query && currentAccepted?.length > 0) {
      const payload = currentAccepted
        .filter((item) => item?.status_text === "Accepted" || "Ticket Sent")
        .map((item) => ({
          label: item?.quotation_code,
          value: item?.id,
        }));
      return payload;
    }
  }, [query?.accepted_quotation]);

  const generateOptionsPayments = useMemo(() => {
    const currentAccepted: any = query?.accepted_quotation;
    if (query && currentAccepted?.length > 0) {
      const payload = currentAccepted
        .filter(
          (item) =>
            item?.status_text === "Accepted" && item?.remaining_payment !== 0
        )
        .map((item) => ({
          label: item?.quotation_code,
          value: item?.id,
        }));
      return payload;
    }
  }, [query?.accepted_quotation]);

  const generateOptionsCancel = useMemo(() => {
    const currentAccepted: any = query?.accepted_quotation;
    if (query && currentAccepted?.length > 0) {
      const payload = currentAccepted.map((item) => ({
        label: item?.quotation_code,
        value: item?.id,
      }));
      return payload;
    }
  }, [query?.accepted_quotation]);

  useEffect(() => {
    if (model?.type == 2) {
      console.log("UER", users, query?.assigned_to);
      const selected = users?.find(
        (item: any) => item?.id === query?.assigned_to
      );

      if (selected) {
        console.log("Selected ", selected);
        onChangeInput({
          target: { name: "userid", value: selected?.id as any },
        } as any);
      }
    }
  }, [users]);

  const areAllPricesZero = (data: any) => {
    const allPricesZero = (prices: any) =>
      prices?.every((item: any) => item.price === 0);
    return allPricesZero(data.actualPrice) && allPricesZero(data.quotedPrice);
  };

  const generatePriceObject = (prices) => {
    const priceMappings = {
      adult_actual_price: { title: "Adult", type: "actual" },
      children_actual_price: { title: "Child", type: "actual" },
      infant_actual_price: { title: "Infant", type: "actual" },
      adult_quoted_price: { title: "Adult", type: "quoted" },
      children_quoted_price: { title: "Child", type: "quoted" },
      infant_quoted_price: { title: "Infant", type: "quoted" },
    };

    const order = ["Adult", "Child", "Infant"];

    const result: any = { actualPrice: [], quotedPrice: [] };

    const tempActualPrice = {};
    const tempQuotedPrice = {};

    for (let key in prices) {
      if (priceMappings[key]) {
        const { title, type } = priceMappings[key];
        if (type === "actual") {
          tempActualPrice[title] = parseFloat(prices[key]);
        } else if (type === "quoted") {
          tempQuotedPrice[title] = parseFloat(prices[key]);
        }
      }
    }

    result.actualPrice = order.map((title) => ({
      title,
      price: tempActualPrice[title] || 0,
    }));
    result.quotedPrice = order.map((title) => ({
      title,
      price: tempQuotedPrice[title] || 0,
    }));

    return result;
  };

  useEffect(() => {
    try {
      const currentAccepted: any = query?.accepted_quotation;
      if (
        model?.quotation_id &&
        currentAccepted &&
        currentAccepted?.length > 0 &&
        !isView
      ) {
        const slectedId = currentAccepted?.find(
          (item: any) => item?.id === model?.quotation_id
        );

        if (!_.isEmpty(slectedId)) {
          setRemainingAmount(true);
          setModel((previous) => ({
            ...previous,
            amount: slectedId?.remaining_payment,
            actual_amount: slectedId?.remaining_payment,
            isPartial: slectedId?.is_partial == 0 ? false : true,
          }));
        }
        ``;
      }
    } catch (error) {
      console.error("Error : ", error);
    }
  }, [model?.quotation_id]);

  useEffect(() => {
    try {
      const currentAccepted: any = query?.accepted_quotation;
      const selectedQuotation: any = currentAccepted?.find(
        (item: any) => item?.id === model?.quotation_id
      );
      const result: any = generatePriceObject(selectedQuotation);
      setPriceObject(result ?? []);
    } catch (error) {
      console.error("Error : ", error);
    }
  }, [model]);

  const handleIsPartial = () => {
    setPartial(!partial);
  };

  const getFirstPartBeforeSlash = (path: string) => {
    return path?.split("/")[0];
  };

  useEffect(() => {
    if (isPartial) {
      setPartial(isPartial);
    }
  }, [isPartial]);

  return (
    <SlideoverModal
      size="lg"
      visible={visible}
      onClose={() => {
        setSubmitting(false);
        setVisible(false);
        setPartial(false);
      }}
      headerTitle={headerTitle || `${!isView ? "Add " : ""} Payment`}
    >
      <SlideoverModal
        size="xl"
        visible={superlargeSlideoverSizePreview}
        onClose={() => {
          setSuperlargeSlideoverSizePreview(false);
        }}
        headerTitle={
          getFirstPartBeforeSlash(selectedBillingDoc?.name) ??
          "Billing Document"
        }
      >
        <div className="h-[100%]">
          {selectedBillingDoc && (
            <iframe
              src={`${baseUrl}/billing_doc/${selectedBillingDoc?.File}`}
              width="100%"
              height="100%"
            />
          )}
        </div>
      </SlideoverModal>
      {loading ? (
        <div className="flex h-1/2">
          <LoadingIcon color="#64748b" icon="oval" className="w-8 h-8 m-auto" />
        </div>
      ) : (
        <form className="flex flex-col h-full" onSubmit={onSubmit}>
          {isView ? (
            <div className="w-full mb-3">
              <FormLabel className="">Quotation Number</FormLabel>
              <ReactSelect
                className="w-full"
                aria-label=".form-select-lg example"
                value={model?.quotation_id}
                name="quotation_id"
                onChange={(_, value) => {
                  onChangeInput({
                    target: {
                      name: "quotation_id",
                      value: value,
                    },
                  });
                }}
                required
                disabled={isView}
                placeholder="--Select--"
                options={generateOptions}
              />
            </div>
          ) : model?.type === 2 ? (
            <div className="w-full mb-3">
              <FormLabel className="">Quotation Number</FormLabel>
              <ReactSelect
                className="w-full"
                aria-label=".form-select-lg example"
                value={model?.quotation_id}
                name="quotation_id"
                onChange={(_, value) => {
                  onChangeInput({
                    target: {
                      name: "quotation_id",
                      value: value,
                    },
                  });
                }}
                required
                disabled={isView || (model?.quotation_id as any)}
                placeholder="--Select--"
                options={generateOptionsCancel}
              />
            </div>
          ) : (
            <div className="w-full mb-3">
              <FormLabel className="">Quotation Number</FormLabel>
              <ReactSelect
                className="w-full"
                aria-label=".form-select-lg example"
                value={model?.quotation_id}
                name="quotation_id"
                onChange={(_, value) => {
                  onChangeInput({
                    target: {
                      name: "quotation_id",
                      value: value,
                    },
                  });
                }}
                required
                disabled={isView}
                placeholder="--Select--"
                options={generateOptionsPayments}
              />
            </div>
          )}

          {model?.type != 2 && (
            <div className="w-full mb-3">
              <FormSwitch className="gap-1">
                <FormSwitch.Input
                  disabled={isView || isPartial}
                  id="add-payment-switch"
                  className="mr-4"
                  type="checkbox"
                  checked={partial}
                  onChange={(e) => handleIsPartial()}
                />
                <FormSwitch.Label htmlFor="add-payment-switch" className="">
                  Is this Partial Payment?
                </FormSwitch.Label>
              </FormSwitch>
            </div>
          )}

          <div className="flex flex-col gap-5 items-end sm:flex-row">
            <div className="w-1/2">
              <FormLabel className="">Payment Date</FormLabel>
              <InputGroup className="">
                <InputGroup.Text id="input-group-email">
                  <Lucide icon="CalendarDays" />
                </InputGroup.Text>
                <Litepicker
                  value={moment(model?.payment_date)?.format("DD MMM, YYYY")}
                  disabled={isView}
                  onChange={(e) => {
                    const date = moment(e?.target?.value)?.format("YYYY-MM-DD");
                    setModel((previous) => {
                      return {
                        ...previous,
                        payment_date: date,
                      };
                    });
                  }}
                  options={{
                    autoApply: false,
                    showWeekNumbers: true,
                    format: "DD MMM, YYYY",
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true,
                    },
                  }}
                  className="pl-1 "
                />
              </InputGroup>
            </div>
            <div className="w-1/2">
              <FormLabel className="">Amount</FormLabel>
              <InputGroup className="">
                <InputGroup.Text id="input-group-email">
                  <Lucide icon="DollarSign" />
                </InputGroup.Text>
                <FormInput
                  type="text"
                  name="amount"
                  required
                  readOnly={
                    partial
                      ? false
                      : isView || (!!model?.amount && model?.type != 2)
                  }
                  value={
                    model?.remaining_payment > 0
                      ? model?.remaining_payment
                      : model?.amount
                  }
                  onChange={(e) => {
                    if (
                      model?.actual_amount &&
                      parseFloat(e?.target?.value) >
                        parseFloat(model?.actual_amount) &&
                      model?.type != 2
                    ) {
                      setAlert({
                        text: "Amount should be less then or equal to remaining amount",
                        alertType: "Swal",
                        swalIcon: "info",
                      });
                      return;
                    }
                    onChangeInput(e);
                  }}
                  aria-describedby="input-group-email"
                />
              </InputGroup>
            </div>
            {model?.payment_mode == "1" && (
              <FormSwitch className="w-1/3 gap-2">
                <FormSwitch.Input
                  id="show-example-1"
                  className=""
                  type="checkbox"
                  name="is_split"
                  disabled={isView}
                  value={model?.is_split}
                  checked={(model?.is_split || 0) == 1}
                  onChange={(event) =>
                    setModel((previous) => {
                      return {
                        ...previous,
                        template_id: undefined,
                        template_name: undefined,
                        is_split: model?.is_split == 1 ? 0 : 1,
                      };
                    })
                  }
                />
                <FormSwitch.Label htmlFor="show-example-1" className="">
                  Split Charges?
                </FormSwitch.Label>
              </FormSwitch>
            )}
          </div>

          {model?.formatted_files?.length > 0 && model?.type != 2 && (
            <div className="py-3 ">
              <FormLabel>Attached Documents</FormLabel>
              <div className="flex gap-3 flex-wrap">
                {model?.formatted_files?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="bg-gray-200 p-1 rounded-md cursor-pointer"
                    onClick={() => {
                      setSelectedBillingDoc(item);
                      setSuperlargeSlideoverSizePreview(true);
                    }}
                  >
                    <span>
                      {item?.name && !_.isEmpty(item?.name)
                        ? getFirstPartBeforeSlash(item?.name)
                        : "Attachment"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="w-fulll mt-3">
            {model?.type == 2 && !isView && (
              <div className="w-full mb-2">
                <FormLabel>Assign to</FormLabel>
                <ReactSelect
                  className="flex-1 w-full mt-3 xl:mt-0"
                  aria-label=".form-select-lg example"
                  name="userid"
                  required
                  disabled={isView || (query?.assigned_to as any)}
                  value={model?.userid}
                  onChange={(_, value) =>
                    onChangeInput({
                      target: { name: "userid", value: value as any },
                    } as any)
                  }
                  options={users?.map((item) => {
                    return {
                      label: `${item?.name || ""} ${item?.last_name || ""}`,
                      value: item?.id,
                    };
                  })}
                  placeholder="--Select--"
                />
              </div>
            )}

            {!areAllPricesZero(priceObject) && (
              <div className="flex justify-center py-3">
                <table className="min-w-full bg-white border border-gray-200 rounded-md">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b text-left"></th>
                      {_.get(priceObject, "actualPrice", [])
                        .filter((item: any) => item.price > 0)
                        .map((item: any, index: number) => (
                          <th
                            key={index}
                            className="py-2 px-4 border-b text-left"
                          >
                            {item.title}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(priceObject, "actualPrice", []).some(
                      (item: any) => item.price > 0
                    ) && (
                      <tr>
                        <td className="py-2 px-4 border-b">Actual Price</td>
                        {_.get(priceObject, "actualPrice", [])
                          .filter((item: any) => item.price > 0)
                          .map((item: any, index: any) => (
                            <td key={index} className="py-2 px-4 border-b">
                              ${item.price}
                            </td>
                          ))}
                      </tr>
                    )}

                    {_.get(priceObject, "quotedPrice", []).some(
                      (item: any) => item.price > 0
                    ) && (
                      <tr>
                        <td className="py-2 px-4 border-b">Quoted Price</td>
                        {_.get(priceObject, "quotedPrice", [])
                          .filter((item: any) => item.price > 0)
                          .map((item: any, index: any) => (
                            <td key={index} className="py-2 px-4 border-b">
                              ${item.price}
                            </td>
                          ))}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="w-full">
              <FormLabel className="">Payment Mode</FormLabel>
              <ReactSelect
                className="w-full"
                aria-label=".form-select-lg example"
                value={model?.payment_mode}
                name="payment_mode"
                onChange={(_, value) =>
                  onChangeInput({
                    target: {
                      name: "payment_mode",
                      value: value,
                    },
                  })
                }
                required
                disabled={isView}
                placeholder="--Select--"
                options={[
                  { label: "Payment form", value: "1" },
                  { label: "Credit/Debit Card", value: "2" },
                  { label: "Paid in Store", value: "3" },
                  { label: "Interac", value: "4" },
                  { label: "Cheque/Draft", value: "5" },
                ]}
              />
            </div>

            <div className="mt-5">
              {model?.payment_mode == "1" && (
                <div className="w-full">
                  <h2 className="text-black mb-2">Send Payment form:</h2>
                  <div className="w-full mt-2">
                    <FormLabel className="w-full">Template</FormLabel>
                    <ReactSelect
                      className="flex-1 w-full"
                      name="template_id"
                      required
                      placeholder="--Select--"
                      value={model?.template_id}
                      disabled={isView}
                      loading={loadingTemplates}
                      options={templates
                        .filter(
                          (ele) => ele?.type == (model?.is_split || 0) + 1
                        )
                        ?.map((item) => {
                          return {
                            label: `${item?.template_name} ${
                              model?.is_split == 1 ? "(Split Payment)" : ""
                            }`,
                            value: item?.template_id,
                          };
                        })}
                      onChange={(_, value) => {
                        setModel((previous) => {
                          return {
                            ...previous,
                            template_id: value as string,
                            template_name: templates?.find(
                              (ele) => ele?.template_id == value
                            )?.template_name,
                          };
                        });
                      }}
                    />
                  </div>
                  {model?.template_id && (
                    <div>
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-1/2">
                          <FormLabel className="">Name</FormLabel>
                          <FormInput
                            type="text"
                            name="signer_name"
                            value={model?.signer_name}
                            onChange={onChangeInput}
                            className="w-full"
                            placeholder="Name"
                            aria-label="Name"
                            required={true}
                            readOnly={isView}
                          />
                        </div>
                        <div className="w-1/2">
                          <FormLabel className="">Email</FormLabel>
                          <FormInput
                            type="email"
                            name="signer_email"
                            value={model?.signer_email}
                            onChange={onChangeInput}
                            className="w-full"
                            placeholder="Email ID"
                            aria-label="Email ID"
                            required={true}
                            readOnly={isView}
                          />
                        </div>
                      </div>
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-[49%]">
                          <FormLabel className="">Phone</FormLabel>
                          {isView ? (
                            <FormInput
                              type="text"
                              value={`${model?.signer_country_code} ${model?.signer_phone}`}
                              className="w-full"
                              placeholder="Phone"
                              required={true}
                              readOnly={true || isView}
                            />
                          ) : (
                            <PhoneInput
                              {...({} as any)}
                              value={`${model?.signer_country_code}${model?.signer_phone}`}
                              className="rounded-md"
                              placeholder="#Phone"
                              required={true}
                              onChange={(data) => {
                                setModel((previous) => {
                                  return {
                                    ...previous,
                                    signer_country_code: data?.dialCode,
                                    signer_phone: data?.phone,
                                  };
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className="w-1/2">
                          <FormLabel className="">Date</FormLabel>
                          <FormInput
                            type="text"
                            value={
                              moment(model?.payment_date)?.format(
                                "DD-MM -yyyy"
                              ) || ""
                            }
                            className="w-full"
                            placeholder="Date of Service (dd-mm-yyyy)"
                            readOnly={true || isView}
                          />
                        </div>
                      </div>
                      {model?.is_split == 1 && (
                        <>
                          <div className="w-full flex items-center gap-2 mt-2">
                            <div className="w-1/2">
                              <FormLabel className="">
                                Charge 1 Amount
                              </FormLabel>
                              <FormInput
                                type="number"
                                value={model?.charge1_amount}
                                name="charge1_amount"
                                onChange={(event) => {
                                  setModel((previous) => {
                                    const value = event?.target?.value;
                                    const isLess =
                                      parseFloat(value) +
                                        parseFloat(
                                          (previous?.charge2_amount as any) || 0
                                        ) <=
                                      parseFloat(previous?.amount);
                                    if (isLess) {
                                      return {
                                        ...previous,
                                        charge1_amount: value,
                                      };
                                    } else {
                                      setAlert({
                                        type: "warning",
                                        text: "Charge 1 and Charge 2 amount must be equal to total amount",
                                      });
                                      return {
                                        ...previous,
                                        charge1_amount:
                                          previous?.charge1_amount || "",
                                      };
                                    }
                                  });
                                }}
                                className="w-full"
                                placeholder="Charge 1 Amount"
                                aria-label="Charge 1 Amount"
                                required={true}
                                readOnly={isView}
                              />
                            </div>
                            <div className="w-1/2">
                              <FormLabel className="">
                                Charge 1 Remark
                              </FormLabel>
                              <FormInput
                                value={model?.charge1_for}
                                name="charge1_for"
                                onChange={onChangeInput}
                                className="w-full"
                                placeholder="Charge 1 Remark"
                                aria-label="Charge 1 Remark"
                                required={true}
                                readOnly={isView}
                              />
                            </div>
                          </div>
                          <div className="w-full flex items-center gap-2 mt-2">
                            <div className="w-1/2">
                              <FormLabel className="">
                                Charge 2 Amount
                              </FormLabel>
                              <FormInput
                                type="number"
                                name="charge2_amount"
                                onChange={(event) => {
                                  setModel((previous) => {
                                    const value = event?.target?.value;
                                    const isLess =
                                      parseFloat(value) +
                                        parseFloat(
                                          (previous?.charge1_amount as any) || 0
                                        ) <=
                                      parseFloat(previous?.amount);
                                    if (isLess) {
                                      return {
                                        ...previous,
                                        charge2_amount: value,
                                      };
                                    } else {
                                      setAlert({
                                        type: "warning",
                                        text: "Charge 1 and Charge 2 amount must be equal to total amount",
                                      });
                                      return {
                                        ...previous,
                                        charge2_amount:
                                          previous?.charge2_amount || "",
                                      };
                                    }
                                  });
                                }}
                                value={model?.charge2_amount}
                                className="w-full"
                                placeholder="Charge 2 Amount"
                                aria-label="Charge 2 Amount"
                                required={true}
                                readOnly={isView}
                              />
                            </div>
                            <div className="w-1/2">
                              <FormLabel className="">
                                Charge 2 Remark
                              </FormLabel>
                              <FormInput
                                value={model?.charge2_for}
                                name="charge2_for"
                                onChange={onChangeInput}
                                className="w-full"
                                placeholder="Charge 2 Remark"
                                aria-label="Charge 2 Remark"
                                required={true}
                                readOnly={isView}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="w-full flex items-center gap-2 mt-2">
                        {model?.is_split == 0 && (
                          <div className="w-1/2">
                            <FormLabel className="">Amount</FormLabel>
                            <FormInput
                              type="number"
                              value={model?.amount}
                              className="w-full"
                              placeholder="Amount"
                              aria-label="Amount"
                              readOnly={true || isView}
                            />
                          </div>
                        )}
                        {canMarkAccepted && model?.signed_pdf && (
                          <div className="w-1/2">
                            <FormLabel className="">Contract</FormLabel>
                            <Button
                              variant="outline-primary"
                              onClick={(event: React.MouseEvent) => {
                                event.preventDefault();
                                setContractVisible(true);
                              }}
                              className="flex items-center p-0.5 outline-none border-0"
                            >
                              <img className="h-6 w-6" src={icon} alt="pdf" />
                              <div className="font-semibold ml-1">
                                View Contract
                              </div>
                            </Button>
                            <ViewContract
                              visible={contractVisible}
                              setVisible={setContractVisible}
                              contractUrl={model?.signed_pdf || ""}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {model?.payment_mode == "2" && (
                <div className="flex-1 w-full xl:mt-0flex flex-col">
                  <h2 className="text-black mb-2">Card Details:</h2>
                  <div className="w-full ">
                    <div className="w-full mb-2">
                      <FormLabel className="">Card No.</FormLabel>
                      <FormInput
                        type="text"
                        pattern="[0-9]{4}[\s-]?[0-9]{4}[\s-]?[0-9]{4}[\s-]?[0-9]{4}"
                        name="card_number"
                        onChange={(e) => {
                          const { value } = e.target;
                          const formattedValue = formatCardNumber(value);
                          const numberValidation = number(formattedValue);
                          const newCardType = numberValidation.card;
                          setCardType(newCardType as any);
                          setModel((previous) => ({
                            ...previous,
                            card_number: formattedValue,
                          }));
                        }}
                        maxLength={(cardType?.lengths?.[0] || 16) + 3}
                        value={
                          hideCardDetail
                            ? `XXXX-XXXX-XXXX-${model?.card_number?.substring(
                                15,
                                19
                              )}`
                            : model?.card_number
                        }
                        className="w-full"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        aria-label="XXXX XXXX XXXX XXXX"
                        required={true}
                        readOnly={isView}
                      />
                    </div>
                    <div className="w-full flex gap-2">
                      <div className="w-1/3">
                        <FormLabel className="w-full">Expiry Date</FormLabel>
                        <FormInput
                          type="text"
                          name="expiration_date"
                          value={
                            hideCardDetail ? `XX/XXXX` : model?.expiration_date
                          }
                          onChange={(e) =>
                            setModel((previous) => {
                              return {
                                ...previous,
                                expiration_date: formatExpiryDate(
                                  e?.target?.value
                                ),
                              };
                            })
                          }
                          className="w-full"
                          placeholder="MM/YYYY"
                          aria-label="2018"
                          required={true}
                          readOnly={isView}
                        />
                      </div>
                      <div className="w-1/4">
                        <FormLabel className="w-full">CVV</FormLabel>
                        <FormInput
                          type="text"
                          name="card_cvv"
                          maxLength={cardType?.code?.size}
                          value={hideCardDetail ? `XXX` : model.card_cvv}
                          onChange={(e) =>
                            setModel((previous) => {
                              return {
                                ...previous,
                                card_cvv: formatCVV(e?.target?.value),
                              };
                            })
                          }
                          className="w-full"
                          placeholder="CVV"
                          aria-label="XXX"
                          required={true}
                          readOnly={isView}
                        />
                      </div>
                      <div className="w-1/2">
                        <FormLabel className="w-full">Name on Card</FormLabel>
                        <FormInput
                          type="text"
                          name="cardholder_name"
                          value={model.cardholder_name}
                          onChange={onChangeInput}
                          className="w-full"
                          placeholder="Card Holder Name"
                          aria-label="Card Holder Name"
                          required={true}
                          readOnly={isView}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {model?.payment_mode == "5" && (
                <div className="w-full">
                  <h2 className="text-black mb-2">Cheque details:</h2>
                  <div className="w-full flex gap-2">
                    <div className="w-1/2">
                      <FormLabel className="">Bank name</FormLabel>
                      <FormInput
                        type="text"
                        name="bank_name"
                        value={model?.bank_name || ""}
                        onChange={onChangeInput}
                        className="w-full"
                        placeholder="Bank name on cheque/draft"
                        aria-label="Bank name"
                        required={true}
                        readOnly={isView}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormLabel className="">Cheque/Draft No.</FormLabel>
                      <FormInput
                        type="text"
                        name="check_no"
                        value={model?.check_no || ""}
                        onChange={onChangeInput}
                        className="w-full"
                        placeholder="Cheque/Draft No."
                        aria-label="Cheque/Draft No."
                        required={true}
                        readOnly={isView}
                      />
                    </div>
                  </div>
                  <div className="w-full flex gap-2 mt-2">
                    <div className="w-1/2">
                      <FormLabel className="">Date on Cheque</FormLabel>
                      <FormInput
                        type="date"
                        name="check_date"
                        value={model?.check_date || ""}
                        onChange={onChangeInput}
                        className="w-full"
                        placeholder="Cheque Date"
                        aria-label="Cheque Date"
                        required={true}
                        readOnly={isView}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormLabel className="">Amount on Cheque</FormLabel>
                      <FormInput
                        type="number"
                        name="check_amount"
                        value={model?.check_amount || ""}
                        onChange={onChangeInput}
                        min="0"
                        className="w-full"
                        placeholder="Amount"
                        aria-label="Amount"
                        required={true}
                        readOnly={isView}
                      />
                    </div>
                  </div>
                </div>
              )}
              {canMarkAccepted && isView && (
                <>
                  {model?.type != 2 && (
                    <div className="flex w-full items-center gap-2 mt-2">
                      {model?.payment_mode == "4" && (
                        <div className="w-2/3">
                          <FormLabel className="w-full">
                            Transaction Id
                          </FormLabel>
                          <FormInput
                            type="text"
                            name="transaction_id"
                            value={acceptModel?.transaction_id || ""}
                            onChange={(e) =>
                              setAcceptModel((previous) => {
                                return {
                                  ...previous,
                                  transaction_id: e?.target?.value,
                                };
                              })
                            }
                            className="col-span-4 w-full"
                            placeholder="Transaction ID"
                            aria-label="Transaction ID"
                            required={true}
                            readOnly={acceptModel?.status == "2"}
                          />
                        </div>
                      )}
                      <div className={`w-1/3`}>
                        <FormLabel className="w-full">Margin</FormLabel>
                        <FormInput
                          type="number"
                          name="total_margin"
                          value={acceptModel?.total_margin || ""}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value) || 0;
                            const amount = parseFloat(model?.amount) || 0;

                            if (newValue <= amount) {
                              setAcceptModel((previous) => ({
                                ...previous,
                                total_margin: e.target.value,
                              }));
                            } else {
                              console.warn(
                                "Total margin cannot be greater than the amount."
                              );
                            }
                          }}
                          className={`col-span-4 w-full`}
                          placeholder="Margin"
                          aria-label="Margin"
                          required={true}
                          readOnly={
                            acceptModel?.status == "2" ||
                            !isQueryOpen(query?.stage)
                          }
                        />
                      </div>
                    </div>
                  )}
                  {acceptModel?.status != "2" && (
                    <div className="w-full mt-4">
                      <FormLabel className="">Remark</FormLabel>
                      <FormTextarea
                        className="mb-3"
                        rows={7}
                        value={acceptModel?.remark}
                        required
                        readOnly={!isQueryOpenOrReopened(query?.stage)}
                        onChange={(e) => {
                          setAcceptModel((previous) => {
                            return {
                              ...previous,
                              remark: e?.target?.value,
                            };
                          });
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {(!isView ||
            (canMarkAccepted &&
              acceptModel?.status != "2" &&
              isQueryOpenOrReopened(query?.stage))) && (
            <div className="mt-auto flex">
              {/* {canMarkAccepted && isView && acceptModel?.status != "2" && (
                <Button
                  type="button"
                  variant="primary"
                  className="group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                  onClick={() => {
                    if (handleAssignToClick) {
                      handleAssignToClick(query?.sourced_by, 4, 1);
                      setAcceptModel({
                        query_id: query?.id || "",
                        payment_id: "",
                        total_margin: 0,
                      });
                      setModel({
                        query_id: query?.id || "",
                        id: "",
                        payment_date: moment(new Date()).format("YYYY-MM-DD"),
                        amount: "",
                        payment_mode: "",
                        is_split: 0,
                      });
                      setVisible(false);
                    }
                  }}
                >
                  <Lucide icon="User" className="stroke-[1.3] w-4 h-4 mr-3" />
                  Assign to agent
                </Button>
              )} */}
              <Button
                type="submit"
                variant="primary"
                className="ml-auto group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                loading={submitting}
              >
                <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
                {canMarkAccepted && isView ? "Mark as completed" : "Submit"}
              </Button>
            </div>
          )}
        </form>
      )}
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries, signTemplates, users }: RootState) => ({
  query: queries?.detail || ({} as any),
  templates: signTemplates?.data || [],
  users: users?.data || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  setAlert: (payload: any) => dispatch(setAlert(payload)),
  onCreate: (payload: any) => dispatch(createPayment(payload)),
  onMarkAccepted: (payload: any) => dispatch(acceptPayment(payload)),
  fetchTemplates: (payload: any) => dispatch(fetchSignTemplates(payload)),
  fetchUsers: (payload: any) => dispatch(fetchUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
