import { useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable";
import { tableFields } from "./constants";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { fetchQueries } from "src/stores/query";
import { queryDataProps } from "src/interfaces";
import { customerAPIUrls } from "src/common/apiActions";

function Component({ id }: { id: string }) {
  const navigate = useNavigate();

  const onQueryClick = (item: queryDataProps) => {
    navigate("/query-detail/" + item?.id, { state: "customer" });
  };

  return (
    <>
      <DataTable
        showColors={false}
        // tableHeading="Queries"
        columns={tableFields((item: queryDataProps) => {
          onQueryClick(item);
        })}
        url={`${customerAPIUrls?.getQueries}/${id}`}
        // statusOptions={[{ label: 'Open', val: "0" }, { label: 'Closed', val: '9' }]}
      />
    </>
  );
}

const mapStateToProps = ({ queries }: RootState) => ({
  queries,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchQueries(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
