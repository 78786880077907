import { store } from "src/stores";
import {
  notificationAPIUrls
} from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchNotificationsService = async (loadMore: boolean = false) => {
  const token = store?.getState()?.authUser?.token;
  if (!token) {
    return
  }
  try {
    const previous = store?.getState()?.notifications;
    const currentPage = previous?.current_page || 1;
    let responseData = await http.Get(
      `${notificationAPIUrls?.get}?page=${loadMore ? currentPage + 1 : ''}`
    );
    responseData = {
      ...responseData,
      isAllLoaded: responseData?.data?.length < 10,
      isNew: responseData?.total > previous?.total && responseData?.data?.some(ele => ele?.read == 0),
      data: loadMore ? [...new Set([...(previous?.data || []), ...responseData?.data])] : responseData?.data,
    };
    return responseData;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createNotificationService = async (payload: any) => {
  try {
    const data = await http.Post(notificationAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateNotificationService = async (payload: any) => {
  try {
    const data = await http.Post(notificationAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
