import { onMessage, getToken } from "firebase/messaging";
import { messaging } from ".";
import { store } from "../stores";
import { setAlert } from "src/stores/basicSlices";
import sound from "./notification-sound.mp3";
import { fetchNotifications } from "src/stores/notification";

export const firebaseToken = async () => {
  try {
    const deviceToken = localStorage.getItem("deviceToken");
    if (Notification.permission == "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      localStorage.setItem("deviceToken", token);
      return token;
    } else if (Notification.permission == "denied") {
      localStorage.removeItem("deviceToken");
      return null;
    } else {
      return deviceToken;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const usePushNotification = () => {
  function requestPermission() {
    Notification?.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then(() => firebaseToken().then((token) => {}));
        }
      }
    });
  }

  requestPermission();
  const notificationHandler = () => {
    onMessage(messaging, (payload) => {
      new Audio(sound).play();
      store?.dispatch(fetchNotifications());
      store?.dispatch(
        setAlert({
          text: payload.notification?.body || "",
          title: payload?.notification?.title,
          alertType: "Snackbar",
          type: "success",
        })
      );
    });
  };
  notificationHandler();
};
