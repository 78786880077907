import React, { useEffect, useState } from "react";
import { Slideover } from "../../base-components/Headless";
import Lucide from "../../base-components/Lucide";
import Button from "src/base-components/Button";
import DataTable from "../DataTable";
import Table from "src/base-components/Table";
import UpdateRequestModal from "src/pages/Queries/QueryDetail/UpdateRequestModal";
import AddCustomLog from "src/pages/Queries/QueryDetail/ActivityLogs/AddCustomLog";
import {
  authUserProps,
  createUpdateQueryActivityLogProps,
  queryDataProps,
} from "src/interfaces";
import LoadingIcon from "src/base-components/LoadingIcon";

interface componentProps {
  headerTitle?: string;
  visible: boolean;
  onClose?: (value: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  style?: any;
  size?: "xl" | "lg" | "md" | "sm";
  backdrop?: boolean;
  headerRight?: React.ReactNode;
  data: any;
  columns: any;
  authUser: authUserProps;
  queryId: string;
  queryNumber?: string;
}

function OverlappingSlideOver(props: componentProps) {
  const {
    visible,
    onClose = () => {},
    headerTitle,
    children,
    className = "",
    style = {},
    size = "md",
    backdrop = true,
    headerRight = "",
    data,
    columns,
    authUser,
    queryId,
    queryNumber,
  } = props;

  const [openOverlapingModal, setOpenOverlapingModal] =
    useState<boolean>(false);
  const [customLogModal, setCustomLogModal] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [selectedQuery, setSelectedQuery] = useState<queryDataProps>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [customLogData, setCustomLogData] =
    useState<createUpdateQueryActivityLogProps>({
      query_id: queryId,
      title: "",
      description: "",
      access_query: "",
    });

  useEffect(() => {
    if (data && data?.length > 0) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [data]);

  const onRowClick = (query: any) => {
    if (query) {
      setSelectedQuery(query);

      if (authUser?.id !== query?.sourced_by) {
        const payload: any = {
          ...customLogData,
          access_query: query?.id,
        };

        setCustomLogData(payload);
        setCustomLogModal(authUser?.id !== query?.sourced_by);
        setIsViewOnly(authUser?.id !== query?.sourced_by);
      } else {
        setOpenOverlapingModal(true);
      }
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setOpenOverlapingModal(true);
    }
  }, [isSubmitted]);

  const handleOverLapingClose = () => {
    setOpenOverlapingModal(false);
    setIsSubmitted(false);
    setCustomLogData({
      query_id: queryId,
      title: "",
      description: "",
      access_query: "",
    });
  };

  return (
    <Slideover size="xl" open={visible} onClose={backdrop ? onClose : () => {}}>
      <Slideover.Panel className="w-full rounded-[0.55rem_0_0_0.55rem/1.1rem_0_0_1.1rem]">
        <a
          href=""
          className="focus:outline-none hover:bg-white/10 bg-white/5 transition-all hover:rotate-180 absolute inset-y-0 left-0 right-auto flex items-center justify-center my-5 -ml-[60px] sm:-ml-[105px] border rounded-full text-white/90 w-8 h-8 sm:w-14 sm:h-14 border-white/90 hover:scale-105"
          onClick={(e) => {
            e.preventDefault();
            onClose(false);
          }}
        >
          <Lucide className="w-8 h-8 stroke-[1]" icon="X" />
        </a>
        <Slideover.Title className="px-6 py-5">
          <h2 className="mr-auto text-base font-medium">
            {headerTitle || "Modal"}
          </h2>
          {headerRight}
        </Slideover.Title>
        {isLoading ? (
          <LoadingIcon
            icon="oval"
            color={"black"}
            className="w-5 h-5 text-center mx-auto mt-10"
          />
        ) : (
          <Slideover.Description
            style={style}
            className={`px-6 py-4 ${className}`}
          >
            <Table className="w-full relative bg-white">
              <Table.Thead>
                <Table.Tr>
                  {columns?.map((column, index) =>
                    (column?.hide && typeof column?.hide != "function") ||
                    !column?.title ? null : (
                      <Table.Th
                        key={index}
                        className="py-4 font-medium bg-inherit text-slate-500 text-start"
                      >
                        {column?.title}
                      </Table.Th>
                    )
                  )}
                </Table.Tr>
              </Table.Thead>
              {data && data?.length === 0 ? (
                <div className="w-full py-10 text-center bg-inherit">
                  <div className="absolute left-0 right-0 font-medium text-slate-500">
                    No matching records found.
                  </div>
                </div>
              ) : (
                <Table.Tbody className=" bg-inherit">
                  {data?.map((item: any, rowIndex) => (
                    <Table.Tr
                      onClick={() => onRowClick(item)}
                      className="cursor-pointer relative"
                      key={rowIndex}
                    >
                      {columns?.map((column, columnIndex) => {
                        const hide =
                          typeof column?.hide == "function"
                            ? column?.hide(item)
                            : column?.hide;
                        return hide ? null : (
                          <Table.Td
                            key={columnIndex}
                            className="py-4 border-dashed dark:bg-darkmode-600"
                          >
                            {column.cell
                              ? column.cell(item)
                              : item[column.field]}
                          </Table.Td>
                        );
                      })}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              )}
            </Table>
          </Slideover.Description>
        )}
      </Slideover.Panel>
      <Slideover
        size="lg"
        open={openOverlapingModal}
        onClose={(e) => handleOverLapingClose()}
      >
        <Slideover.Panel>
          <a
            href=""
            className="focus:outline-none hover:bg-white/10 bg-white/5 transition-all hover:rotate-180 absolute inset-y-0 left-0 right-auto flex items-center justify-center my-5 -ml-[60px] sm:-ml-[105px] border rounded-full text-white/90 w-8 h-8 sm:w-14 sm:h-14 border-white/90 hover:scale-105"
            onClick={(e) => {
              e.preventDefault();
              handleOverLapingClose();
            }}
          >
            <Lucide className="w-8 h-8 stroke-[1]" icon="X" />
          </a>
          <Slideover.Title className="p-5">
            <h2 className="mr-auto text-base font-medium">
              {selectedQuery && selectedQuery?.query_no}
            </h2>
            <div className="items-center text-slate-900 sm:col-span-1 md:col-span-2 hidden sm:flex ml-5">
              {selectedQuery?.stage_text != selectedQuery?.status_text && (
                <span className="px-1.5 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                  {selectedQuery?.stage_text}
                </span>
              )}
              <span className="ml-1 px-1.5 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                {selectedQuery?.status_text}
              </span>
            </div>
          </Slideover.Title>
          <Slideover.Description className="">
            <div className="flex gap-x-10 flex-col items-start mt-0 gap-y-1 sm:mt-5 sm:mb-3 lg:mt-2 xl:mb-0">
              <div className="items-center text-slate-900 sm:col-span-1 md:col-span-2 sm:hidden ml-auto mb-1 flex">
                {selectedQuery?.stage_text != selectedQuery?.status_text && (
                  <span className="px-2 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                    {selectedQuery?.stage_text}
                  </span>
                )}
                <span className="ml-3 px-2 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                  {selectedQuery?.status_text}
                </span>
              </div>

              <div className="flex flex-row justify-between gap-x-20">
                <div className="">
                  <div className="text-slate-500">Source:</div>
                  <div className="mt-1.5  font-medium text-slate-600">
                    {selectedQuery?.source}
                  </div>
                </div>

                <div className="">
                  <div className="text-slate-500 ">Type:</div>
                  <div className="mt-1.5 font-medium  text-slate-600 w-max">
                    {selectedQuery?.query_type_text}
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between mt-10 gap-x-16">
                <div className="">
                  <div className="text-slate-500 ">Trip Type:</div>
                  <div className="mt-1.5 font-medium text-slate-600 ">
                    {selectedQuery?.trip_type_text}
                  </div>
                </div>

                <div className=" ">
                  <div className="text-slate-500"># PAX:</div>
                  <div className="mt-1.5 w-max ">
                    <div className="text-slate-500">
                      {selectedQuery?.no_of_adults} Adult,{" "}
                      {selectedQuery?.no_of_children} Children,{" "}
                      {selectedQuery?.no_of_infants} Infant
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selectedQuery?.queries_status_logs?.length > 0 && (
              <div className="max-w-[50%] min-w-[50%] mt-5">
                <div className="text-slate-500">Initial Remarks:</div>
                <div className="mt-1.5 w-max">
                  <div className="font-medium text-slate-600">
                    {selectedQuery?.queries_status_logs[0]?.description}
                  </div>
                </div>
              </div>
            )}
          </Slideover.Description>
        </Slideover.Panel>
      </Slideover>
      <AddCustomLog
        setIsSubmitted={setIsSubmitted}
        visible={customLogModal}
        setVisible={setCustomLogModal}
        model={customLogData}
        setModel={setCustomLogData}
        isViewOnly={isViewOnly}
        fromDetail={true}
        queryNumber={selectedQuery?.query_no}
      />
    </Slideover>
  );
}

export default OverlappingSlideOver;
