import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { notificationsProps } from "../../interfaces";

const initialState: any = [] as any;

export const queuesSlice = createSlice({
  name: "queues",
  initialState,
  reducers: {
    setQueus: (state, action: PayloadAction<any[]>) => {
      if (action.payload.length === 0) {
        return [];
      }
      const existingIds = new Set(state.map((item) => item.id));
      const newItems = action.payload.filter(
        (item) => !existingIds.has(item.id)
      );

      const updatedState = [...state, ...newItems];

      return updatedState.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    },

    removeQueus: () => {
      return initialState;
    },
    removeSingleQueue: (state, action: PayloadAction<any>) => {
      return state.filter((item) => item.query_id !== action.payload);
    },
  },
});

export const { setQueus, removeQueus, removeSingleQueue } = queuesSlice.actions;

export const selectQueus = (state: RootState) => {
  return state.queues;
};

export default queuesSlice.reducer;
