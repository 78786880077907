import SlideoverModal from "src/components/SlideoverModal";
import { baseUrl } from "src/services/http";

export const Component = ({
    visible,
    setVisible,
    contractUrl
}: {
    visible: boolean;
    setVisible: Function;
    contractUrl:string
}) => {

    return (
        <SlideoverModal
            size="xl"
            visible={visible}
            onClose={() => {
                setVisible(false);
            }}
            headerTitle={`Contract`}
        >
            <iframe src={`${baseUrl}/${contractUrl}`} width="100%" height="100%" />
        </SlideoverModal>
    );
};

export default Component;
