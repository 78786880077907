import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { signTemplatesProps } from "../../interfaces";

const initialState: signTemplatesProps = {} as any;

export const signTemplatesSlice = createSlice({
  name: "signTemplates",
  initialState,
  reducers: {
    setSignTemplates: (state, action: PayloadAction<signTemplatesProps>) => {
      return { ...state, ...action.payload };
    },
    removeSignTemplates: () => {
      return initialState;
    },
  },
});

export const { setSignTemplates, removeSignTemplates } = signTemplatesSlice.actions;

export const selectSignTemplates = (state: RootState) => {
  return state.signTemplates;
};

export default signTemplatesSlice.reducer;
