import React, { useEffect, useState } from "react";
import SlideoverModal from "../../../components/SlideoverModal";
import {
  FormCheck,
  FormInput,
  FormLabel,
} from "../../../base-components/Form";
import FormInputParent from "../../../base-components/FormInputParent/FormInputParent";
import {
  createUpdateRoleProps,
  permissionProps,
} from "src/interfaces";
import { connect } from "react-redux";
import { createRole, updateRole } from "src/stores/role";
import { fetchPermissions } from "src/stores/permission";
import { RootState } from "src/stores";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import {
  additionalPermissionAdd,
  additionalPermissionRemove,
  userTypes,
} from "./constants";
import LoadingIcon from "src/base-components/LoadingIcon";
import ReactSelect from "src/base-components/ReactSelect";

const Component = ({
  visible,
  setVisible,
  model = {},
  setModel = () => { },
  fetchPermissions,
  permissions,
  onCreate,
  onUpdate,
  onCreateUpdateComplete,
}: {
  visible: boolean;
  setVisible: Function;
  model?: createUpdateRoleProps;
  setModel?: (payload: createUpdateRoleProps | any) => void;
  fetchPermissions: Function;
  permissions: permissionProps;
  onCreate: (payload: createUpdateRoleProps) => void;
  onUpdate: (payload: createUpdateRoleProps) => void;
  onCreateUpdateComplete: Function;
}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const permissionList = permissions?.data;
  useEffect(() => {
    if (visible) {
      setLoading(true)
      fetchPermissions({ type: 1, callback: () => { setLoading(false) } });
    }
  }, [visible]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    let payload: any = model;
    if (payload?.id) {
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setSubmitting(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setSubmitting(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };

  return (
    <SlideoverModal
      size="md"
      headerTitle={model?.id ? "Update Role" : "Add Role"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="h-full w-full flex flex-col">
        <div className="mt-2 ">
          <FormLabel>Role Name</FormLabel>
          <div className="w-full">
            <FormInput
              value={model?.name}
              required
              type="text"
              onChange={(event) => {
                setModel({
                  ...model,
                  name: event?.target?.value,
                });
              }}
            />
          </div>
        </div>
        <div className="mt-2 ">
          <FormLabel>User Type</FormLabel>
          <div className="w-full">
            <ReactSelect
              options={userTypes}
              onChange={(_: any, value) => {
                setModel((previous) => {
                  return {
                    ...previous,
                    user_type: value,
                  } as any
                });
              }}
              value={model?.user_type}
              placeholder="Select Type"
            />
          </div>
        </div>
        <div className="w-full mt-4">
          <FormInput
            value={search}
            type="text"
            placeholder="Search..."
            onChange={(event) => {
              setSearch(event?.target?.value);
            }}
          />
        </div>
        <div className="py-4 h-[61vh] my-2 overflow-y-auto">
          {loading ? <div className="flex w-[100%] justify-center py-12">
            <LoadingIcon
              color="#64748b"
              icon="oval"
              className="w-8 h-8 mx-auto"
            />
          </div> :
            <div className="p-0.5">
              {!search && <div className="mb-6">
                <FormCheck.Input
                  required={model?.permissions?.length == 0}
                  type="checkbox"
                  onChange={() => {
                    let permissionsArr: any[] = []
                    if (permissionList?.length !== model?.permissions?.length) {
                      permissionsArr = permissionList?.map(item => { return { id: item?.id, permission: 'Write' } })
                    }
                    setModel({
                      ...model,
                      permissions: permissionsArr,
                    });
                  }}
                  checked={model?.permissions?.length == permissionList?.length}
                />
                <span className="ml-2">All</span>
              </div>}
              {
                permissionList?.filter(ele => search ? ele?.name?.toLowerCase()?.includes(search?.toLowerCase()) : ele != null)?.map((item, index) => {
                  // const permissionValue =
                  //   model?.permissions?.find((ele) => ele?.id == item?.id)
                  //     ?.permission ||
                  //   model?.permissions?.find((ele) => ele?.id == item?.id)?.pivot
                  //     ?.permission ||
                  //   "";
                  return (
                    <div className="my-2">
                      <FormInputParent
                        key={index}
                        label={
                          (
                            <div>
                              <FormCheck.Input
                                required={model?.permissions?.length == 0}
                                type="checkbox"
                                onChange={() => {
                                  let permissionItem = model?.permissions?.find(
                                    (ele) => ele?.id === item?.id
                                  );
                                  let permissionsArr = model?.permissions || [];
                                  if (permissionItem) {
                                    permissionsArr = permissionsArr?.filter(
                                      (ele) => ele?.id !== permissionItem?.id
                                    );
                                    const permissionWith =
                                      additionalPermissionRemove[item?.backend_route];
                                    if (permissionWith?.length > 0) {
                                      const additionalPermissions = permissionList
                                        ?.filter((ele) =>
                                          permissionWith.includes(ele?.backend_route)
                                        )
                                        ?.map((ele) => ele?.id);
                                      if (additionalPermissions?.length > 0) {
                                        permissionsArr = permissionsArr?.filter(
                                          (ele) =>
                                            !additionalPermissions?.includes(ele?.id)
                                        );
                                      }
                                    }
                                  } else {
                                    permissionsArr = [
                                      ...permissionsArr,
                                      { id: item?.id, permission: "Write" as string },
                                    ];
                                    const permissionWith =
                                      additionalPermissionAdd[item?.backend_route];
                                    if (permissionWith?.length > 0) {
                                      const additionalPermissions =
                                        permissionList?.filter((ele) =>
                                          permissionWith.includes(ele?.backend_route)
                                        );
                                      if (additionalPermissions?.length > 0) {
                                        additionalPermissions?.map(
                                          (additionalPermission) => {
                                            const additionalPermissionObj = {
                                              id: additionalPermission.id,
                                              permission: "Write" as string,
                                            };
                                            permissionsArr?.push(
                                              additionalPermissionObj
                                            );
                                          }
                                        );
                                      }
                                    }
                                  }
                                  setModel({
                                    ...model,
                                    permissions: permissionsArr,
                                  });
                                }}
                                checked={model?.permissions?.some(
                                  (ele) => ele?.id == item?.id
                                )}
                              />
                              <span className="ml-2">{item?.nav_title}</span>
                            </div>
                          ) as any
                        }
                        description=""
                      // childrens={
                      //   <div className="flex flex-row-reverse justify-end  w-full  md:flex-row-reverse sm:flex-row-reverse md:justify-end  ">
                      //     <FormSelect
                      //       disabled={!model?.permissions?.some(ele => ele?.id == item?.id)}
                      //       onChange={(e) => {
                      //         let permissionItem = model?.permissions?.find(ele => ele?.id === item?.id)
                      //         let permissionsArr = model?.permissions?.filter(ele => ele?.id !== permissionItem?.id) || []
                      //         if (permissionItem) {
                      //           permissionItem.permission = e?.target?.value as string
                      //           permissionsArr = [...permissionsArr, permissionItem]
                      //         } else {
                      //           permissionsArr = [...permissionsArr, { id: item?.id, permission: e?.target?.value as string }]
                      //         }
                      //         setModel({
                      //           ...model,
                      //           permissions: permissionsArr
                      //         })
                      //       }}
                      //       value={permissionValue}
                      //       required={model?.permissions?.some(ele => ele?.id == item?.id)}
                      //       name="permissionName"
                      //       className="w-4/3  sm:w-4/5 pt-3"
                      //     >
                      //       {[
                      //         { label: "Read/Write?", value: '', },
                      //         { label: "Read", value: 'Read', },
                      //         { label: "Write", value: 'Write', }
                      //       ]?.map((option, optionIndex) => <option key={optionIndex} value={option?.value}>{option?.label}</option>)}
                      //     </FormSelect>
                      //   </div>
                      // }
                      />
                    </div>
                  );
                })}
            </div>}
        </div>
        <div className="absolute bottom-0 right-0 py-5 px-7">
          <Button
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
            type="submit"
            disabled={submitting || loading || permissionList?.length == 0}
            loading={submitting}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            Submit
          </Button>
        </div>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ permissions }: RootState) => ({ permissions });

const mapDispatchToProps = (dispatch: any) => ({
  fetchPermissions: (payload: any) => dispatch(fetchPermissions(payload)),
  onCreate: (payload: any) => dispatch(createRole(payload)),
  onUpdate: (payload: any) => dispatch(updateRole(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
