import {
  CREATE_NOTIFICATION,
  FETCH_NOTIFICATION_LIST,
  UPDATE_NOTIFICATION,
} from "./types";

export const fetchNotifications = (payload?:any) => {
  return {
    type: FETCH_NOTIFICATION_LIST,
    payload
  };
};

export const createNotification = (payload: any) => {
  return {
    type: CREATE_NOTIFICATION,
    payload
  };
};

export const updateNotification = (payload: any) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload
  };
};
