import { connect } from "react-redux";
import Button from "src/base-components/Button";
import {
  FormHelp,
  FormLabel,
  FormSelect,
  FormTextarea,
} from "src/base-components/Form";
import React, { useEffect, useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import { queryDataProps, viewPermissionActionProps } from "src/interfaces";
import { RootState } from "src/stores";
import { viewPermissionAction } from "src/stores/query";
import { useNavigate } from "react-router-dom";

export type forType = 1 | 2;
const Component = ({
  query,
  onAssign,
  visible,
  setVisible,
}: {
  query: queryDataProps | undefined;
  onAssign: (payload: viewPermissionActionProps) => void;
  visible: boolean;
  setVisible: Function;
}) => {
  const query_id = query?.id || "";
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [model, setModel] = useState<viewPermissionActionProps>({
    id: query?.id || "",
    for: 0,
    remark: "",
  });
  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };
  useEffect(() => {
    if (visible) {
      setModel({ id: query?.id || "", for: 0, remark: "" });
    }
  }, [visible]);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const payload: viewPermissionActionProps = {
      id: query_id || model?.id,
      remark: model?.remark,
      for: model?.for,
    };
    setSubmitting(true);
    onAssign({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
          navigate(-1);
        }
        setSubmitting(false);
      },
    } as any);
  };

  return (
    <SlideoverModal
      size="lg"
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle="Assign Update Permission"
    >
      <form className="h-[100%] flex flex-col" onSubmit={handleSubmit}>
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block">
          Query: <span className="text-primary"># {query?.query_no}</span>
        </div>
        <div className="flex-1 w-full mt-3 mb-10 xl:mt-0 p-7">
          <FormLabel>Action</FormLabel>
          <FormSelect
            className="flex-1 w-full mt-3 xl:mt-0"
            id="for"
            name="for"
            required
            value={model?.for}
            onChange={handleSelectChange as any}
          >
            <option value={""}>--Select--</option>
            {[
              { label: "Accept", value: 1 },
              { label: "Deny", value: 2 },
            ]?.map((option, optionKey) => (
              <option key={optionKey} value={option?.value}>
                {option.label}
              </option>
            ))}
          </FormSelect>
          <div className="mt-10">
            <div className="flex-1 w-full mt-10 xl:mt-0">
              <FormLabel>Remarks</FormLabel>
              <FormTextarea
                rows={5}
                value={model?.remark || ""}
                name="remark"
                required
                onChange={handleSelectChange as any}
              />
              <FormHelp className="text-right">
                Maximum characters: {model?.remark ? model.remark.length : 0}
                /2000
              </FormHelp>
            </div>
          </div>
        </div>

        <Button
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
});

const mapDispatchToProps = (dispatch: any) => ({
  onAssign: (payload: any) => dispatch(viewPermissionAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
