import React from "react";

const Card = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  return (
    <div
      onClick={props?.onClick}
      className={`border border-slate-400 before:border before:border-slate-400 shadow-md relative ${
        !props?.className?.includes("p-") ? "p-3" : ""
      } box box--stacked ${props?.onClick ? "cursor-pointer" : ""} ${
        props?.className || ""
      }`}
    >
      {props?.children}
    </div>
  );
};

export default Card;
