import moment from "moment";
import { Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import { TableColumnType } from "src/components/DataTable";
import { dateParser } from "src/helpers";
import { customerDataProps } from "src/interfaces";

export const columns = ({
  onViewClick = () => { },
  onEditClick,
}: {
  onViewClick: Function
  onEditClick?: Function | undefined;
}) => {
  return [
    {
      title: "Customer #",
      field: "customer_id",
      cell: (row: customerDataProps) => {
        return (
          <div
            className="flex items-center text-[#075985] cursor-pointer"
            onClick={() => onViewClick(row)}
          >
            {row?.customer_id || "N/A"}
          </div>
        );
      },
    },
    {
      title: "Name",
      field: "fullname",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Phone",
      field: "phone",
      cell: (row) => `${row?.country_code} ${row?.phone}`,
    },
    {
      title: "Created at",
      field: "created_at",
      cell: (row) => dateParser(row?.created_at, "DD-MM-YYYY"),
    },
    {
      title: "Action",
      field: "action",
      hide: !onEditClick,
      cell: (row: customerDataProps) => {
        if (!onEditClick) return null;
        return (
          <Menu>
            <Menu.Button
              onClick={() => {
                onEditClick && onEditClick(row);
              }}
              className="w-100 h-5 text-slate-500"
            >
              <Lucide icon="Pencil" className="w-4 h-4 mr-2 text-primary" />
            </Menu.Button>
          </Menu>
        );
      },
    },
  ] as TableColumnType<any>[];
};
