import { authUserProps } from "src/interfaces";
import { icons } from "../../base-components/Lucide";

export interface Menu {
  icon: keyof typeof icons;
  title: string;
  badge?: number;
  pathname?: string;
  subMenu?: Menu[];
  ignore?: boolean;
}

export interface SideMenuState {
  menu: Array<Menu | string>;
}

const allMenus: Array<Menu | string> = [
  {
    icon: "GaugeCircle",
    pathname: "/",
    title: "Dashboard",
  },
  {
    icon: "ShieldAlert",
    pathname: "/actionable",
    title: "My Actionable",
  },
  {
    icon: "ActivitySquare",
    pathname: "/queries",
    title: "Queries",
  },
  {
    icon: "Mail",
    pathname: "/email-templates",
    title: "Email Templates",
  },
  {
    icon: "Users",
    pathname: "/users",
    title: "Users",
  },
  {
    icon: "Users",
    pathname: "/customers",
    title: "Customers",
  },
];

export const menus = (authUser: authUserProps) => {
  let permissions = authUser?.role?.permissions;
  let permittedMenus: Array<Menu | string> = [] as any;
  if (authUser?.token) {
    if (authUser?.user_type == "Superadmin") {
      permittedMenus?.push(
        {
          icon: "KeySquare",
          pathname: "/",
          title: "Permissions",
        },
        {
          icon: "Key",
          pathname: "/roles",
          title: "Roles",
        },
        {
          icon: "Users",
          pathname: "/users",
          title: "Users",
        }
      );
    } else if (authUser?.user_type == "Admin") {
      permittedMenus?.push(...allMenus);
    } else {
      permittedMenus?.push(
        {
          icon: "GaugeCircle",
          pathname: "/",
          title: "Dashboard",
        },
        ...allMenus?.filter((ele) => {
          return permissions?.some(
            (p) => typeof ele !== "string" && p?.frontend_route == ele?.pathname
          );
        })
      );
    }
  }
  return permittedMenus;
};
