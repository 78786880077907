// Permission Actions /
export const authAPIUrls = {
  login: "/login",
  userPermissions: "/user/permissions",
};

// Permission Actions //
export const permissionAPIUrls = {
  get: "/permissions/get",
  create: "/permissions/create",
  update: "/permissions/update",
};

// Role Actions //
export const roleAPIUrls = {
  get: "/roles/get",
  create: "/roles/create",
  update: "/roles/update",
};

// Location Actions //
export const locationAPIUrls = {
  get: "/locations/get",
  create: "/locations/create",
  update: "/locations/update",
};

// Notification Actions //
export const notificationAPIUrls = {
  get: "/notifications/get",
  create: "/notifications/create",
  update: "/notifications/update",
};

// User Actions //
export const userAPIUrls = {
  get: "/users/get",
  getAll: "/users/get-all",
  getUsers: "/users/get-all-users",
  getAgents: "/users/getAgents",
  create: "/users/create",
  update: "/users/update",
};

// Customer Actions //
export const customerAPIUrls = {
  get: "/customers/get",
  getAll: "/customers/get-all",
  getQueries: "/customers/queries",
  create: "/customers/create",
  update: "/customers/update",
  details: "/customers/customer-details",
};
// Query Actions //
export const queryAPIUrls = {
  get: "/queries/get",
  actionable: "/queries/actionable",
  search: "/queries/global",
  getById: "/queries/detail",
  create: "/queries/create",
  update: "/queries/update",
  getActivities: "/queries/get-activity-log",
  createActivity: "/queries/create-activity-log",
  assign: "/queries/assign-to",
  forceClose: "/queries/force-close",
  viewPermission: "/queries/view-permission-action",
  cancellation: "/queries/cancellation-request",
  changeDate: "/queries/change-date-request",
  rescheduledByAirline: "/queries/rescheduled-by-airline-request",
  all: "/queries/get?stage=0",
  callBack: "/callback",
  userQueries: "/queries/customer_queries",
  getQueues: "/queries/queues/list",
  updateQueue: "/queries/assignFromQueue",
  queriesQue: "/queries/queues",
};

// Quotation Actions //
export const quotationAPIUrls = {
  create: "/quotations/create",
  accept: "/quotations/mark-accepted",
  send: "/quotations/send",
  uploadTicket: "/quotations/upload-ticket",
  sendTicket: "/quotations/send-ticket",
  deleteTicket: "/quotations/delete-ticket",
  fetchTicket: "/quotations/getQuotationTicket",
};

// Payment Actions //
export const paymentAPIUrls = {
  create: "/payments/create",
  initiateCancellation: "/payments/initiate-cancellation",
  initiateRefund: "/payments/initiate-refund",
  completeRefund: "/payments/complete-refund",
  accept: "/payments/mark-completed",
  getById: "/payments/get",
  getSignTemplates: "/payments/templates",
};

// Passenger Actions //
export const passengerAPIUrls = {
  get: "/passengers/get",
  create: "/passengers/create",
  update: "/passengers/update",
};

// Dashbooard Actions //
export const dashboardAPIUrls = {
  get: "/dashboard",
  globalSearch: "/global-search/search",
};

// Email Template Actions //
export const emailTemplateAPIUrls = {
  get: "/templates/get",
  create: "/templates/create",
  update: "/templates/update",
  delete: "/templates/delete",
};
