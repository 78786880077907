import {
  CREATE_LOCATION,
  FETCH_LOCATION_LIST,
  UPDATE_LOCATION,
} from "./types";

export const fetchLocations = (payload?:any) => {
  return {
    type: FETCH_LOCATION_LIST,
    payload
  };
};

export const createLocation = (payload: any) => {
  return {
    type: CREATE_LOCATION,
    payload
  };
};

export const updateLocation = (payload: any) => {
  return {
    type: UPDATE_LOCATION,
    payload
  };
};
