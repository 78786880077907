import Lucide from "../../base-components/Lucide";
import { Menu } from "../../base-components/Headless";
import { Tab } from "../../base-components/Headless";
import Table from "../../base-components/Table";
import { useState, useRef, useEffect } from "react";
import Button from "../../base-components/Button";
import { Mail, Phone, Shield } from "lucide-react";
import {
  ActionableQueries,
  dashboardProps,
} from "src/interfaces/dashboard.interfaces";
import { fetchDashboards } from "src/stores/dashboard";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { stageOptions } from "./constants";
import moment, { Moment } from "moment";
import CustomTippy from "src/base-components/customTooltip";
import LoadingIcon from "../../base-components/LoadingIcon";
import Card from "src/components/Card";
import { useNavigate } from "react-router-dom";
import { dateParser, getTableColorFromStatus, isManager } from "src/helpers";
import { linkEnum } from "src/themes/Razor/QuickSearch/constants";
import DatePicker from "src/base-components/DatePicker";
import { DateValueType } from "react-tailwindcss-datepicker";

interface payloadProps {
  stage: string;
  fromDate: string;
  toDate: string;
}
function Component({
  onFetch,
  dashboards,
}: {
  onFetch: (payload: any) => void;
  dashboards: dashboardProps;
}) {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [generalReportFilter, setGeneralReportFilter] =
    useState<DateValueType>();

  const [selectedButton, setSelectedButton] = useState("WalkIn");
  const onQueryClick = (item: ActionableQueries) => {
    navigate("/query-detail/" + item?.id);
  };
  const handleButtonClick = (buttonName: any) => {
    setSelectedButton(buttonName);
  };
  const [model, setModel] = useState<payloadProps>({
    stage: "",
    fromDate: `${new Date().getFullYear()}-${new Date()?.getMonth()}-${new Date()?.getDate()}`,
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  useEffect(() => {
    handleFetch();
  }, [model]);

  const handleFetch = () => {
    setLoading(true);
    onFetch({
      ...model,
      callback: (error: boolean, response: any) => {
        setLoading(false);
        if (!error) {
        }
      },
    });
  };

  const handleDateChange = (value: any) => {
    if (value?.startDate && value?.endDate) {
      setGeneralReportFilter(value);
      setModel((previous) => {
        return {
          ...previous,
          fromDate: value?.startDate,
          toDate: value?.endDate,
        };
      });
    }
  };

  const [buttonText, setButtonText] = useState("All");
  const handleMenuClick = (menuItem: any) => {
    setButtonText(menuItem?.label);
    setModel((previous) => {
      return { ...previous, stage: menuItem?.value };
    });
  };

  const handleSeeAll = () => {
    navigate(linkEnum["all"]);
  };
  const currentDate: any = moment(new Date());
  return (
    <div className="grid grid-cols-12 gap-y-10 gap-x-6">
      <div className="col-span-12">
        <Tab.Group>
          <div className="flex flex-col md:h-10 gap-y-3 md:items-center md:flex-row">
            <div className="text-base font-medium group-[.mode--light]:text-primary/500">
              Dashboard
            </div>
            <div className="flex flex-col sm:flex-row gap-x-3 gap-y-2 md:ml-auto">
              <div className="relative">
                <DatePicker
                  value={
                    generalReportFilter?.startDate ||
                    generalReportFilter?.endDate
                      ? generalReportFilter
                      : (undefined as any)
                  }
                  separator="-"
                  inputId="date-picker"
                  maxDate={currentDate}
                  placeholder="Select date range"
                  showFooter={true}
                  onChange={(value) => {
                    handleDateChange(value);
                  }}
                />
              </div>
            </div>
          </div>
          <Tab.Panels className="mt-3.5 border-gray-400 before:border-gray-400 shadow-md box box--stacked">
            <Tab.Panel className="flex flex-col xl:flex-row gap-2 p-1.5 leading-relaxed">
              <div className="grid w-full grid-cols-4 gap-2">
                <div className="col-span-4 sm:col-span-2 xl:col-span-1 bg-gradient-to-b from-secondary/90 to-primary/[0.85] flex-1 p-5 border-0 relative rounded-[0.6rem] box border-slate-200/60 bg-slate-50 overflow-hidden before:content-[''] before:w-full before:h-[130%] before:bg-gradient-to-b before:from-black/[0.15] before:to-transparent before:absolute before:right-0 before:top-0 before:rotate-45 before:-mr-[62%]">
                  <div className="flex gap-2 items-start">
                    <span className="flex items-center justify-center w-16 h-16 border rounded-full border-primary/10 bg-primary/10">
                      <Lucide
                        icon="Files"
                        className="w-6 h-6 text-white fill-primary/10"
                      />
                    </span>
                    <div>
                      <div className="text-white text-2xl font-medium mt-2">
                        Queries
                      </div>
                      <div className="mt-3 flex gap-1">
                        <Button
                          className={`flex w-10 h-10 border rounded-full focus:ring-0 ${
                            selectedButton === "WalkIn"
                              ? "border-white/10 bg-white/20"
                              : "border-white/10 bg-white/5"
                          } `}
                          onClick={() => handleButtonClick("WalkIn")}
                        >
                          <CustomTippy
                            mode="div"
                            content="Walk-In"
                            theme="light"
                            children={
                              <Shield
                                size={20}
                                className={
                                  selectedButton === "WalkIn"
                                    ? "fill-white/5"
                                    : "fill-white/40"
                                }
                                color={
                                  selectedButton === "WalkIn" ? "white" : ""
                                }
                              />
                            }
                          />
                        </Button>
                        <Button
                          className={`flex w-10 h-10 border rounded-full focus:ring-0 ${
                            selectedButton === "Phone"
                              ? "border-white/30 bg-white/20"
                              : "border-white/10 bg-white/5"
                          }`}
                          onClick={() => handleButtonClick("Phone")}
                        >
                          <CustomTippy
                            mode="div"
                            content="Phone-Call"
                            theme="light"
                            children={
                              <Phone
                                size={20}
                                className={
                                  selectedButton === "Phone"
                                    ? "fill-white/5"
                                    : "fill-white/40"
                                }
                                color={
                                  selectedButton === "Phone"
                                    ? "white"
                                    : "white/20"
                                }
                              />
                            }
                          />
                        </Button>
                        <Button
                          className={`flex w-10 h-10 border rounded-full z-10 focus:ring-0 ${
                            selectedButton === "Website"
                              ? "border-white/30 bg-white/20"
                              : "border-white/10 bg-white/5"
                          }`}
                          onClick={() => handleButtonClick("Website")}
                        >
                          <CustomTippy
                            mode="div"
                            content="Websites"
                            theme="light"
                            children={
                              <Mail
                                size={20}
                                className={
                                  selectedButton === "Website"
                                    ? "fill-white/5"
                                    : "fill-white/40"
                                }
                                color={
                                  selectedButton === "Website"
                                    ? "white"
                                    : "white/20"
                                }
                              />
                            }
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center ">
                    <div
                      onClick={() => {
                        navigate("/queries", {
                          state: {
                            source: selectedButton?.replace(
                              "WalkIn",
                              "Walk-in"
                            ),
                          },
                        });
                      }}
                      className="cursor-pointer text-6xl font-medium text-white mt-4"
                    >
                      {dashboards?.totalQueries?.[selectedButton] || 0}
                    </div>
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1 flex-1 p-5 border relative rounded-[0.6rem] bg-slate-50/50 ">
                  <div className="flex items-center">
                    <span className="flex items-center justify-center w-16 h-16 border rounded-full border-primary/10 bg-primary/10">
                      <Lucide
                        icon="Files"
                        className="w-6 h-6 text-primary fill-primary/10"
                      />
                    </span>

                    <div className="ml-4">
                      <div className="text-2xl font-medium">Queries</div>
                    </div>
                  </div>
                  <div className="flex items-center mt-12">
                    <div
                      onClick={() => {
                        navigate("/queries", {
                          state: { stage: model?.stage },
                        });
                      }}
                      className=" cursor-pointer text-6xl font-medium"
                    >
                      {dashboards?.queriesByStatus || 0}
                    </div>
                    <Menu className="flex  ml-3.5 border border-danger/50 bg-danger/70 rounded-full pl-[7px] pr-1 py-[2px] text-xs font-medium text-white/90">
                      <Menu.Button className="flex align-center z-10">
                        {buttonText}
                        <Lucide
                          icon="ChevronDown"
                          className="w-4 h-4 ml-px stroke-[1.5]"
                        />
                      </Menu.Button>
                      <Menu.Items
                        className="w-48 text-black flex flex-col items-center"
                        placement="bottom-start"
                      >
                        {stageOptions?.map((item, index) => (
                          <Menu.Item
                            key={index}
                            className="flex p-1 z-10 text-slate-500 w-full"
                            onClick={() => handleMenuClick(item)}
                          >
                            {item?.label}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1 flex-1 p-5 border relative rounded-[0.6rem] bg-slate-50/50 overflow-hidden">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-16 h-16 border rounded-full border-primary/10 bg-primary/10">
                      <Lucide
                        icon="DollarSign"
                        className="w-6 h-6 text-primary fill-primary/10"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-2xl font-medium">Transaction</div>
                    </div>
                  </div>
                  <div className="flex items-center mt-12">
                    <div className="text-6xl font-medium">
                      {dashboards?.transactions || "$0"}
                    </div>
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1 flex-1 p-5 border relative rounded-[0.6rem] bg-slate-50/50 overflow-hidden">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-16 h-16 border rounded-full border-primary/10 bg-primary/10">
                      <Lucide
                        icon="Users"
                        className="w-6 h-6 text-primary fill-primary/10"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-2xl font-medium">
                        Open Follow-ups
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-12">
                    <div className="text-6xl font-medium">
                      {dashboards?.openfollowup || 0}
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel className="p-5 leading-relaxed"></Tab.Panel>
            <Tab.Panel className="p-5 leading-relaxed"></Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="col-span-12">
        <div className="flex flex-col md:h-10 gap-y-3 md:items-center md:flex-row justify-between">
          <div className="text-base font-medium">Recent Actionable</div>
        </div>

        <div className="mt-2 overflow-auto lg:overflow-visible">
          <div className="overflow-auto lg:overflow-visible">
            {Loading ? (
              <Card className="flex w-[100%] justify-center py-8">
                <LoadingIcon
                  color="#64748b"
                  icon="oval"
                  className="w-8 h-8 mx-auto"
                />
              </Card>
            ) : (
              <Table className="border-spacing-y-[10px] border-separate">
                <Table.Tbody>
                  {dashboards.actionablequery?.length > 0 ? (
                    dashboards.actionablequery.map((query: any) => {
                      const datetimeString = query.created_at;
                      const date = new Date(datetimeString)
                        .toISOString()
                        .split("T")[0];
                      return (
                        <Table.Tr key={query.created_at}>
                          <Table.Td
                            className=" w-28 cursor-pointer box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600"
                            onClick={() => onQueryClick(query)}
                          >
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              Query #
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm whitespace-nowrap text-[#075985] flex items-center">
                                <div
                                  className={`h-4 w-1 mr-1 ${
                                    getTableColorFromStatus[
                                      query?.stage == 4
                                        ? 16
                                        : query?.stage == 2
                                        ? query?.assigned_by &&
                                          !query?.assigned_to
                                          ? 19
                                          : 18
                                        : query.status
                                    ]
                                  }`}
                                />
                                {query.query_no}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              Query Date
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                {dateParser(date)}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              Source
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                {query.source}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              Type
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                {query.query_type_text}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1 text-sm text-slate-500 whitespace-nowrap">
                              Trip Type
                            </div>
                            <div className="whitespace-nowrap">
                              {query.trip_type_text}
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              No.of PAX
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                {query.no_of_adults +
                                  query.no_of_infants +
                                  query.no_of_children}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                              Location
                            </div>
                            <div className="flex mb-1">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                {query?.sourced_by_user?.location?.name ??
                                  query?.location?.name}
                              </div>
                            </div>
                          </Table.Td>
                          <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                            <div className="mb-1 text-sm text-slate-500 whitespace-nowrap">
                              Status
                            </div>
                            <span className="w-auto h-auto px-2 py-0.5 justify-items-stretch text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
                              {query.status_text}
                            </span>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })
                  ) : (
                    <Card className="flex w-[100%] justify-center py-8 text-base">
                      No Actionable Query
                    </Card>
                  )}
                </Table.Tbody>
              </Table>
            )}
          </div>
        </div>
      </div>

      {isManager() && (
        <div className="col-span-12">
          <div className="flex flex-col md:h-10 gap-y-3 md:items-center md:flex-row justify-between">
            <div className="text-base font-medium">Open Queries</div>
            <Button
              variant="primary"
              onClick={handleSeeAll}
              className="group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
            >
              See All
            </Button>
          </div>

          <div className="mt-2 overflow-auto lg:overflow-visible">
            <div className="overflow-auto lg:overflow-visible">
              {Loading ? (
                <Card className="flex w-[100%] justify-center py-8">
                  <LoadingIcon
                    color="#64748b"
                    icon="oval"
                    className="w-8 h-8 mx-auto"
                  />
                </Card>
              ) : (
                <Table className="border-spacing-y-[10px] border-separate">
                  <Table.Tbody>
                    {dashboards.queries?.length > 0 ? (
                      dashboards.queries?.slice(0, 3)?.map((query) => {
                        const datetimeString = query.created_at;
                        const date = new Date(datetimeString)
                          .toISOString()
                          .split("T")[0];
                        return (
                          <Table.Tr key={query.created_at}>
                            <Table.Td
                              className=" w-28 cursor-pointer box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600"
                              onClick={() => onQueryClick(query)}
                            >
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                Query #
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm whitespace-nowrap text-[#075985] flex items-center">
                                  <div
                                    className={`h-4 w-1 mr-1 ${
                                      getTableColorFromStatus[
                                        query?.stage == 4
                                          ? 16
                                          : query?.stage == 2
                                          ? query?.assigned_by &&
                                            !query?.assigned_to
                                            ? 19
                                            : 18
                                          : query.status
                                      ]
                                    }`}
                                  />
                                  {query.query_no}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                Query Date
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                  {dateParser(date)}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                Source
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                  {query.source}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                Type
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                  {query.query_type_text}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1 text-sm text-slate-500 whitespace-nowrap">
                                Trip Type
                              </div>
                              <div className="whitespace-nowrap">
                                {query.trip_type_text}
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                No.of PAX
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                  {query.no_of_adults +
                                    query.no_of_infants +
                                    query.no_of_children}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                Location
                              </div>
                              <div className="flex mb-1">
                                <div className="mb-1.5 text-sm text-slate-500 whitespace-nowrap">
                                  {query?.sourced_by_user?.location?.name}
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="w-44 box shadow-[5px_3px_5px_#00000005] first:border-l last:border-r first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] rounded-l-none rounded-r-none border-x-0 dark:bg-darkmode-600">
                              <div className="mb-1 text-sm text-slate-500 whitespace-nowrap">
                                Status
                              </div>
                              <span className="w-auto h-auto px-2 py-0.5 justify-items-stretch text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
                                {query.status_text}
                              </span>
                            </Table.Td>
                          </Table.Tr>
                        );
                      })
                    ) : (
                      <Card className="flex w-[100%] justify-center py-8 text-base">
                        No Open Query
                      </Card>
                    )}
                  </Table.Tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ dashboards }: RootState) => ({
  dashboards,
});
const mapDispatchToProps = (dispatch: any) => ({
  onFetch: (payload: any) => dispatch(fetchDashboards(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
