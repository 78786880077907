import {
  CREATE_PAYMENT,
  FETCH_PAYMENT,
  ACCEPT_PAYMENT,
  FETCH_SIGN_TEMPLATES,
  INITIATE_REFUND,
  COMPLETE_REFUND,
} from "./types";

export const createPayment = (payload: any) => {
  return {
    type: CREATE_PAYMENT,
    payload,
  };
};

export const acceptPayment = (payload: any) => {
  return {
    type: ACCEPT_PAYMENT,
    payload,
  };
};

export const fetchPayment = (payload?: any) => {
  return {
    type: FETCH_PAYMENT,
    payload,
  };
};

export const fetchSignTemplates = (payload?: any) => {
  return {
    type: FETCH_SIGN_TEMPLATES,
    payload,
  };
};

export const initiateRefund = (payload?: any) => {
  return {
    type: INITIATE_REFUND,
    payload,
  };
};

export const completeRefund = (payload?: any) => {
  return {
    type: COMPLETE_REFUND,
    payload,
  };
};
