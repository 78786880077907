import { icons } from "src/base-components/Lucide";
export const titleEnum = {
  queries: "Queries",
  customers: "Customers",
  locations: "Locations",
};

export const iconEnum: { [key: string]: keyof typeof icons } = {
  queries: "ActivitySquare",
  customers: "Users",
  locations: "MapPin",
};

export const linkEnum = {
  queries: "/queries?search-queries",
  customers: "/customers",
  locations: "/locations",
  all: "/queries?all",
};
export const detailLinkEnum = {
  queries: "/query-detail",
  customers: "/customer-detail",
  locations: "",
};

export const displayKeys: {
  [key: string]: {
    key: string;
    type?: "date" | "status" | "custom" | "customer";
    callback?: (item: any) => void;
  }[];
} = {
  queries: [
    { key: "query_no" },
    { key: "created_at", type: "date" },
    {
      key: "",
      type: "custom",
      callback: (item: any) => (
        <span>{`${item?.sourced_by_user?.name || ""} ${
          item?.sourced_by_user?.last_name || ""
        }`}</span>
      ),
    },
    {
      key: "",
      type: "customer",
      callback: (item: any) => (
        <span>{`${item?.customer?.fullname || ""}`}</span>
      ),
    },
    { key: "status_text", type: "status" },
  ],
  customers: [
    { key: "customer_id" },
    { key: "fullname" },
    { key: "email" },
    { key: "phone" },
  ],
  locations: [{ key: "name" }],
};
