import {
  CREATE_CUSTOMER,
  FETCH_CUSTOMER_LIST, UPDATE_CUSTOMER,
} from "./types";

export const fetchCustomers = (payload: any) => {
  return {
    type: FETCH_CUSTOMER_LIST,
    payload
  };
};

export const createCustomer = (payload: string) => {
  return {
    type: CREATE_CUSTOMER,
    payload
  };
};

export const updateCustomer = (payload: string) => {
  return {
    type: UPDATE_CUSTOMER,
    payload
  };
};
