import Lucide from "src/base-components/Lucide";
import Button from "src/base-components/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import PassengersDetails from "./Passengers";
import Payments from "./Payments";
import QuotationProposals from "./Quotations";
import {
  fetchQuery,
  fetchQueryActivityLogs,
  fetchUserQueryHistory,
  removeQuery,
  removeUserQuery,
} from "src/stores/query";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import {
  authUserProps,
  createUpdateCustomerProps,
  forceDeleteProps,
  queryAssignToProps,
  queryDataProps,
  queryUpdateProps,
} from "src/interfaces";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { checkPermission, customColorGenerator } from "src/helpers";
import { queryAPIUrls, customerAPIUrls } from "src/common/apiActions";
import LoadingIcon from "src/base-components/LoadingIcon";
import ActivityLogs from "./ActivityLogs";
import AssignTo, { assignToUsersType, forType } from "./AssignTo";
import { updateCustomer, setCustomer } from "src/stores/customer";
import { FormInput } from "src/base-components/Form";
import {
  CheckIcon,
  Pencil,
  XIcon,
  Edit,
  PhoneOutgoing,
  Info,
  InfoIcon,
} from "lucide-react";
import clsx from "clsx";
import PhoneInput from "src/components/PhoneInput";
import UpdateRequestModal from "./UpdateRequestModal";
import Card from "src/components/Card";
import UpdateQuery from "../UpdateQuery";
import EmergencyIcon from "../EmergencyIcon";
import { Menu } from "src/base-components/Headless";
import { queriesTableFields, reopenOptions } from "./constants";
import {
  isQueryCancelled,
  isQueryClosedAndCancelled,
  isQueryOpen,
} from "../helpers";
import ForceClose from "./ForceCLose";
import CallBack from "./CallBack";
import CustomTippy from "src/base-components/customTooltip";
import OverlappingSlideOver from "src/components/OverlappingSlideOver";
import { updateQueryQue } from "src/services";
import { createQueue } from "src/stores/queue";

function Component(props: {
  headerTitle?: string | React.ReactElement;
  addBtnTitle?: string | React.ReactElement;
  onAddClick?: Function;
  query: queryDataProps;
  userQueries: any;
  fetchQuery: (payload: { id: string; no: string; callback: Function }) => void;
  fetchUserQueries: any;
  removeQuery: Function;
  removeUserQuery: Function;
  authUser: authUserProps;
  onUpdateCustomer: Function;
  setCustomer: (payload: any) => void;
  setBillingToQue: (payload: any) => void;
  fetchActivityLogs: (payload: any) => void;
  queues: any;
}) {
  const { state } = useLocation();
  const {
    query,
    fetchQuery,
    fetchUserQueries,
    removeQuery,
    removeUserQuery,
    authUser,
    userQueries,
    onUpdateCustomer,
    setCustomer,
    setBillingToQue,
    fetchActivityLogs,
    queues,
  } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const isViewQuery =
    loading ||
    authUser?.user_type == "Admin" ||
    authUser?.role?.user_type == "Manager"
      ? false
      : authUser?.id !== query?.sourced_by &&
        authUser?.id != query?.assigned_to;
  const [addSelfUser, setAddSelfUser] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [error, setError] = useState("");
  const [isEditCustomer, setIsEditCustomer] = useState(false);
  const [isEditQuery, setIsEditQuery] = useState(false);
  const [assignToModal, setAssignToModal] = useState(false);
  const [updateRequestModal, setUpdateRequestModal] = useState(false);
  const [forceCloseModal, setForceCloseModal] = useState(false);
  const [callBackModal, setCallBackModal] = useState(false);
  const [assignToUsersType, setAssignToUsersType] =
    useState<assignToUsersType>(1);
  const [forType, setForType] = useState<forType>(1);
  const [assignToModel, setAssignToModel] = useState<queryAssignToProps>({
    id: query?.id,
  } as any);
  const [withDoc, setWithDoc] = useState<boolean>(false);
  const [callBackToModel, setCallBackToModel] = useState<queryAssignToProps>({
    id: query?.id,
  } as any);
  const [forceDeleteModel, setForceDeleteModel] = useState<forceDeleteProps>({
    id: query?.id,
  } as any);
  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState(false);
  const [customerModel, setCustomerModel] = useState<createUpdateCustomerProps>(
    {
      id: query?.customer?.id || "",
      fullname: query?.customer?.fullname || "",
      phone: query?.customer?.phone || "",
      email: query?.customer?.email || "",
      country_code: query?.customer?.country_code || "",
    }
  );

  const [showOverlapModal, setShowOverlapModal] = useState<boolean>(false);

  const getRemarks = useMemo(() => {
    return query?.queries_status_logs && query?.queries_status_logs.length > 0
      ? query?.queries_status_logs[0]?.description
      : "";
  }, [query]);

  const [queryModel, setQueryModel] = useState<queryUpdateProps>({
    id: query?.id || "",
    source: "",
    trip_type: 0,
    no_of_adults: 0,
    no_of_infants: 0,
    no_of_children: 0,
    query_type: 2,
    query_itinerary_detail: [],
    is_emergency: 1,
    remarks: "",
    location_id: "",
  });

  const handleOverlapModal = (id) => {
    if (id) {
      fetchUserQueries({ customer_id: id });
      setShowOverlapModal(true);
    }
  };

  const handleIsEditCustomer = () => {
    setIsEditCustomer(!isEditCustomer);
  };

  const handleIsEditQuery = () => {
    const currentEditStatus = !isEditQuery;
    if (!currentEditStatus) {
      setQueryModel({} as any);
    } else {
      const itineraries = JSON.parse(query?.query_itinerary_detail);
      setQueryModel({
        id: query?.id,
        source: query?.source,
        trip_type: query?.trip_type,
        no_of_adults: query?.no_of_adults,
        no_of_infants: query?.no_of_infants,
        no_of_children: query?.no_of_children,
        query_type: query?.query_type,
        query_itinerary_detail: query?.query_itinerary_detail
          ? Array.isArray(itineraries)
            ? itineraries
            : JSON.parse(itineraries)
          : [],
        is_emergency: query?.is_emergency,
        remarks: getRemarks ?? "",
        location_id: query?.location_id ?? "",
      });
    }
    setIsEditQuery(currentEditStatus);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setCustomerModel((previous) => {
      return {
        ...previous,
        [name]: value,
      };
    });
  };

  const handleUpdateCustomer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsUpdatingCustomer(true);
    onUpdateCustomer({
      ...customerModel,
      fromQuery: true,
      query_id: query?.id,
      callback: () => {
        setIsUpdatingCustomer(false);
        handleIsEditCustomer();
      },
    });
  };

  const params = useParams();
  const navigate = useNavigate();

  const removeOldData = async () => {
    setLoading(true);
    removeQuery();
    removeUserQuery();
  };

  useEffect(() => {
    if (
      query &&
      !query?.canViewQuery &&
      query?.sourced_by &&
      query?.assigned_to !== authUser?.id &&
      authUser?.user_type !== "Admin" &&
      authUser?.role?.user_type !== "Manager"
    ) {
      setIsViewOnly(authUser?.id !== query?.sourced_by);
    }
    setCustomerModel({
      id: query?.customer?.id || "",
      fullname: query?.customer?.fullname || "",
      phone: query?.customer?.phone || "",
      email: query?.customer?.email || "",
      country_code: query?.customer?.country_code || "",
    });
    return () => {
      setIsViewOnly(false);
    };
  }, [query]);

  useEffect(() => {
    removeOldData()?.then(() => {
      console.log("HERE qu");
      fetchQuery({
        id: params?.id || "",
        no: params?.no || "",
        callback: (error, message) => {
          setLoading(false);
          if (error) {
            setError(message);
          }
        },
      });
    });
  }, [params?.id]);

  const QuotationProposalsCallback = useCallback(
    () => (
      <QuotationProposals totalPassengers={totalPassengers} loading={loading} />
    ),
    [loading, query?.quotations_detail, query?.id]
  );

  const handleAssignToModal = async (
    usersType: assignToUsersType = 1,
    fortype: forType = 1,
    status: any = undefined,
    isAddSelf: boolean = false,
    agentId: number = 0
  ) => {
    setAssignToModal(true);
    setAssignToUsersType(usersType);
    setForType(fortype);
    setAddSelfUser(isAddSelf);
    let assignToPayload = {
      id: query?.id,
      userid: agentId,
      remark: "",
      status: status,
    };

    setWithDoc(false);

    if (status) {
      assignToPayload = { ...assignToPayload, status };
    }
    setAssignToModel(assignToPayload);
  };

  const isAssigned = useMemo(() => {
    if (
      query &&
      query?.assigned_to_user &&
      Object.entries(query?.assigned_to_user)?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [query]);

  const totalPassengers =
    query?.no_of_adults + query?.no_of_children + query?.no_of_infants;
  return (
    <div className="grid grid-cols-12 gap-y-10 gap-x-6">
      <div className="col-span-12">
        <div className="flex flex-col md:items-center md:flex-row">
          <Button
            onClick={() => {
              if (state == "customer") {
                setCustomer(query?.customer);
              } //
              navigate(-1);
            }}
            variant="primary"
            className="mr-2 p-1 px-2 shadow-none w-[80px] lg:mb-0 sm:mb-1"
          >
            <Lucide icon="ArrowLeft" className="stroke-[1.3] w-4 h-4 mr-2" />
            Back
          </Button>
          {query?.query_no?.length > 0 && (
            <div className="flex flex-row items-center">
              <div className="text-base font-medium items-center justify-center group-[.mode--light]:text-white hidden md:block ">
                <div className="flex">
                  Query #{" "}
                  <div
                    className={`w-6 h-6 p-2 border-2 rounded-full  flex justify-center items-center`}
                    style={{
                      backgroundColor: customColorGenerator(
                        query?.sourced_by_user?.location?.short_name ??
                          query?.location?.short_name
                      )?.backgroundColor,
                    }}
                  >
                    <p
                      className="p-2 text-[8px] font-semibold"
                      style={{
                        color: customColorGenerator(
                          query?.sourced_by_user?.location?.short_name ??
                            query?.location?.short_name
                        )?.color,
                      }}
                    >
                      {query?.sourced_by_user?.location?.short_name ??
                        query?.location?.short_name}
                    </p>
                  </div>
                  <span className="text-primary">{query?.query_no}</span>
                  {query?.is_emergency == 1 && (
                    <EmergencyIcon className="mr-0 ml-1" />
                  )}
                </div>
              </div>

              <CustomTippy
                mode="div"
                content={"Customer needs a callback"}
                theme="light"
                children={
                  <Button
                    onClick={() => {
                      setCallBackModal(true);
                    }}
                    variant="soft-primary"
                    className="ml-2 p-1 px-2 text-sm shadow-none lg:mb-0 sm:mb-1 w-32"
                  >
                    <PhoneOutgoing className="stroke-[1.3] w-4 h-4 mr-3" />
                    Call Back
                  </Button>
                }
              />
            </div>
          )}

          <div className="flex flex-col sm:flex-row gap-x-3 gap-y-2 md:ml-auto">
            <>
              {!loading && (
                <>
                  {!isViewQuery &&
                    isQueryClosedAndCancelled(query?.status) &&
                    (checkPermission(queryAPIUrls?.cancellation) ||
                      checkPermission(queryAPIUrls?.changeDate) ||
                      checkPermission(queryAPIUrls?.rescheduledByAirline)) &&
                    !isQueryCancelled(query?.status) && (
                      //  &&
                      // query?.accepted_quotation?.some(
                      //   (item) => item.ticket != null
                      // )
                      <Menu className="">
                        <Menu.Button
                          as={Button}
                          onClick={() => {}}
                          className={clsx(
                            "h-10 border-slate-300/100",
                            "object-contain justify-start truncate"
                          )}
                        >
                          <Lucide icon="Globe" className="mr-1" />
                          Re-open
                          <Lucide icon="ChevronDown" className="ml-auto" />
                        </Menu.Button>
                        <Menu.Items
                          className="flex flex-col flex-wrap w-full lg:w-full md:w-full sm:w-full  "
                          placement="bottom-custom"
                        >
                          {reopenOptions?.map((item, index) => {
                            if (!checkPermission(item?.url)) {
                              return null;
                            }
                            return (
                              <Menu.Item
                                className="md:w-full sm:w-full sm:text-sm md:text-sm"
                                onClick={() => {
                                  if (item?.url == queryAPIUrls?.cancellation) {
                                    handleAssignToModal(
                                      4,
                                      1,
                                      11,
                                      true,
                                      authUser?.id
                                    );
                                  }
                                }}
                              >
                                {item?.label}
                              </Menu.Item>
                            );
                          })}
                        </Menu.Items>
                      </Menu>
                    )}
                  {!isQueryClosedAndCancelled(query?.status) && (
                    <>
                      {checkPermission(queryAPIUrls?.forceClose, query) &&
                        query?.action_status !== 6 &&
                        (!query?.payment?.every(
                          (payment) => payment?.status_text === "Completed"
                        ) ||
                          !query?.payment?.some(
                            (payment) => payment?.status_text === "Completed"
                          )) && (
                          <Button
                            onClick={() => {
                              if (query?.status >= 7) {
                                handleAssignToModal(5, 6);
                              } else {
                                setForceCloseModal(true);
                              }
                            }}
                            variant="soft-primary"
                            className="p-1 px-2 shadow-none lg:mb-0 sm:mb-1 mr-[-0.75rem]"
                          >
                            <Lucide
                              icon="Unplug"
                              className="stroke-[1.3] w-4 h-4 mr-3"
                            />
                            Force Close
                          </Button>
                        )}

                      {query?.action_status == 6 &&
                        authUser?.id == query?.assigned_to && (
                          <Button
                            onClick={() => {
                              setForceCloseModal(true);
                            }}
                            variant="soft-primary"
                            className="p-1 px-2 shadow-none lg:mb-0 sm:mb-1 mr-[-0.75rem]"
                          >
                            <Lucide
                              icon="Unplug"
                              className="stroke-[1.3] w-4 h-4 mr-3"
                            />
                            Approve Force Close
                          </Button>
                        )}
                      <ForceClose
                        visible={forceCloseModal}
                        setVisible={setForceCloseModal}
                        model={forceDeleteModel}
                        setModel={setForceDeleteModel}
                      />
                      <CallBack
                        visible={callBackModal}
                        setVisible={setCallBackModal}
                        model={callBackToModel}
                        setModel={setCallBackToModel}
                        currentSource={query?.sourced_by}
                      />
                    </>
                  )}

                  {isViewQuery && query?.action_status != 3 && (
                    <Button
                      onClick={() => {
                        handleAssignToModal(3, 3);
                      }}
                      variant="primary"
                      className="p-1 group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                    >
                      <Lucide
                        icon="GitPullRequest"
                        className="stroke-[1.3] w-4 h-4 mr-3"
                      />
                      Request Update Permission
                    </Button>
                  )}
                  {query?.action_status == 3 &&
                    authUser?.id == query?.assigned_to && (
                      <Button
                        onClick={() => {
                          setUpdateRequestModal(true);
                        }}
                        variant="primary"
                        className="p-1 group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                      >
                        <Lucide
                          icon="GitPullRequest"
                          className="stroke-[1.3] w-4 h-4 mr-3"
                        />
                        Request for Update Permission
                      </Button>
                    )}
                </>
              )}
            </>
            {!isViewQuery &&
              (query?.sourced_by == authUser?.id ||
                query?.assigned_to == authUser?.id) &&
              query?.is_added_to_billing != 1 &&
              isQueryOpen(query?.stage) && (
                <Button
                  onClick={() => {
                    handleAssignToModal(1);
                  }}
                  variant="primary"
                  className="group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                >
                  <Lucide
                    icon="CornerUpRight"
                    className="stroke-[1.3] w-4 h-4 mr-3"
                  />
                  Assign to
                </Button>
              )}
            {/* {checkPermission(queryAPIUrls?.create) ? (
              <Button
                as={Link}
                to="/create-query"
                variant="primary"
                className="group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
              >
                <Lucide icon="Plus" className="stroke-[1.3] w-4 h-4 mr-2" />
                Create New Query
              </Button>
            ) : null} */}
          </div>
          {query?.query_no?.length > 0 && (
            <div className="text-base font-medium group-[.mode--light]:text-white mt-2 md:hidden">
              Query # <span className="text-primary">{query?.query_no}</span>
            </div>
          )}
        </div>
        <div className="mt-2 grid grid-cols-12 gap-y-10 gap-x-6">
          <div className="col-span-12 xl:col-span-8">
            <Card className="mt-1">
              {loading || isUpdatingCustomer ? (
                <div className="flex w-[100%] justify-center py-16">
                  <LoadingIcon
                    color="#64748b"
                    icon="oval"
                    className="w-8 h-8 mx-auto"
                  />
                </div>
              ) : Object?.keys(query || {}).length == 0 || error ? (
                <div
                  className={`flex w-[100%] justify-center py-16 ${
                    error ? "text-danger text-sm font-semibold" : ""
                  }`}
                >
                  <div className="text-center">
                    <div>{error || "No Item"}</div>
                  </div>
                </div>
              ) : (
                <div
                  onMouseOver={() => {
                    checkPermission(queryAPIUrls?.update, query) &&
                      setShowEdit(true);
                  }}
                  onMouseLeave={() => setShowEdit(false)}
                  className="border relative border-dashed py-4 px-2 rounded-lg"
                >
                  {showEdit && isQueryOpen(query?.stage) && (
                    <Edit
                      className="ml-auto absolute right-1 top-1 text-slate-500 cursor-pointer"
                      size={18}
                      onClick={() => handleIsEditQuery()}
                    />
                  )}
                  <div className="flex gap-x-5 flex-col justify-between items-start flex-1 mt-0 gap-y-1 sm:mt-5 sm:mb-3 lg:mt-2 xl:mb-0 md:flex-row">
                    <div className="items-center text-slate-900 sm:col-span-1 md:col-span-2 sm:hidden ml-auto mb-1 flex">
                      {query?.stage_text != query?.status_text && (
                        <span className="px-2 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                          {query?.stage_text}
                        </span>
                      )}
                      <span className="ml-3 px-2 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                        {query?.status_text}
                      </span>
                    </div>
                    <div>
                      <div className="text-slate-500">Source:</div>
                      <div className="mt-1.5 font-medium text-slate-600">
                        {query?.source}
                      </div>
                    </div>
                    <div className="max-w-[25%]">
                      <div className="text-slate-500">Type:</div>
                      <div className="mt-1.5 font-medium text-slate-600 w-max">
                        {query?.query_type_text}
                      </div>
                    </div>
                    <div className="max-w-[20%] min-w-[15%]">
                      <div className="text-slate-500">Trip Type:</div>
                      <div className="mt-1.5 font-medium text-slate-600">
                        {query?.trip_type_text}
                      </div>
                    </div>
                    <div className="max-w-[15%] min-w-[15%]">
                      <div className="text-slate-500"># PAX:</div>
                      <div className="mt-1.5 w-max">
                        <div className="font-medium text-slate-600">
                          {`${totalPassengers} ${
                            totalPassengers > 1 ? "Persons" : "Person"
                          }`}
                        </div>
                        <div className="text-slate-500">
                          {query?.no_of_adults} Adult, {query?.no_of_children}{" "}
                          Children, {query?.no_of_infants} Infant
                        </div>
                      </div>
                    </div>
                    <div className="items-center text-slate-900 sm:col-span-1 md:col-span-2 hidden sm:flex">
                      {query?.stage_text != query?.status_text && (
                        <span className="px-1.5 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                          {query?.stage_text}
                        </span>
                      )}
                      <span className="ml-1 px-1.5 py-0.5 bg-blue-100 text-blue-600 text-xs rounded-md border border-slate-200">
                        {query?.status_text}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {isEditCustomer ? (
                      <div className="flex flex-row items-center">
                        <div className="text-slate-500 mr-1">Customer Info</div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center">
                        {!query?.customer?.email && (
                          <CustomTippy
                            mode="div"
                            content="Please provide email address to continue with sharing the quotation/ticket."
                            theme="light"
                            className="shadow-none"
                            children={
                              <InfoIcon size={15} className="text-slate-500" />
                            }
                          />
                        )}
                        <div className="text-slate-500 mr-1">Customer Info</div>
                        {checkPermission(customerAPIUrls?.update, query) && (
                          <Pencil
                            size={14}
                            className="cursor-pointer"
                            onClick={handleIsEditCustomer}
                          />
                        )}
                      </div>
                    )}
                    {isEditCustomer ? (
                      <form
                        className="flex flex-row items-center w-[90%] mt-1"
                        onSubmit={handleUpdateCustomer}
                      >
                        <FormInput
                          name="fullname"
                          value={customerModel?.fullname}
                          onChange={onChangeInput}
                          placeholder="Enter customer name..."
                          required
                          type="text"
                          className={clsx(
                            "first:rounded-b-none first:md:rounded-bl-md ",
                            "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
                            "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
                            "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
                            "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-10"
                          )}
                        />
                        <FormInput
                          name="email"
                          value={customerModel?.email}
                          onChange={onChangeInput}
                          placeholder="Enter customer email..."
                          type="email"
                          className={clsx(
                            "first:rounded-b-none first:md:rounded-bl-md ",
                            "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
                            "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
                            "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
                            "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-10"
                          )}
                        />
                        <PhoneInput
                          {...({} as any)}
                          value={`${customerModel?.country_code}${customerModel?.phone}`}
                          placeholder="#Phone"
                          required={true}
                          onChange={(data) => {
                            setCustomerModel((previous) => {
                              return {
                                ...previous,
                                country_code: data?.dialCode,
                                phone: data?.phone,
                              };
                            });
                          }}
                        />
                        <button
                          type="submit"
                          className="border-none bg-primary p-1 rounded-full ml-2"
                        >
                          <CheckIcon size={20} type="submit" color="white" />
                        </button>
                        <button className="rounded-full bg-gray-300 ml-2 p-1">
                          <XIcon
                            className="cursor-pointer text-primary"
                            onClick={handleIsEditCustomer}
                            size={20}
                          />
                        </button>
                      </form>
                    ) : (
                      <div className="flex justify-start flex-col flex-1 mt-0 gap-3 sm:mt-5 sm:mb-4 lg:mt-2 xl:mb-0 md:flex-row ">
                        <div
                          className="font-medium text-primary cursor-pointer"
                          onClick={() =>
                            handleOverlapModal(query?.customer?.id)
                          }
                        >
                          {query?.customer?.fullname}
                        </div>
                        <div className="hidden md:block">|</div>
                        <div className="font-medium text-slate-600">
                          {query?.customer?.country_code}
                          {query?.customer?.phone}
                        </div>
                        {query?.customer?.email && (
                          <div className="hidden md:block">|</div>
                        )}
                        <div className="font-medium text-slate-600">
                          {query?.customer?.email}
                        </div>
                        <OverlappingSlideOver
                          authUser={authUser}
                          headerTitle={`Query History (${query?.customer?.fullname})`}
                          visible={showOverlapModal}
                          onClose={(value) => setShowOverlapModal(value)}
                          data={
                            userQueries && userQueries?.length > 0
                              ? userQueries?.filter(
                                  (item: any) => item?.id !== query?.id
                                )
                              : []
                          }
                          columns={queriesTableFields(
                            (item: queryDataProps) => {}
                          )}
                          queryId={query?.id}
                          queryNumber={query?.query_no}
                        />
                      </div>
                    )}
                  </div>
                  {query?.queries_status_logs?.length > 0 && (
                    <div className="max-w-[15%] min-w-[15%] mt-5">
                      <div className="text-slate-500">Initial Remarks:</div>
                      <div className="mt-1.5 w-max">
                        <div className="font-medium text-slate-600">
                          {query.queries_status_logs[0]?.description}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card>
            {!isViewQuery && (
              <div>
                {(query?.quotations_detail?.some((ele) => ele?.status == "3") ||
                  query?.payment?.length > 0) && (
                  <Payments
                    handleAssignToClick={(agentId, usersType, forType) => {
                      handleAssignToModal(
                        usersType || 2,
                        forType || 2,
                        false,
                        agentId
                      );
                      setWithDoc(true);
                    }}
                  />
                )}
                <QuotationProposalsCallback />
              </div>
            )}
          </div>
          <ActivityLogs isViewOnly={isViewOnly} loading={loading} />
        </div>
      </div>
      <AssignTo
        headerTitle={forType == 6 ? "Force close" : "Assign Query"}
        model={assignToModel}
        setModel={setAssignToModel}
        visible={assignToModal}
        setVisible={setAssignToModal}
        assignToUsersType={assignToUsersType}
        forType={forType}
        addSelfUser={addSelfUser}
        withDoc={withDoc}
      />
      <UpdateQuery
        isAssigned={isAssigned}
        model={queryModel}
        setModel={setQueryModel}
        visible={isEditQuery}
        setVisible={() => {
          setIsEditQuery(false);
          setQueryModel({} as any);
        }}
      />
      <UpdateRequestModal
        visible={updateRequestModal}
        setVisible={setUpdateRequestModal}
      />
    </div>
  );
}

const mapStateToProps = ({ queries, authUser, queues }: RootState) => ({
  query: queries?.detail || ({} as any),
  userQueries: queries?.userQueries || ({} as any),
  authUser,
  queues: queues || ([] as any),
});

const mapDispatchToProps = (dispatch: any) => ({
  setCustomer: (payload: any) => dispatch(setCustomer(payload)),
  fetchQuery: (payload: any) => dispatch(fetchQuery(payload)),
  fetchUserQueries: (payload: any) => dispatch(fetchUserQueryHistory(payload)),
  fetchActivityLogs: (payload: any) =>
    dispatch(fetchQueryActivityLogs(payload)),
  removeQuery: (payload: any) => dispatch(removeQuery(payload)),
  removeUserQuery: (payload: any) => dispatch(removeUserQuery(payload)),
  onUpdateCustomer: (payload: any) => dispatch(updateCustomer(payload)),
  setBillingToQue: (payload: any) => dispatch(createQueue(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
