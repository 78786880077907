import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_DASHBOARD_GLOBAL_SEARCH, FETCH_DASHBOARD_DATA } from "./types";
import {
  fetchDashboardService,
  fetchDashboardGlobalSearchService,
} from "../../services";
import { setAlert } from "../basicSlices";
import { setDashboards } from "./slice";
import { setGlobalSearchData } from "./searchSlice";

function* handleLoadDashboards({
  payload,
}: { payload?: any } = {}): Generator<any> {
  try {
    const responseData: any = yield call(fetchDashboardService, payload);
    yield put(setDashboards(responseData));
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Dashboards!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleLoadGlobalSearch({
  payload,
}: {
  payload?: { keywords: string; callback?: Function };
} = {}): Generator<any> {
  try {
    const responseData: any = yield call(
      fetchDashboardGlobalSearchService,
      payload?.keywords
    );
    yield put(setGlobalSearchData(responseData));
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch search results!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

export default function* dashboardSaga() {
  yield takeLatest<any>(FETCH_DASHBOARD_DATA, handleLoadDashboards);
  yield takeLatest<any>(FETCH_DASHBOARD_GLOBAL_SEARCH, handleLoadGlobalSearch);
}
