import clsx from "clsx";
import Button from "src/base-components/Button";
import {
  createUpdateQueryActivityLogProps,
  queryDataProps,
} from "src/interfaces";
import LoadingIcon from "src/base-components/LoadingIcon";
import moment from "moment";
import { fetchQueryActivityLogs } from "src/stores/query";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { memo, useEffect, useState } from "react";
import AddCustomLog from "./AddCustomLog";
import Card from "src/components/Card";
import { dateParser } from "src/helpers";
import { useParams } from "react-router-dom";

function Component(props: {
  query: queryDataProps;
  fetchActivityLogs: (payload: { id: string; callback: Function }) => void;
  loading: boolean;
  isViewOnly: boolean;
}) {
  const { query, fetchActivityLogs, loading, isViewOnly } = props;
  const activityLogs = query?.activity_log?.data || [];
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);
  const [customLogModal, setCustomLogModal] = useState(false);

  const [customLogModel, setCustomLogModel] =
    useState<createUpdateQueryActivityLogProps>({
      query_id: query?.id,
      title: "",
      description: "",
    });

  return (
    <div className="col-span-12 xl:col-span-4">
      <Card className="flex flex-col p-5 mt-1">
        <div className="pb-2 mb-1 font-medium border-b border-dashed border-slate-300/70 text-[0.94rem]">
          Activity log and Notes
        </div>
        <div>
          <div>
            {loading ? (
              <div className="flex w-[100%] justify-center py-16">
                <LoadingIcon
                  color="#64748b"
                  icon="oval"
                  className="w-8 h-8 mx-auto"
                />
              </div>
            ) : activityLogs?.length == 0 ? (
              <div className="flex w-[100%] justify-center py-16">No Item</div>
            ) : (
              <div
                onScroll={(event) => {
                  const { scrollTop, scrollHeight, clientHeight } =
                    event?.currentTarget;
                  const distanceFromBottom =
                    scrollHeight - scrollTop - clientHeight;
                  console.log("HERE");
                  if (distanceFromBottom <= 0.5 && !isLoadedAll) {
                    if (!loadingMore && !loading) {
                      setLoadingMore(true);
                      fetchActivityLogs({
                        id: query?.id,
                        callback: (isError: boolean, isAllLoaded: boolean) => {
                          setLoadingMore(false);
                          setIsLoadedAll(isAllLoaded || isError);
                        },
                      });
                    }
                  } else if (distanceFromBottom > 0) {
                    setIsLoadedAll(false);
                  }
                }}
                className=" py-0 mt-4 flex flex-col gap-3.5 max-h-[60vh] overflow-y-auto overflow-x-hidden *:flex-wrap"
              >
                <div
                  key={"jkjjfslkdjlkdsjflk"}
                  className="relative w-full before:content-[''] before:absolute before:w-px before:bg-slate-200/60 before:left-0 before:inset-y-0 before:dark:bg-darkmode-400 before:ml-[14px]"
                >
                  {activityLogs?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={clsx([
                          "mb-3 last:mb-0 relative flex-wrap",
                          "first:before:content-[''] first:before:h-1/2 first:before:w-5 first:before:bg-white first:before:absolute",
                          "last:after:content-[''] last:after:h-1/2 last:after:w-5 last:after:bg-white last:after:absolute last:after:bottom-0",
                        ])}
                      >
                        <div
                          className={clsx([
                            "px-4 py-3 ml-8",
                            "before:content-[''] before:ml-1 before:absolute before:w-5 before:h-5 before:bg-slate-200 before:rounded-full before:inset-y-0 before:my-auto before:left-0 before:dark:bg-darkmode-300 before:z-[1]",
                            "after:content-[''] after:absolute after:w-1.5 after:h-1.5 after:bg-slate-500 after:rounded-full after:inset-y-0 after:my-auto after:left-0 after:ml-[11px] after:dark:bg-darkmode-200 after:z-[1]",
                          ])}
                        >
                          <a
                            href="#"
                            className="font-medium text-primary w-full cursor-default"
                          >
                            {item?.title}
                          </a>
                          <div className="flex w-full flex-wrap flex-col sm:flex-row sm:items-center gap-y-1.5 mt-1.5 leading-relaxed text-slate-500 text-[0.8rem]">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>

                          <div className="mt-1.5 text-xs text-slate-500">
                            {item?.createdByName} |{" "}
                            {moment(
                              item?.createdAt,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMMM YYYY hh:mm A")}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {loadingMore && !loading && (
                    <div className="flex w-[100%] justify-center py-5">
                      <LoadingIcon
                        color="#64748b"
                        icon="oval"
                        className="w-6 h-6 mx-auto"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isViewOnly && !loading && (
              <div className="ml-auto pt-2 mt-1.5 float-right">
                <Button
                  onClick={() => setCustomLogModal(true)}
                  variant="outline-primary"
                  size="sm"
                  className="font-medium text-primary"
                >
                  + Add Custom Note
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card>
      <AddCustomLog
        visible={customLogModal || isViewOnly}
        setVisible={setCustomLogModal}
        model={customLogModel}
        query={query}
        setModel={setCustomLogModel}
        isViewOnly={isViewOnly}
      />
    </div>
  );
}

const mapStateToProps = ({ queries }: RootState) => ({
  query: queries?.detail || ({} as any),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchActivityLogs: (payload: any) =>
    dispatch(fetchQueryActivityLogs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
