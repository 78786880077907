import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import {
  FormHelp,
  FormLabel,
  FormInput,
  FormTextarea,
} from "src/base-components/Form";
import React, { useEffect, useMemo, useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import {
  queryDataProps,
  queryAssignToProps,
  userDataProps,
} from "src/interfaces";
import { RootState, useAppSelector } from "src/stores";
import { queryAssignTo } from "src/stores/query";
import { fetchAllUsers } from "src/stores/user";
import LoadingIcon from "src/base-components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import ReactSelect from "src/base-components/ReactSelect";
import { selectAuthUser } from "src/stores/auth";
import { createQueue } from "src/stores/queue";
import { baseUrl } from "src/services/http";

export type assignToUsersType = 1 | 2 | 3 | 4 | 5;
export type forType = 1 | 2 | 3 | 6;

const Component = ({
  headerTitle = "Assign Query",
  query,
  users,
  fetchUsers,
  onAssign,
  visible,
  setVisible,
  model,
  setModel,
  assignToUsersType = 1,
  forType = 1,
  addSelfUser = false,
  withDoc = false,
  setBillingToQue,
}: {
  headerTitle: string;
  query: queryDataProps | undefined;
  users: userDataProps[];
  fetchUsers: (payload: { type: 1 | 2 | 3 }) => void;
  onAssign: (payload: queryAssignToProps) => void;
  visible: boolean;
  setVisible: Function;
  model: queryAssignToProps;
  setModel: Function;
  assignToUsersType?: assignToUsersType;
  forType?: forType;
  addSelfUser?: boolean;
  withDoc?: boolean;
  setBillingToQue: (payload: any) => void;
}) => {
  const authUser = useAppSelector(selectAuthUser);
  const navigate = useNavigate();
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<any>(null);

  const [openDocModel, setOpenDocModel] = useState<boolean>(false);
  const [billingDocs, setBillingDocs] = useState<any>([]);
  const [docFile, setDocFile] = useState<any>(null);
  const [currentTitleText, setCurrentTitleText] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isTitleError, setIsTitleError] = useState<boolean>(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setSubmitting(true);

    const currentPayment: any = localStorage.getItem("currentPayment");
    const result = JSON.parse(currentPayment);

    const id = result?.quotation_id;

    if (withDoc) {
      const finalPayload = {
        query_id: query_id,
        billing_doc: billingDocs,
        quotation_id: id,
        customer_id: query?.customer_id,
        payment_id: result?.id,
      };

      setBillingToQue({
        ...finalPayload,
        callback: (error) => {
          if (forType == 3) {
            navigate(-1);
          }
          if (!error) {
            localStorage.removeItem("currentPayment");
            setModel({ query_id: query_id } as any);
            setVisible(false);
            setBillingDocs(null);
          }
          localStorage.removeItem("currentPayment");
          setSubmitting(false);
        },
      });
    } else {
      let payload: queryAssignToProps = {
        id: query_id || model?.id,
        userid: model?.userid,
        remark: model?.remark,
        for: forType,
      };
      if ((model as any)?.status) {
        payload = { ...payload, status: (model as any)?.status } as any;
      }

      onAssign({
        ...payload,
        callback: (error) => {
          if (forType == 3) {
            navigate(-1);
          }
          if (!error) {
            setModel({ query_id: query_id } as any);
            setVisible(false);
          }
          setSubmitting(false);
        },
      } as any);
    }
  };
  useEffect(() => {
    if (visible) {
      setLoading(true);
      fetchUsers({
        type: assignToUsersType,
        callback: (userList) => {
          if (
            !!model?.userid &&
            !addSelfUser &&
            !userList?.some((ele) => ele?.id == model?.userid)
          ) {
            setModel({
              ...model,
              userid: "",
            });
          }
          setLoading(false);
        },
      } as any);
    }
  }, [visible]);
  const selfUser = addSelfUser
    ? [
        {
          name: authUser?.name,
          last_name: authUser?.last_name,
          id: authUser?.id,
        },
      ]
    : [];
  const userOptions = [...selfUser, ...users]?.map((item) => {
    return {
      label: `${item?.name || ""} ${item?.last_name || ""}`,
      value: item?.id,
    };
  });

  const handleDocText = (event) => {
    setIsError(false);
    const target = event.target;

    setDocFile(target.files.length > 0 ? target.files[0] : null);
  };

  const handleDocUpload = () => {
    if (_.isEmpty(currentTitleText)) {
      setIsTitleError(true);
      return;
    } else if (!docFile) {
      setIsError(true);
      return;
    }
    setIsError(false);
    setBillingDocs((prev) => [
      ...(Array.isArray(prev) ? prev : []),
      { title: currentTitleText ?? "", file: docFile ?? null },
    ]);
    setOpenDocModel(false);
    setCurrentTitleText("");
  };

  const handleFileDelete = (item: any) => {
    setBillingDocs((prevDocs) => prevDocs.filter((doc) => doc !== item));
  };

  useEffect(() => {
    if (!openDocModel) {
      setIsError(false);
    }
  }, [openDocModel]);

  const handleTitleText = (e: any) => {
    setIsTitleError(false);
    setCurrentTitleText(e.target.value);
  };

  return (
    <SlideoverModal
      size={withDoc ? "sm" : "lg"}
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle={headerTitle}
    >
      {withDoc ? (
        <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
          <SlideoverModal
            size={"sm"}
            visible={openDocModel}
            onClose={() => {
              setOpenDocModel(false);
              setIsTitleError(false);
              setIsError(false);
            }}
            headerTitle={"Upload Billing Document"}
          >
            <div className="h-[100%] w-full flex flex-col">
              <FormLabel className="w-full ">Document Title</FormLabel>
              <FormInput
                type="text"
                title="Document Title"
                name="docTitle"
                required
                onChange={(e) => handleTitleText(e)}
                className={`block w-full border ${
                  isTitleError ? "border-red-500" : "border-gray-200"
                } shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600
                file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4 dark:file:bg-gray-700 dark:file:text-gray-400 mb-3`}
              />
              <FormLabel className="w-full ">Billing Document</FormLabel>
              <FormInput
                type="file"
                accept="application/pdf"
                title="choose ticket"
                name="ticket"
                onChange={(e) => handleDocText(e)}
                className={`block w-full border ${
                  isError ? "border-red-500" : "border-gray-200"
                } shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600
                file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4 dark:file:bg-gray-700 dark:file:text-gray-400 mb-3`}
              />

              <Button
                variant="primary"
                onClick={() => handleDocUpload()}
                className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
              >
                <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
                Add Document
              </Button>
            </div>
          </SlideoverModal>

          <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
            Query: <span className="text-primary"># {query?.query_no}</span>
          </div>

          <FormLabel className="w-full ">
            Billing Documents <span className="text-gray-400">(optional)</span>{" "}
          </FormLabel>

          <div className="flex gap-2 flex-wrap items-center">
            {billingDocs &&
              billingDocs?.map((item: any, index: number) => (
                <div key={index} className="flex items-center gap-1">
                  <span className="bg-gray-200 p-1 rounded-md">
                    {item?.title
                      ? item?.title?.length < 10
                        ? item?.title
                        : `${item?.title?.slice(0, 10)}...`
                      : "Attachment"}
                  </span>
                  <Lucide
                    icon="Trash"
                    className="stroke-[1.3] w-4 h-4 mr-2 text-red-500 cursor-pointer"
                    onClick={() => handleFileDelete(item)}
                  />
                </div>
              ))}
            <div
              className="bg-gray-300 p-2 rounded-md w-8 cursor-pointer"
              onClick={() => setOpenDocModel(true)}
            >
              <Lucide icon="Plus" className="stroke-[1.3] w-4 h-4 mr-2" />
            </div>
          </div>

          <Button
            variant="primary"
            type="submit"
            loading={submitting}
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
            Submit
          </Button>
        </form>
      ) : (
        <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
          <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
            Query: <span className="text-primary"># {query?.query_no}</span>
          </div>
          {loading ? (
            <div className="flex w-[100%] justify-center py-12">
              <LoadingIcon className="w-6 h-6 mx-auto" />
            </div>
          ) : (
            <div className="flex-1 w-full mt-3 mb-10 xl:mt-0">
              <FormLabel>Assign to</FormLabel>
              <ReactSelect
                className="flex-1 w-full mt-3 xl:mt-0"
                name="userid"
                placeholder="--Select--"
                required
                value={model?.userid}
                onChange={(_, value) =>
                  handleSelectChange({
                    target: { name: "userid", value: value as any },
                  } as any)
                }
                options={userOptions}
              />
              <div className="mt-3">
                <div className="flex-1 w-full mt-3 xl:mt-0">
                  <FormLabel>Remarks</FormLabel>
                  <FormTextarea
                    rows={5}
                    value={model?.remark || ""}
                    name="remark"
                    required
                    onChange={handleSelectChange as any}
                  />
                  <FormHelp className="text-right">
                    Maximum characters:{" "}
                    {model?.remark ? model.remark.length : 0}
                    /2000
                  </FormHelp>
                </div>
              </div>
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            loading={submitting}
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
            Submit
          </Button>
        </form>
      )}
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
  users: users?.data || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (payload: any) => dispatch(fetchAllUsers(payload)),
  onAssign: (payload: any) => dispatch(queryAssignTo(payload)),
  setBillingToQue: (payload: any) => dispatch(createQueue(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
