import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { roleProps } from "../../interfaces";

const initialState: roleProps = {} as any

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<roleProps>) => {
      return { ...state, ...action.payload };
    },
    removeRoles: () => {
      return initialState;
    },
  },
});

export const { setRoles, removeRoles } = rolesSlice.actions;

export const selectRoles = (state: RootState) => {
  return state.roles;
};

export default rolesSlice.reducer;
