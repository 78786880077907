import React, { ReactNode } from 'react';

interface FormParentProps {
  label: string;
  required?: boolean;
  description: string | ReactNode;
  childrens?: ReactNode;
  errors?: ReactNode;
  className?: string;
}


const FormInputParent: React.FC<FormParentProps> = ({ label, required = false, description, childrens, errors }) => {
  return (
    <div className="flex-col block pt-3 mt-3 xl:items-start sm:flex xl:flex-row first:mt-0 first:pt-0">
      <label className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:w-60 xl:mr-14">
        <div className="text-left">
          <div className="flex items-center">
            <div className="font-medium">{label}</div>
            {required && (
              <div className="ml-2.5 px-2 py-0.5 bg-slate-100 text-slate-500 dark:bg-darkmode-300 dark:text-slate-400 text-xs rounded-md border border-slate-200">
                Required
              </div>
            )}
          </div>
          <div className="mt-1.5 xl:mt-3 text-xs leading-relaxed text-slate-500/80">
            {description}
          </div>
        </div>
      </label>
      <div className="flex-1 xl:items-start justify-start justify-items-start w-full mt-3 xl:mt-0">
        {childrens}
        {errors ? errors : ''}
      </div>
    </div>
  )
}

export default FormInputParent;