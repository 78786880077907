import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { alertProps } from "../../interfaces";
import { RootState } from "..";

const initialState: alertProps = {
  text: "",
  title: "",
  visible: false,
  icon: "",
};
export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<alertProps>) => {
      return {
        ...action.payload,
        visible: true,
        icon: action?.payload?.icon || "XCircle",
      };
    },
    removeAlert: (state) => {
      return initialState;
    },
  },
});

export const { setAlert, removeAlert } = alertSlice.actions;

export const selectText = (state: RootState) => {
  return state.alert;
};

export default alertSlice.reducer;
