import DataTable from "../../components/DataTable";
import { columns, templateStatusEnum, templateTypesEnum } from "./constants";
import AddUpdate from "./AddUpdate";
import { useEffect, useState } from "react";
import { fetchEmailTemplates } from "src/stores/emailTemplate";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdateEmailTemplateProps,
  emailTemplateDataProps,
  emailTemplateProps,
} from "src/interfaces";
import { emailTemplateAPIUrls } from "src/common/apiActions";
import { checkPermission } from "src/helpers";

const Component = ({
  fetchData,
  emailTemplates,
}: {
  fetchData: Function;
  emailTemplates: emailTemplateProps;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = emailTemplates?.data || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<createUpdateEmailTemplateProps>({
    title: "",
    content: "",
    status: "",
    type: "",
    location_id: [],
  });

  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Email Templates"
        url={emailTemplateAPIUrls?.get}
        reload={isReload}
        onDataLoaded={() => setIsReload(false)}
        addBtnContent="Add New Template"
        onAddClick={
          checkPermission(emailTemplateAPIUrls?.create)
            ? () => {
                setModel({
                  title: "",
                  content: "",
                  status: "1",
                  type: "",
                  location_id: [],
                });
                setVisible(true);
              }
            : undefined
        }
        columns={columns({
          onEditClick: checkPermission(emailTemplateAPIUrls?.update)
            ? (row: emailTemplateDataProps) => {
                setModel({
                  id: row?.id,
                  title: row?.title,
                  content: row?.content,
                  status: templateStatusEnum[row?.status] || row?.status,
                  type: templateTypesEnum[row?.type] || row?.type,
                  location_id: row?.locations?.map((item) => item?.id),
                });
                setVisible(true);
              }
            : undefined,
        })}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ emailTemplates }: RootState) => ({ emailTemplates });

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchEmailTemplates(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
