import { BrowserRouter as RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './stores';
import ScrollToTop from "./base-components/ScrollToTop";

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider>
          {children}
          <ScrollToTop />
        </RouterProvider>
      </PersistGate>
    </Provider>
  );
};
export default AppProviders;
