import { TableColumnType } from "../../components/DataTable/interfaces";
import { authUserProps, queryDataProps } from "src/interfaces";
import EmergencyIcon from "./EmergencyIcon";
import { isQueryCancelled, isQueryClosedAndCancelled } from "./helpers";
import { dateParser, getTableColorFromStatus } from "src/helpers";

export const tableFields = (
  onQueryClick: Function,
  onCustomerClick: Function,
  authUser: authUserProps
): TableColumnType<any>[] => [
  {
    title: "Query #",
    field: "id",
    cell: (row: queryDataProps) => {
      return (
        <div
          className="flex items-center text-[#075985] cursor-pointer"
          onClick={() => onQueryClick(row)}
        >
          <div
            className={`h-4 w-1 mr-1 ${
              row?.stage == 4
                ? getTableColorFromStatus[16]
                : row?.stage == 2
                ? row?.assigned_by && !row?.assigned_to
                  ? getTableColorFromStatus[19]
                  : getTableColorFromStatus[18]
                : getTableColorFromStatus[row.status]
            }`}
          />
          {row?.query_no}{" "}
          {row?.is_emergency == 1 && (
            <EmergencyIcon className="absolute left-[5.5rem]" />
          )}
        </div>
      );
    },
  },
  {
    title: "Customer",
    field: "name",
    cell: (row: queryDataProps) => {
      return (
        <div
          className="flex flex-col text-[#075985] cursor-pointer"
          onClick={() => onCustomerClick(row)}
        >
          <div className="text-base font-medium whitespace-nowrap">
            {row?.customer?.fullname}
          </div>
          <div className="mt-1 text-xs">{row.customer?.customer_id || ""}</div>
        </div>
      );
    },
  },
  {
    title: "No. of PAX",
    field: "pax",
    cell: (row: queryDataProps) => {
      return row?.no_of_adults + row?.no_of_children + row?.no_of_infants;
    },
  },
  {
    title: "Created By",
    field: "createdBy",
    cell: (row: queryDataProps) => {
      return row?.sourced_by == authUser?.id
        ? "You"
        : `${row?.sourced_by_user?.name} ${
            row?.sourced_by_user?.last_name
              ? row?.sourced_by_user?.last_name
              : ""
          }`;
    },
  },
  {
    title: "Assigned To",
    field: "assignedTo",
    cell: (row: queryDataProps) => {
      if (!row?.assigned_to_user?.name && !row?.assigned_to_user?.last_name) {
        return "N/A";
      }
      return (
        (row?.assigned_to_user?.name || "") +
        " " +
        (row?.assigned_to_user?.last_name
          ? row?.assigned_to_user?.last_name
          : "")
      );
    },
  },
  {
    title: "Created On",
    field: "time",
    cell: (row: queryDataProps) => {
      return dateParser(row?.created_at, "DD-MM-YYYY");
    },
  },
  {
    title: "Location",
    field: "",
    cell: (item) => {
      return item?.sourced_by_user?.location?.name ?? item?.location?.name;
    },
  },
  {
    title: "Status",
    field: "status",
    cell: (row: queryDataProps) => {
      return (
        <>
          {isQueryCancelled(row.status) ? (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#F5CECC] rounded-md text-[#CE3B3B]">
              {row.status_text}
            </span>
          ) : (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
              {row.status_text}
            </span>
          )}
        </>
      );
    },
  },
  // {
  //   title: "Actions",
  //   field: "actions",
  //   cell: (row: queryDataProps) => (
  //     <ActionPopup menuItems={menuItems} />
  //   ),
  // },
];

interface TripType {
  label: string;
  value: number;
}

export const tripTypes: Array<TripType> = [
  { label: "Return", value: 1 },
  { label: "One way", value: 2 },
];

export const queryTypes: Array<{ label: string; value: number }> = [
  { label: "New Ticket: International", value: 1 },
  { label: "New Ticket: Domestic", value: 2 },
];

export const countries: Array<{ label: string; value: string }> = [
  { label: "India", value: "India" },
  { label: "Canada", value: "Canada" },
  { label: "Australia", value: "Australia" },
  { label: "Japan", value: "Japan" },
];

export const queryTypesEnum = {
  1: "New Ticket: International",
  2: "New Ticket: Domestic",
};

export const queryActionStatusEnum = {
  1: "Assigned",
  2: "Assigned for Payment",
  3: "Request for update",
};

export interface Source {
  label: string;
  value: string;
}

export const sources: Array<Source> = [
  {
    label: "Website",
    value: "Website",
  },
  {
    label: "Phone Call",
    value: "Phone",
  },
  {
    label: "Walk-In",
    value: "Walk-in",
  },
];

export const stageOptions = [
  { label: "All", val: "", default: true },
  { label: "Open", val: "0" },
  { label: "Pending", val: "1" },
  { label: "In-progress", val: "2" },
  { label: "Query Done", val: "3" },
  { label: "Re-opened", val: "4" },
];
export const sourceOptions = [
  { label: "All Sources", val: "", default: true },
  { label: "Walk-in", val: "Walk-in" },
  { label: "Website", val: "Website" },
  { label: "Phone", val: "Phone" },
];

export const sortOptions = [
  { label: "Sort By", val: "", default: true },
  { label: "Query", val: "Query" },
  // { label: "PAX", val: "PAX" },
  { label: "Created On", val: "Time" },
  // { label: "Customer Name", val: "Name" },
];
