import darkMode from "./basicSlices/darkModeSlice";
import colorScheme from "./basicSlices/colorSchemeSlice";
import compactMenu from "./basicSlices/compactMenuSlice";
import pageLoader from "./basicSlices/pageLoaderSlice";
import alert from "./basicSlices/alertSlice";
import queryViewType from "./basicSlices/queryViewTypeSlice";
import authUser from "./auth/slice";
import users from "./user/slice";
import roles from "./role/slice";
import permissions from "./permission/slice";
import locations from "./location/slice";
import customers from "./customer/slice";
import customer from "./customer/detailSlice";
import queries from "./query/slice";
import cityAirports from "./cityAirport/slice";
import dashboards from "./dashboard/slice";
import globalSearch from "./dashboard/searchSlice";
import emailTemplates from "./emailTemplate/slice";
import signTemplates from "./payment/slice";
import notifications from "./notification/slice";
import queues from "./queue/slice";

// Define root reducer combining all reducers
const allReducers = {
  darkMode,
  colorScheme,
  compactMenu,
  pageLoader,
  alert,
  queryViewType,
  authUser,
  users,
  roles,
  permissions,
  locations,
  customers,
  customer,
  queries,
  cityAirports,
  dashboards,
  globalSearch,
  emailTemplates,
  signTemplates,
  notifications,
  queues,
};

export default allReducers;
