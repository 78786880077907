import Button from "src/base-components/Button";
import Lucide from "../../../base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import { RootState } from "src/stores";
import {
  fetchNotifications,
  updateNotification,
} from "src/stores/notification";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import LoadingIcon from "src/base-components/LoadingIcon";
import { notificationsProps } from "src/interfaces";
import { useNavigate } from "react-router-dom";
import { clearAllListeners } from "@reduxjs/toolkit";
interface props {
  token: string;
  visible: boolean;
  setVisible: (val: boolean) => void;
  notifications: notificationsProps;
  fetchData: Function;
  onUpdate: (payload: { id: string | "all"; callback?: Function }) => void;
}

function Component(props: props) {
  const navigate = useNavigate();
  const notifications = props?.notifications?.data || [];
  const [markingRead, setMarkingRead] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const loadData = (loadMore = false) => {
    if (notifications?.length == 0) {
      setLoading(true);
    }
    setLoadingNotification(true);
    if (!loadingNotification) {
      props?.fetchData({
        loadMore: loadMore,
        callback: (isError: boolean, responseData) => {
          setLoading(false);
          setLoadingMore(false);
          setIsLoadedAll(responseData?.isAllLoaded || isError);
        },
      });
    }
  };
  useEffect(() => {
    loadData(props?.visible);
    addEventListener("focus", () => {
      if (props?.token && localStorage.getItem("deviceToken")) {
        loadData(props?.visible);
      }
    });
    return () => {
      clearAllListeners();
    };
  }, []);
  useEffect(() => {
    if (props?.visible) {
      loadData(props?.visible);
    }
  }, [props?.visible]);

  return (
    <>
      <SlideoverModal
        headerRight={
          <Button
            loadingColor="#233580"
            loading={markingRead}
            disabled={
              notifications?.filter((ele) => ele?.read == 0)?.length == 0
            }
            onClick={() => {
              setMarkingRead(true);
              props?.onUpdate({
                id: "all",
                callback: (isError) => {
                  setMarkingRead(false);
                  if (!isError) {
                    props?.setVisible(false);
                  }
                },
              });
            }}
            variant="outline-primary"
            className="hidden sm:flex"
          >
            <Lucide icon="Check" className="w-4 h-4 mr-2" /> Mark all as read
          </Button>
        }
        className="p-2"
        visible={props?.visible}
        onClose={() => props.setVisible(false)}
        headerTitle="Notifications"
      >
        {loading ? (
          <div className="flex w-[100%] justify-center py-16">
            <LoadingIcon
              color="#64748b"
              icon="oval"
              className="w-8 h-8 mx-auto"
            />
          </div>
        ) : notifications?.length == 0 ? (
          <div className="flex w-[100%] justify-center py-28">
            No notifications
          </div>
        ) : (
          <div
            onScroll={(event) => {
              const { scrollTop, scrollHeight, clientHeight } =
                event?.currentTarget;
              const distanceFromBottom =
                scrollHeight - scrollTop - clientHeight;
              if (distanceFromBottom == 0 && !isLoadedAll) {
                if (!loadingMore && !loading) {
                  setLoadingMore(true);
                  loadData(true);
                }
              } else if (distanceFromBottom > 0) {
                setIsLoadedAll(false);
              }
            }}
            className="flex flex-col gap-0.5"
          >
            {notifications?.map((item, itemKey) => (
              <div
                onClick={() => {
                  if (item?.read == 0) {
                    props?.onUpdate({ id: item?.id });
                  }
                  props?.setVisible(false);
                  if (
                    location?.pathname != `/query-detail/${item?.reference_id}`
                  ) {
                    navigate(`/query-detail/${item?.reference_id}`);
                  }
                }}
                key={itemKey}
                className="cursor-pointer flex items-center px-3 py-2.5 rounded-xl hover:bg-slate-100/80"
              >
                <div>
                  <div className="overflow-hidden flex border-2 rounded-full w-11 h-11 image-fit border-slate-200/70">
                    <Lucide icon="Activity" className="m-auto" />
                  </div>
                </div>
                <div className="sm:ml-5">
                  <div className="font-medium">{item.title}</div>
                  <div className="text-slate-500 mt-0.5">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </div>
                  <div className="mt-1.5 text-xs text-slate-500">
                    {item.created_at} |{" "}
                    {`${item?.created_by?.name} ${
                      item?.created_by?.last_name
                        ? item?.created_by?.last_name
                        : ""
                    }`}
                  </div>
                </div>
                {item?.read == 0 && (
                  <div className="flex-none w-2 h-2 ml-auto border rounded-full bg-primary/40 border-primary/40"></div>
                )}
              </div>
            ))}
            {loadingMore && !loading && (
              <div className="flex w-[100%] justify-center py-1">
                <LoadingIcon
                  color="#64748b"
                  icon="oval"
                  className="w-6 h-6 mx-auto"
                />
              </div>
            )}
          </div>
        )}
      </SlideoverModal>
    </>
  );
}

const mapStateToProps = ({ notifications, authUser }: RootState) => ({
  notifications,
  token: authUser?.token,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchNotifications(payload)),
  onUpdate: (payload: any) => dispatch(updateNotification(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
