import { fetch as http } from "./http";
import { quotationAPIUrls } from "../common/apiActions";

export const createQuotationService = async (payload: any) => {
  try {
    const data = await http.Post(quotationAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const acceptQuotationService = async (payload: any) => {
  try {
    const data = await http.Post(quotationAPIUrls?.accept, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const fetchQuotationTicket = async (payload: any) => {
  try {
    const data = await http.Post(quotationAPIUrls?.fetchTicket, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const sendQuotationService = async (payload: any) => {
  try {
    const data = await http.Post(quotationAPIUrls?.send, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const uploadTicketService = async (payload: any) => {
  try {
    const data = await http.Post(
      quotationAPIUrls?.uploadTicket,
      payload,
      "multipart/form-data"
    );
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const sendTicketService = async (payload: any) => {
  try {
    const data = await http.Post(quotationAPIUrls?.sendTicket, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const deleteTicketService = async (payload: any) => {
  try {
    const data = await http.Post(
      quotationAPIUrls?.deleteTicket,
      payload,
      "multipart/form-data"
    );
    console.log("DATA -=-=-=-=-= > ", JSON.stringify(data));
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
