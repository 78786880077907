import {
  roleAPIUrls
} from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchRolesService = async () => {
  try {
    const data = await http.Get(roleAPIUrls?.get);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createRoleService = async (payload: any) => {
  try {
    const data = await http.Post(roleAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateRoleService = async (payload: any) => {
  try {
    const data = await http.Post(roleAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
