import { authUserProps } from "src/interfaces";
import { store } from "src/stores";
import { fetchUserPermissions, logout } from "src/stores/auth";

export const appInitializer = (authUser: authUserProps) => {
  const isSameSession = sessionStorage.getItem("IsSameSession")
  const isLoggedIn = !!authUser?.token
  const isPersist = authUser?.isPersist
  if (isLoggedIn && isSameSession != "Yes") {
    if (!isPersist) {
      store?.dispatch(logout());
    }
  }
  if (isLoggedIn && (isSameSession || isPersist)) {
    store?.dispatch(fetchUserPermissions());
  }
};
