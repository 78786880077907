import {
  CREATE_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE
} from "./types";

export const fetchEmailTemplates = (payload?: any) => {
  return {
    type: FETCH_EMAIL_TEMPLATE,
    payload
  };
};

export const createEmailTemplate = (payload: any) => {
  return {
    type: CREATE_EMAIL_TEMPLATE,
    payload
  };
};

export const updateEmailTemplate = (payload: any) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE,
    payload
  };
};

export const deleteEmailTemplate = (payload: any) => {
  return {
    type: DELETE_EMAIL_TEMPLATE,
    payload
  };
};
