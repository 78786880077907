import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import userSaga from "./user/saga";
import roleSaga from "./role/saga";
import permisisonSaga from "./permission/saga";
import locationSaga from "./location/saga";
import querySaga from "./query/saga";
import paymentSaga from "./payment/saga";
import quotationSaga from "./quotation/saga";
import passengerSaga from "./passenger/saga";
import customerSaga from "./customer/saga";
import { cityAirportsSaga } from "./cityAirport";
import dashboardSaga from "./dashboard/saga";
import emailTemplateSaga from "./emailTemplate/saga";
import notificationSaga from "./notification/saga";
import queueSaga from "./queue/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    roleSaga(),
    userSaga(),
    permisisonSaga(),
    locationSaga(),
    querySaga(),
    paymentSaga(),
    quotationSaga(),
    passengerSaga(),
    customerSaga(),
    cityAirportsSaga(),
    dashboardSaga(),
    emailTemplateSaga(),
    notificationSaga(),
    queueSaga(),
  ]);
}
