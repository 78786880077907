import {
  CREATE_ROLE,
  FETCH_ROLE_LIST,
  UPDATE_ROLE,
} from "./types";

export const fetchRoles = (payload?: any) => {
  return {
    type: FETCH_ROLE_LIST,
    payload
  };
};

export const createRole = (payload: any) => {
  return {
    type: CREATE_ROLE,
    payload
  };
};

export const updateRole = (payload: any) => {
  return {
    type: UPDATE_ROLE,
    payload
  };
};