import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { emailTemplateProps } from "../../interfaces";

const initialState: emailTemplateProps = {} as any;

export const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    setEmailTemplates: (state, action: PayloadAction<emailTemplateProps>) => {
      return { ...state, ...action.payload };
    },
    removeEmailTemplates: () => {
      return initialState;
    },
  },
});

export const { setEmailTemplates, removeEmailTemplates } = emailTemplatesSlice.actions;

export const selectEmailTemplates = (state: RootState) => {
  return state.emailTemplates;
};

export default emailTemplatesSlice.reducer;
