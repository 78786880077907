import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { customerDataProps } from "../../interfaces";

const initialState: customerDataProps = {} as any;
export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
      setCustomer: (state, action: PayloadAction<customerDataProps>) => {
        return action.payload
      },
      removeCustomer: (state) => {
        return initialState;
      },
    },
  });
  
  export const {  setCustomer, removeCustomer } = customerSlice.actions;
  
  export const selectCustomer = (state: RootState) => {
    return state.customer;
  };
  
  export default customerSlice.reducer;