import DataTable from "../../../components/DataTable";
import { columns } from "./constants";
import AddUpdate from "./AddUpdate";
import { useEffect, useState } from "react";
import { fetchPermissions } from "src/stores/permission";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdatePermissionProps,
  permissionDataProps,
  permissionProps,
} from "src/interfaces";
import { permissionAPIUrls } from "src/common/apiActions";

const Component = ({
  fetchData,
  permissions,
}: {
  fetchData: Function;
  permissions: permissionProps;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = permissions.data || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<createUpdatePermissionProps>({
    name: "",
    is_nav: "",
    nav_title: "",
    frontend_route: "",
    backend_route: "",
  });

  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Permissions"
        addBtnContent="Add New Permission"
        url={permissionAPIUrls?.get}
        reload={isReload}
        onDataLoaded={() => setIsReload(false)}
        onAddClick={() => {
          setModel({ name: "", is_nav: "", nav_title: "" });
          setVisible(true);
        }}
        columns={columns({
          onEditClick: (row: permissionDataProps) => {
            setModel({
              id: row?.id,
              name: row?.name,
              is_nav: row?.is_nav,
              nav_title: row?.nav_title,
              frontend_route: row?.frontend_route,
              backend_route: row?.backend_route,
            });
            setVisible(true);
          },
        })}
        rowsPerPage={10}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ permissions, authUser }: RootState) => ({
  permissions,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchPermissions(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
