import SlideoverModal from "src/components/SlideoverModal";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryQueueProps } from "src/interfaces";
import Lucide from "src/base-components/Lucide";
import LoadingIcon from "src/base-components/LoadingIcon";
import { dateParser, isAdmin, isManager } from "src/helpers";
import { fetchQueues, updateQueue } from "src/stores/queue";
import _ from "lodash";
import { clearAllListeners } from "@reduxjs/toolkit";
import Card from "src/components/Card";
import Button from "src/base-components/Button";

interface ComponentProps {
  visible: boolean;
  setVisible: (payload: boolean) => void;
  fetchData: () => void;
  onUpdate: (payload: {
    id: string;
    query_id: string;
    user_id: number;
    callback?: Function;
  }) => void;
  queryQueues: queryQueueProps[] | undefined;
  token: any;
}

function Component(props: ComponentProps) {
  const { queryQueues, fetchData, setVisible, visible, onUpdate, token } =
    props;
  const navigate = useNavigate();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadedAll, setIsLoadedAll] = useState(false);

  const loadData = () => {
    if (isManager() || isAdmin()) {
      fetchData();
    }
  };

  useEffect(() => {
    loadData();
    addEventListener("focus", () => {
      if (props?.token && localStorage.getItem("deviceToken")) {
        loadData();
      }
    });
    return () => {
      clearAllListeners();
    };
  }, []);

  return (
    <>
      <SlideoverModal
        className="p-2"
        visible={visible}
        onClose={() => setVisible(false)}
        headerTitle="Queries waiting for billing/payment"
      >
        {loading ? (
          <div className="flex w-[100%] justify-center py-16">
            <LoadingIcon
              color="#64748b"
              icon="oval"
              className="w-8 h-8 mx-auto"
            />
          </div>
        ) : queryQueues?.length == 0 ? (
          <div className="flex w-[100%] justify-center py-28">No Queries</div>
        ) : (
          <div
            onScroll={(event) => {
              const { scrollTop, scrollHeight, clientHeight } =
                event?.currentTarget;
              const distanceFromBottom =
                scrollHeight - scrollTop - clientHeight;
              if (distanceFromBottom == 0 && !isLoadedAll) {
                if (!loadingMore && !loading) {
                  setLoadingMore(true);
                  // loadData(true);
                }
              } else if (distanceFromBottom > 0) {
                setIsLoadedAll(false);
              }
            }}
            className="flex flex-col gap-0.5"
          ></div>
        )}
        <div className="flex flex-col gap-2">
          {!_.isEmpty(queryQueues) &&
            queryQueues?.map((item: any, itemKey) => (
              <Card key={itemKey}>
                <div>
                  <div className="flex justify-between">
                    <div className="flex items-center text-[#075985] ">
                      {item?.query_no}
                    </div>
                    <div className="text-[12px] text-right">
                      {dateParser(item.updated_at, "DD-MM-YYYY")}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col my-2">
                      <div className="text-[12px]">
                        {item?.queries?.customer?.fullname}
                      </div>
                      <div
                        className="text-[10px]"
                        style={{ lineHeight: "1rem" }}
                      >
                        Customer ID:{" "}
                        {item?.queries?.customer?.customer_id || ""}
                      </div>
                    </div>
                    <div className="flex flex-col leading-4 my-1">
                      <div className="text-[10px] font-medium text-[#075985] text-end ">
                        Payment/Billing Amount:
                      </div>
                      <div className="text-[12px] text-left">
                        ${item?.payment_amount}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between mb-1">
                    <div className="flex flex-col leading-4 my-1 w-full">
                      <div className="text-[10px] font-medium text-[#075985] text-start ">
                        Assigned by:
                      </div>
                      <div className="text-[12px] text-left">
                        {item?.assigned_by_user?.name}
                      </div>
                    </div>
                    <Button
                      variant="outline-primary"
                      className=" font-bold w-32"
                      onClick={() => {
                        if (item?.status == 0) {
                          onUpdate({
                            id: item?.id,
                            query_id: item?.query_id,
                            user_id: token?.id,
                          });
                        }
                        props?.setVisible(false);
                        if (
                          location?.pathname !=
                          `/query-detail/${item?.query_id}`
                        ) {
                          navigate(`/query-detail/${item?.query_id}`);
                        }
                      }}
                    >
                      Proceed
                      <Lucide icon="ArrowRight" className="w-4 h-4 ml-2" />
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </SlideoverModal>
    </>
  );
}

const mapStateToProps = ({ queries, authUser, queues }: RootState) => ({
  token: authUser,
  queryQueues: queues,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: () => dispatch(fetchQueues()),
  onUpdate: (payload: any) => dispatch(updateQueue(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
