import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from "crypto-js";

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const encrypt = (value: any) => {
  const privateKey = process.env.REACT_APP_PRIVATE_KEY
  const hmacDigest = CryptoJS.AES.encrypt(value, privateKey).toString();;
  return hmacDigest
}

export const decrypt = (value: any) => {
  const privateKey = process.env.REACT_APP_PRIVATE_KEY;
  const bytes = CryptoJS.AES.decrypt(value, privateKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
