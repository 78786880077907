import DataTable from "../../../components/DataTable";
import { columns } from "./constants";
import AddUpdate from "./AddUpdate";
import { useEffect, useState } from "react";
import { fetchRoles } from "src/stores/role";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdateRoleProps,
  roleDataProps,
  roleProps,
} from "src/interfaces";
import { roleAPIUrls } from "src/common/apiActions";
import { checkPermission } from "src/helpers";

const Component = ({
  fetchData,
  roles,
}: {
  fetchData: Function;
  roles: roleProps;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = roles.data || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<createUpdateRoleProps>({
    name: "",
    permissions: [],
  });

  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Roles"
        url={roleAPIUrls?.get}
        reload={isReload}
        saveState
        onDataLoaded={() => setIsReload(false)}
        addBtnContent="Add New Role"
        onAddClick={
          checkPermission(roleAPIUrls?.create)
            ? () => {
                setModel({ name: "", permissions: [] });
                setVisible(true);
              }
            : undefined
        }
        columns={columns({
          onEditClick: checkPermission(roleAPIUrls?.update)
            ? (row: roleDataProps) => {
                setModel({
                  id: row?.id,
                  role_id: row?.id,
                  user_type: row?.user_type,
                  name: row?.name,
                  permissions: row?.permissions?.map((item) => {
                    return {
                      permission: item?.pivot?.permission,
                      id: item?.id,
                    } as any;
                  }),
                });
                setVisible(true);
              }
            : undefined,
        })}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ roles }: RootState) => ({ roles });

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (payload: any) => dispatch(fetchRoles(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
