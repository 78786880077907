import { firebaseToken } from "src/firebase";
import { authAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const loginService = async (payload: any) => {
  let deviceToken = await firebaseToken();
  try {
    const data = await http.Post(authAPIUrls?.login, {
      ...payload,
      device_token: deviceToken,
      email: payload?.username as any,
    });
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  } finally {
    payload?.callback && payload?.callback();
  }
};

const amadeusBaseUrl = process.env.REACT_APP_AMADEUS_BASE_URL;
const envEnums: any = {
  Developement: "dev",
  Staging: "staging",
  Production: "prod",
};
const currentEnv = envEnums[process.env.REACT_APP_ENV || "prod"];
export const getAmadeusTokenService = async () => {
  if (
    origin === "http://localhost:5173" ||
    origin === "http://192.168.18.128:5173"
  ) {
    const payload = {
      client_id: process.env.REACT_APP_AMADEUS_CLIENT_ID,
      client_secret: process.env.REACT_APP_AMADEUS_CLIENT_SECRET,
    };
    try {
      const data = await http.Postx_www_form(
        `/v1/security/oauth2/token`,
        `grant_type=client_credentials&client_id=${payload?.client_id}&client_secret=${payload?.client_secret}`,
        amadeusBaseUrl
      );
      return data;
    } catch (error: any) {
      throw new Error(error);
    }
  } else {
    try {
      const data = await http.Post(
        `api/token.${currentEnv}.php`,
        { base: process.env.REACT_APP_AMADEUS_BASE_URL },
        "multipart/form-data",
        undefined,
        "/"
      );
      return data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
};

export const fetchUserPermissionsService = async (payload: any) => {
  try {
    let deviceToken = await firebaseToken();
    if (deviceToken) {
      deviceToken = "/" + deviceToken;
    } else {
      deviceToken = "";
    }
    const data = await http.Get(
      `${authAPIUrls?.userPermissions}${deviceToken}`
    );
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
