import { queryAPIUrls } from "src/common/apiActions";
import Lucide, { icons } from "src/base-components/Lucide";
import { TableColumnType } from "../../../components/DataTable/interfaces";
import { queryDataProps } from "src/interfaces";
import moment from "moment";
import EmergencyIcon from "../../Queries/EmergencyIcon";
import {
  isQueryCancelled,
  isQueryClosedAndCancelled,
} from "src/pages/Queries/helpers";
import { customColorGenerator, dateParser } from "src/helpers";

export const reopenOptions = [
  { label: "Cancellation Request", value: 11, url: queryAPIUrls?.cancellation },
  { label: "Change Date Request", value: 12, url: queryAPIUrls?.changeDate },
  {
    label: "Rescheduled by Airlines",
    value: 13,
    url: queryAPIUrls?.rescheduledByAirline,
  },
];

export const queriesTableFields = (
  onQueryClick: Function
): TableColumnType<any>[] => [
  {
    title: "Query #",
    field: "id",
    cell: (row: queryDataProps) => {
      const randomColor = customColorGenerator(
        row?.sourced_by_user?.location?.short_name ?? row?.location?.short_name
      );
      return (
        <div
          className="flex items-center text-[#075985] cursor-pointer"
          onClick={() => onQueryClick(row)}
        >
          <div
            className={`w-5 h-5 p-2 border-2 rounded-full  flex justify-center items-center absolute ml-[-1.5rem]`}
            style={{
              backgroundColor: randomColor?.backgroundColor,
            }}
          >
            <p
              className="p-2 text-[8px] font-semibold"
              style={{
                color: randomColor?.color,
              }}
            >
              {row?.sourced_by_user?.location?.short_name ??
                row?.location?.short_name}
            </p>
          </div>
          {row?.query_no}{" "}
          {row?.is_emergency == 1 && (
            <EmergencyIcon className="absolute ml-[3.5rem]" />
          )}
        </div>
      );
    },
  },
  {
    title: "No. of PAX",
    field: "pax",
    cell: (row: queryDataProps) => {
      return row?.no_of_adults + row?.no_of_children + row?.no_of_infants;
    },
  },
  {
    title: "Created By",
    field: "createdBy",
    cell: (row: queryDataProps) => {
      return `${row?.sourced_by_user?.name} ${
        row?.sourced_by_user?.last_name ?? ""
      }`;
    },
  },
  {
    title: "Time",
    field: "time",
    cell: (row: queryDataProps) => {
      return dateParser(row?.created_at, "DD-MM-YYYY");
    },
  },
  {
    title: "Status",
    field: "status",
    cell: (row: queryDataProps) => {
      return (
        <>
          {isQueryCancelled(row.status) ? (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#F5CECC] rounded-md text-[#CE3B3B]">
              {row?.status_text}
            </span>
          ) : (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
              {row?.status_text}
            </span>
          )}
        </>
      );
    },
  },
];

export type menuIdProps = "info" | "queries";
interface menuProps {
  title: string;
  icon: keyof typeof icons;
  id: menuIdProps;
}
export const menus: menuProps[] = [
  {
    id: "info",
    title: "Profile",
    icon: "User",
  },
  {
    id: "queries",
    title: "Queries",
    icon: "Activity",
  },
];
