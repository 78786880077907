import { call, put, takeLatest } from "redux-saga/effects";
import {
  ACCEPT_PAYMENT,
  COMPLETE_REFUND,
  CREATE_PAYMENT,
  FETCH_PAYMENT,
  FETCH_SIGN_TEMPLATES,
  INITIATE_REFUND,
} from "./types";
import {
  createPaymentService,
  acceptPaymentService,
  fetchPaymentService,
  fetchSignTemplatesService,
  initiateRefundService,
  completeRefundService,
} from "../../services";
import { setAlert } from "../basicSlices";
import { handleLoadQuery } from "../query";
import { setSignTemplates } from "./slice";

function* handleCreatePayment({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createPaymentService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Payment!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleAcceptPayment({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(acceptPaymentService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to accept Payment!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleLoadPayment({
  payload,
}: {
  payload: { id: string; callback?: Function };
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchPaymentService, payload?.id);
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Payment!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleLoadSignTemplates({
  payload,
}: {
  payload: { id: string; callback?: Function };
}): Generator<any> {
  try {
    let responseData: any = yield call(fetchSignTemplatesService, payload);
    if (Array.isArray(responseData)) {
      responseData = { data: responseData }
    }
    yield put(setSignTemplates(responseData))
    payload?.callback && payload?.callback(false);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load templates!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleInitiateRefund({
  payload,
}: {
  payload: { id: string; query_id: string; callback?: Function };
}): Generator<any> {
  try {
    const responseData: any = yield call(initiateRefundService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Payment!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleCompleteRefund({
  payload,
}: {
  payload: { id: string; query_id: string; callback?: Function };
}): Generator<any> {
  try {
    const responseData: any = yield call(completeRefundService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Payment!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

export default function* paymentSaga() {
  yield takeLatest<any>(CREATE_PAYMENT, handleCreatePayment);
  yield takeLatest<any>(ACCEPT_PAYMENT, handleAcceptPayment);
  yield takeLatest<any>(FETCH_PAYMENT, handleLoadPayment);
  yield takeLatest<any>(FETCH_SIGN_TEMPLATES, handleLoadSignTemplates);
  yield takeLatest<any>(INITIATE_REFUND, handleInitiateRefund);
  yield takeLatest<any>(COMPLETE_REFUND, handleCompleteRefund);
}
