import { FETCH_CITY_AIRPORT_DICTIONARIES, PARSE_PNR_DETAIL } from "./types";

export const fetchCityAirportDictionaries = (payload: {
  codes: string[];
  type?: "A" | "C";
}) => {
  return {
    type: FETCH_CITY_AIRPORT_DICTIONARIES,
    payload,
  };
};

export const parsePNR = (payload: any) => {
  return {
    type: PARSE_PNR_DETAIL,
    payload,
  };
};
