import { loginProps } from "../../interfaces";
import {
  HANDLE_LOGIN,
  HANDLE_FETCH_AMADEUS_TOKEN,
  HANDLE_LOGOUT,
  FETCH_USER_PERMISSIONS,
} from "./types";

export const login = (payload: loginProps) => {
  return {
    type: HANDLE_LOGIN,
    payload,
  };
};

export const logout = () => {
  localStorage.removeItem('deviceToken')
  return {
    type: HANDLE_LOGOUT,
  };
};

export const fetchAmadeusToken = () => {
  return {
    type: HANDLE_FETCH_AMADEUS_TOKEN,
  };
};

export const fetchUserPermissions = (payload?: any) => {
  return {
    type: FETCH_USER_PERMISSIONS,
    payload,
  };
};
