import { customerAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchCustomersService = async (
  keywords: string = "",
  type: number = 0
) => {
  try {
    const data = await http.Get(
      `${customerAPIUrls?.getAll}?type=${type}&search=${keywords}`
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchCustomersDetails = async (payload: any) => {
  try {
    const data = await http.Post(`${customerAPIUrls?.details}`, payload);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createCustomerService = async (payload: any) => {
  try {
    const data = await http.Post(customerAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateCustomerService = async (payload: any) => {
  try {
    const data = await http.Post(customerAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
